import React, { FC, useEffect, useState } from "react";
import container from "../container";
import LoginStaff from "../components/Staff/LoginStaff";
import FilesForStaff from "../components/Staff/FilesForStaff";
import { FilesForStaffInterface } from "../services/Api/DataInterface/FilesForStaffInterface";
import LocaleEnum from "../services/Locale/enums/Locale/LocaleEnum";
import PropTypes from "prop-types";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import Forbidden from "../components/Staff/Forbidden";

interface PropsInterface {
    locale: LocaleEnum;
}

const PanelStaff: FC<PropsInterface> = ({ locale }) => {
    const [data, setData] = useState<FilesForStaffInterface[] | null>(null);
    const [isLogged, setIsLogged] = useState<boolean | null>(null);

    useEffect(() => {
        (async () => {
            try {
                const data = await container.staffPanelService.getUserContent();
                setIsLogged(true);
                setData(data.items);
            } catch (e) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (e && e.status && e.status === 403) {
                    setIsLogged(true);
                    return;
                }
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                if (e && e.status && e.status === 401) {
                    setIsLogged(false);
                    return;
                }

                throw e;
            }
        })();
    }, [locale]);

    if (isLogged === false) {
        return <LoginStaff />;
    }

    if (isLogged && !data) {
        return <Forbidden />;
    }
    if (isLogged) {
        return <FilesForStaff data={data} />;
    }

    return null;
};

PanelStaff.propTypes = {
    locale: PropTypes.oneOf([LocaleEnum.PL, LocaleEnum.EN]).isRequired,
};

const mapStateToProps = (state: ApplicationState) => ({
    locale: state.locale.locale,
});

export default connect(mapStateToProps)(PanelStaff);
