import React, { FC } from "react";
import styled from "styled-components";
import {connect} from "react-redux";
import Container from "../Container";
import BreakpointEnum from "../../shared/enums/BreakpointEnum/BreakpointEnum";
import { injectIntl, WrappedComponentProps } from "react-intl";
import PropTypes from "prop-types";
import {ApplicationState} from "../../store";
import LocaleEnum from "../../services/Locale/enums/Locale/LocaleEnum";
import { ThemeEnum } from "../../shared/enums/ThemeEnum/ThemeEnum";

const HorizontalListWrapper = styled.div`
    width: 100%;
    background: var(--main-red, #c0001b);
    color: var(--font-light, #fff);
    padding: 30px 0 60px;

    @media ${BreakpointEnum.MD} {
        padding: 60px 15px 60px;
    }
    @media ${BreakpointEnum.XXL} {
        padding: 120px 15px 120px;
    }
`;

const HorizontalList = styled.div`
    justify-content: space-evenly;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: auto;
`;

const ListItem = styled.a`
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 30px 40px 0;
    cursor: pointer;
    color: var(--font-light, #fff);

    @media ${BreakpointEnum.MD} {
        margin: 30px 50px 0;
    }

    @media ${BreakpointEnum.XXL} {
        margin: 30px 50px 0;
    }
`;

const ListItemTitle = styled.div`
    font-size: calc(14px + var(--font-modificator, 0px));
    text-align: center;
    max-width: 95px;
    margin-top: 10px;
    min-height: 77px;

    @media ${BreakpointEnum.MD} {
        font-size: calc(18px + var(--font-modificator, 0px));
        max-width: 130px;
        margin-top: 25px;
    }

    @media ${BreakpointEnum.XXL} {
        font-size: calc(20px + var(--font-modificator, 0px));
    }
`;

const ListItemIco = styled.img`
    max-width: 80px;
    margin: auto;

    @media ${BreakpointEnum.MD} {
        max-width: 108px;
    }
`;

type iconModel = {
    id: number,
    img: string,
    title: string,
    link: string
}

interface PropsInterface extends WrappedComponentProps {
    locale: LocaleEnum;
    contrast: ThemeEnum;
}

const HorizontalListRow: FC<PropsInterface> = ({ intl, locale, contrast }) => {
    const iconsListMultiLang = {
        pl: [
            {
                id: 1,
                img: `/images/contrast/${contrast}/logo/ico_cok.png`,
                title: intl.formatMessage({id: "homepage.horizontalList.cok"}),
                link: "https://www.cok.agh.edu.pl/"
            },
            {
                id: 2,
                img: `/images/contrast/${contrast}/logo/agh.png`,
                title: intl.formatMessage({id: "homepage.horizontalList.agh"}),
                link: "https://www.agh.edu.pl/"
            },
            {
                id: 3,
                img: `/images/contrast/${contrast}/logo/samorzad.png`,
                title: intl.formatMessage({id: "homepage.horizontalList.library"}),
                link: "http://www.samorzad.imir.agh.edu.pl/",
            },
            {
                id: 4,
                img: `/images/contrast/${contrast}/logo/study.png`,
                title: intl.formatMessage({id: "homepage.horizontalList.study"}),
                link: "/pl/studenci/rozklady_zajec/",
            },
            {
                id: 5,
                img: `/images/contrast/${contrast}/logo/upel.png`,
                title: intl.formatMessage({id: "homepage.horizontalList.upel"}),
                link: "https://upel.agh.edu.pl/"
            },
            {
                id: 6,
                img: `/images/contrast/${contrast}/logo/ico_dss.png`,
                title: intl.formatMessage({id: "homepage.horizontalList.dss"}),
                link: "https://www.dss.agh.edu.pl/"
            },
        ],
        en: [
            {
                id: 1,
                img: `/images/contrast/${contrast}/logo/ico_cok.png`,
                title: intl.formatMessage({id: "homepage.horizontalList.cok"}),
                link: "https://www.cok.agh.edu.pl/"
            },
            {
                id: 2,
                img: `/images/contrast/${contrast}/logo/agh.png`,
                title: intl.formatMessage({id: "homepage.horizontalList.agh"}),
                link: "https://www.agh.edu.pl/"
            },
            {
                id: 3,
                img: `/images/contrast/${contrast}/logo/samorzad.png`,
                title: intl.formatMessage({id: "homepage.horizontalList.library"}),
                link: "http://www.samorzad.imir.agh.edu.pl/",
            },
            {
                id: 4,
                img: `/images/contrast/${contrast}/logo/study.png`,
                title: intl.formatMessage({id: "homepage.horizontalList.study"}),
                link: "/en/students_affairs/timetable/",
            },
            {
                id: 5,
                img: `/images/contrast/${contrast}/logo/upel.png`,
                title: intl.formatMessage({id: "homepage.horizontalList.upel"}),
                link: "https://upel.agh.edu.pl/login/index.php?lang=en"
            },
            {
                id: 6,
                img: `/images/contrast/${contrast}/logo/ico_dss.png`,
                title: intl.formatMessage({id: "homepage.horizontalList.dss"}),
                link: "https://www.dss.agh.edu.pl/en/"
            },
        ],
    }

    const iconsList = iconsListMultiLang[locale];

    return (
        <HorizontalListWrapper>
            <Container withPadding={false}>
                <HorizontalList>
                    {iconsList.map((ico: iconModel) => (
                        <ListItem key={ico.id} href={ico.link} target={"_blank"}>
                            <ListItemIco src={ico.img} alt={ico.title} />
                            <ListItemTitle>{ico.title}</ListItemTitle>
                        </ListItem>
                    ))}
                </HorizontalList>
            </Container>
        </HorizontalListWrapper>
    );
};

HorizontalListRow.propTypes = {
    locale: PropTypes.oneOf([LocaleEnum.PL, LocaleEnum.EN]).isRequired,
    intl: PropTypes.any.isRequired,
};

const mapStateToProps = (state: ApplicationState) => ({
    locale: state.locale.locale,
    contrast: state.contrast.contrast
});

export default connect(mapStateToProps)(injectIntl(HorizontalListRow));

