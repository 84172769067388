import FrontApiErrorInterface from "./FrontApiErrorInterface";
import { StatusCodes } from "http-status-codes";
import FrontApiErrorCodeEnum from "./FrontApiErrorCodeEnum";
import _get from "lodash/get";
import FrontApiErrorsCollection from "./FrontApiErrorsCollection";
import FrontApiNestedErrorInterface from "./FrontApiNestedErrorInterface";

export default class FrontApiError implements FrontApiErrorInterface {
    public status: StatusCodes = StatusCodes.INTERNAL_SERVER_ERROR;
    public code: FrontApiErrorCodeEnum | undefined;
    public message: string;
    public errors: FrontApiErrorsCollection;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public response: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public additionalData: any;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(status: number, response: any) {
        const { code, message, errors, additionalData } = this.parseError(response);

        this.code = code;
        this.message = message;
        this.errors = errors;
        this.response = response;
        this.status = status || this.status;
        this.additionalData = additionalData;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    parseError(response: any) {
        const code = _get(response, "error.code", undefined);
        const message = _get(response, "error.message", "");
        const errors = new FrontApiErrorsCollection(_get(response, "error.errors", []));
        const additionalData = _get(response, "error.additionalData", {});

        return {
            code,
            message,
            errors,
            additionalData,
        };
    }

    getNestedErrorByCode(code: FrontApiErrorCodeEnum): FrontApiNestedErrorInterface | null {
        return this.errors.findByCode(code);
    }

    toJson() {
        const obj: Omit<FrontApiErrorInterface, "response"> = {
            status: this.status,
            code: this.code,
            message: this.message,
            errors: this.errors,
        };

        return obj;
    }
}
