import MenuItemInterface from "./Interface/MenuItemInterface";
import RoutesPath from "../../shared/enums/RoutesPath";

export default [
    {
        id: 0,
        text: "menu.homepage",
        isMultiLang: true,
        routes: {
            pl: RoutesPath.HOMEPAGE.pl,
            en: RoutesPath.HOMEPAGE.en,
        },
    },
    {
        id: 119999,
        text: "menu.news",
        routes: {
            pl: RoutesPath.NEWS.pl,
        },
    },
    {
        id: 1,
        text: "menu.department",
        isParent: true,
        isMultiLang: true,
        categoryName: "department",
        children: [
            {
                id: 10,
                text: "menu.aboutDepartment",
                routes: {
                    pl: RoutesPath.ABOUT_DEOARTMENT.pl,
                    en: RoutesPath.ABOUT_DEOARTMENT.en,
                },
                isMultiLang: true,
            },
                        {
                id: 101,
                text: "menu.elections",
                routes: {
                    pl: RoutesPath.ELECTIONS.pl
                },
                isMultiLang: true,
            },
            {
                id: 11,
                text: "menu.authoritiesOfFaculty",
                isMultiLang: true,
                children: [
                    {
                        id: 111,
                        text: "menu.dean",
                        isMultiLang: true,
                        routes: {
                            pl: RoutesPath.DEAN.pl,
                            en: RoutesPath.DEAN.en,
                        },
                    },
                    {
                        id: 112,
                        text: "menu.deputyDeans",
                        isMultiLang: true,
                        routes: {
                            pl: RoutesPath.DEPUTY_DEANS.pl,
                            en: RoutesPath.DEPUTY_DEANS.en,
                        },
                    },
                    {
                        id: 113,
                        text: "menu.plenipotentiary",
                        isMultiLang: true,
                        routes: {
                            pl: RoutesPath.PLENIPOTENTIARY.pl,
                            en: RoutesPath.PLENIPOTENTIARY.en,
                        },
                    },
                    {
                        id: 114,
                        text: "menu.administration",
                        isMultiLang: true,
                        routes: {
                            pl: RoutesPath.ADMINISTRATION.pl,
                            en: RoutesPath.ADMINISTRATION.en,
                        },
                    },
                    {
                        id: 115,
                        text: "menu.deansOffice",
                        isMultiLang: true,
                        routes: {
                            pl: RoutesPath.DEANS_OFFICE.pl,
                            en: RoutesPath.DEANS_OFFICE.en,
                        },
                    },
                    {
                        id: 116,
                        text: "menu.councilOfTheFaculty",
                        isMultiLang: true,
                        routes: {
                            pl: RoutesPath.COUNCIL_OF_THE_FACULTY.pl,
                            en: RoutesPath.COUNCIL_OF_THE_FACULTY.en,
                        },
                    },
                    {
                        id: 117,
                        text: "menu.facultyTeams",
                        routes: {
                            pl: RoutesPath.FACULTY_TEAMS.pl,
                            en: RoutesPath.FACULTY_TEAMS.en,
                        },
                        isMultiLang: true,
                    },
                    {
                        id: 117,
                        text: "menu.supervisorsOfCoursesAndSpecialties",
                        routes: {
                            pl: RoutesPath.SUPERVISORS_OF_COURSES_AND_SPECIALTIES.pl,
                            en: RoutesPath.SUPERVISORS_OF_COURSES_AND_SPECIALTIES.en,
                        },
                    },
                ],
            },
            {
                id: 12,
                text: "menu.departments",
                routes: {
                    pl: RoutesPath.DEPARTMENTS.pl,
                    en: RoutesPath.DEPARTMENTS.en,
                },
                isMultiLang: true,
            },
            {
                id: 13,
                text: "menu.history",
                isMultiLang: true,
                children: [
                    {
                        id: 131,
                        text: "menu.facultysHistory",
                        routes: {
                            pl: RoutesPath.FACULTYS_HISTORY.pl,
                            en: RoutesPath.FACULTYS_HISTORY.en,
                        },
                        isMultiLang: true,
                    },
                    {
                        id: 132,
                        text: "menu.deans",
                        routes: {
                            pl: RoutesPath.DEANS.pl,
                            en: RoutesPath.DEANS.en,
                        },
                        isMultiLang: true,
                    },
                    {
                        id: 133,
                        text: "menu.importantsEvents",
                        routes: {
                            pl: RoutesPath.IMPORTANTS_EVENTS.pl,
                            en: RoutesPath.IMPORTANTS_EVENTS.en,
                        },
                        isMultiLang: true,
                    },
                ],
            },
            {
                id: 14,
                text: "menu.discipline",
                children: [
                    {
                        id: 141,
                        text: "menu.team",
                        routes: {
                            pl: RoutesPath.MENU_TEAM.pl,
                        },
                    },
                    {
                        id: 142,
                        text: "menu.doctorates",
                        routes: {
                            pl: RoutesPath.DOCTORATES.pl,
                        },
                        targetBlank: true,
                    },
                    {
                        id: 143,
                        text: "menu.habilitation",
                        routes: {
                            pl: RoutesPath.HABILITATION.pl,
                        },
                        targetBlank: true,
                    },
                    {
                        id: 144,
                        text: "menu.qualificationRules",
                        routes: {
                            pl: RoutesPath.QUALIFICATION_RULES.pl,
                        },
                    },
                ],
            },
            {
                id: 1333333333,
                text: "menu.publicCouncilOfFaculty",
                routes: {
                    pl: RoutesPath.PUBLIC_COUNCIL_OF_FACULTY.pl,
                    en: RoutesPath.PUBLIC_COUNCIL_OF_FACULTY.en,
                },
                isMultiLang: true,
            },
            {
                id: 15,
                text: "menu.qualityEducation",
                children: [
                    {
                        id: 151,
                        text: "menu.qualityEducation",
                        routes: {
                            pl: RoutesPath.QUALITY_EDUCATION.pl,
                        },
                    },
                    // {
                    //     id: 152,
                    //     text: "menu.qualityEducationTeam",
                    //     routes: {
                    //         pl: RoutesPath.QUALITY_EDUCATION_TEAM.pl,
                    //     },
                    // },
                    // {
                    //     id: 153,
                    //     text: "menu.qualityEducationTeamAudit",
                    //     routes: {
                    //         pl: RoutesPath.QUALITY_EDUCATION_TEAM_AUDIT.pl,
                    //     },
                    // },
                    {
                        id: 1533,
                        text: "menu.qualityEducationWimirAudit",
                        routes: {
                            pl: RoutesPath.QUALITY_EDUCATION_WIMIR_AUDIT.pl,
                        },
                    },
                    {
                        id: 1534,
                        text: "menu.qualityEducationAccreditation",
                        routes: {
                            pl: RoutesPath.QUALITY_EDUCATION_ACCREDITATION.pl,
                        },
                        children: [
                            {
                                id: 3712,
                                text: "menu.qualityEducationAccreditationKAUT-MiBM",
                                routes: {
                                    pl: RoutesPath.QUALITY_EDUCATION_ACCREDITATION_KAUT_MIBM.pl,
                                },
                            },
                            {
                                id: 3713,
                                text: "menu.qualityEducationAccreditationKAUT-AIR",
                                routes: {
                                    pl: RoutesPath.QUALITY_EDUCATION_ACCREDITATION_KAUT_AIR.pl,
                                },
                            },
                            {
                                id: 3714,
                                text: "menu.qualityEducationDistinction",
                                routes: {
                                    pl: RoutesPath.QUALITY_EDUCATION_DISTINCTION.pl,
                                },
                            },
                            {
                                id: 3715,
                                text: "menu.qualityEducationAccreditationAiR",
                                routes: {
                                    pl: RoutesPath.QUALITY_EDUCATION_ACCREDITATION_AIR.pl,
                                },
                            },
                        ],
                    },
                    {
                        id: 155,
                        text: "menu.qualityEducationRanking",
                        routes: {
                            pl: RoutesPath.QUALITY_EDUCATION_RANKING.pl,
                        },
                        children: [
                            {
                                id: 1554,
                                text: "menu.qualityEducation2021",
                                routes: {
                                    pl: RoutesPath.QUALITY_EDUCATION_2021.pl,
                                },
                            },
                            {
                                id: 1555,
                                text: "menu.qualityEducation2020",
                                routes: {
                                    pl: RoutesPath.QUALITY_EDUCATION_2020.pl,
                                },
                            },
                            {
                                id: 1556,
                                text: "menu.qualityEducation2019",
                                routes: {
                                    pl: RoutesPath.QUALITY_EDUCATION_2019.pl,
                                },
                            },
                            {
                                id: 1557,
                                text: "menu.qualityEducation2018",
                                routes: {
                                    pl: RoutesPath.QUALITY_EDUCATION_2018.pl,
                                },
                            },
                            {
                                id: 1558,
                                text: "menu.qualityEducation2017",
                                routes: {
                                    pl: RoutesPath.QUALITY_EDUCATION_2017.pl,
                                },
                            },
                            {
                                id: 1559,
                                text: "menu.qualityEducation2016",
                                routes: {
                                    pl: RoutesPath.QUALITY_EDUCATION_2016.pl,
                                },
                            },
                            {
                                id: 15591,
                                text: "menu.qualityEducation2015",
                                routes: {
                                    pl: RoutesPath.QUALITY_EDUCATION_2015.pl,
                                },
                            },
                            {
                                id: 15592,
                                text: "menu.qualityEducation2014",
                                routes: {
                                    pl: RoutesPath.QUALITY_EDUCATION_2014.pl,
                                },
                            },
                        ],
                    },
                    {
                        id: 1593,
                        text: "menu.surveyResults",
                        routes: {
                            pl: RoutesPath.SURVEY_RESULTS.pl,
                        },
                    },
                    {
                        id: 1594,
                        text: "menu.surveyResultsDoctoral",
                        routes: {
                            pl: RoutesPath.SURVEY_RESULTS_DOCTORAL.pl,
                        },
                    },
                ],
            },
            {
                id: 16,
                text: "menu.forStaff",
                children: [
                    {
                        id: 161,
                        text: "menu.documentTemplates",
                        routes: {
                            pl: RoutesPath.DOCUMENT_TEMPLATES.pl,
                        },
                    },
                    {
                        id: 162,
                        text: "menu.success",
                        routes: {
                            pl: RoutesPath.SUCCESS.pl,
                        },
                    },
                ],
            },
            {
                id: 17,
                text: "menu.facultyAdministrationOffice",
                routes: {
                    pl: RoutesPath.FACULTY_ADMINISTRATION_OFFICE.pl,
                },
            },
            {
                id: 20,
                text: "menu.fromLifeOfTheFaculty",
                routes: {
                    pl: RoutesPath.FROM_LIFE_OF_THE_FACULTY.pl,
                },
            },
            {
                id: 18,
                text: "menu.contact",
                isMultiLang: true,
                routes: {
                    pl: RoutesPath.CONTACT.pl,
                    en: RoutesPath.CONTACT.en,
                },
            },
            {
                id: 181,
                text: "menu.accessibilityDeclaration",
                isMultiLang: false,
                routes: {
                    pl: RoutesPath.ACCESSIBILITY_DECLARATION.pl,
                },
            },
        ],
    },
    {
        id: 2,
        text: "menu.science",
        isParent: true,
        isMultiLang: true,
        categoryName: "research",
        children: [
            // {
            //     id: 21,
            //     text: "menu.decisionsDeans",
            //     routes: {
            //         pl: RoutesPath.DECISIONS_DEANS.pl,
            //     },
            // },
            {
                id: 22,
                text: "menu.communicationVideDean",
                routes: {
                    pl: RoutesPath.COMMUNICATION_VIDEO_DEAN.pl,
                },
            },
            {
                id: 22290,
                text: "menu.scientificAndResearchOffer",
                routes: {
                    pl: RoutesPath.SCIENTIFIC_AND_RESEARCH_OFFER.pl,
                },
            },
            {
                id: 23,
                text: "menu.categorizationMinistry2017",
                routes: {
                    pl: RoutesPath.CATEGORIZATION_MINISTRY_2017.pl,
                    en: RoutesPath.CATEGORIZATION_MINISTRY_2017.en,
                },
                isMultiLang: true,
            },
            {
                id: 24,
                text: "menu.publications",
                isMultiLang: true,
                routes: {
                    pl: RoutesPath.PUBLICATIONS.pl,
                    en: RoutesPath.PUBLICATIONS.en,
                },
                targetBlank: true,
            },
            {
                id: 25,
                text: "menu.laboratories",
                routes: {
                    pl: RoutesPath.LABORATORIES.pl,
                    en: RoutesPath.LABORATORIES.en,
                },
                targetBlank: true,
                isMultiLang: true,
            },
            {
                id: 26,
                text: "menu.foundUE",
                routes: {
                    pl: RoutesPath.FOUND_UE.pl,
                },
            },
            {
                id: 27,
                text: "menu.implementationDoctorate",
                routes: {
                    pl: RoutesPath.IMPLEMENTATION_DOCTORATE.pl,
                },
            },
            {
                id: 28,
                text: "menu.youngResearchersWIMIR",
                routes: {
                    pl: RoutesPath.YOUNG_RESEARCHERS_WIMIR.pl,
                },
            },
            {
                id: 29,
                text: "menu.phdStudents",
                routes: {
                    pl: RoutesPath.PHD_STUDENTS.pl,
                    en: RoutesPath.PHD_STUDENTS.en
                },
                targetBlank: true,
                isMultiLang: true,
            },
        ],
    },
    {
        id: 3,
        text: "menu.candidates",
        isParent: true,
        isMultiLang: true,
        categoryName: "study",
        children: [
            {
                id: 31,
                text: "menu.stationary1class",
                routes: {
                    pl: RoutesPath.STATIONARY_1_CLASS.pl,
                    en: RoutesPath.STATIONARY_1_CLASS.en,
                },
                isMultiLang: true,
            },
            {
                id: 32,
                text: "menu.stationary2class",
                routes: {
                    pl: RoutesPath.STATIONARY_2_CLASS.pl,
                    en: RoutesPath.STATIONARY_2_CLASS.en,
                },
                isMultiLang: true,
            },
            {
                id: 33,
                text: "menu.stationary3class",
                routes: {
                    pl: RoutesPath.STATIONARY_3_CLASS.pl,
                    en: RoutesPath.STATIONARY_3_CLASS.en,
                },
                isMultiLang: true,
            },
            {
                id: 34,
                text: "menu.partTimeStudies",
                routes: {
                    pl: RoutesPath.PART_TIME_STUDIES.pl,
                    en: RoutesPath.PART_TIME_STUDIES.en,
                },
                isMultiLang: true,
            },
            {
                id: 35,
                text: "menu.postgraduateStudies",
                routes: {
                    pl: RoutesPath.POSTGRADUATE_STUDIES.pl,
                    en: RoutesPath.POSTGRADUATE_STUDIES.en,
                },
                isMultiLang: true,
            },
            {
                id: 36,
                text: "menu.coursesOfStudy",
                isParent: true,
                isMultiLang: true,
                children: [
                    {
                        id: 361,
                        text: "menu.mechanicalEngineering",
                        routes: {
                            pl: RoutesPath.MECHANICAL_ENGINEERING.pl,
                            en: RoutesPath.MECHANICAL_ENGINEERING.en,
                        },
                        isMultiLang: true,
                    },
                    {
                        id: 362,
                        text: "menu.automationAndRobotics",
                        routes: {
                            pl: RoutesPath.AUTONATION_AND_ROBOTICS.pl,
                            en: RoutesPath.AUTONATION_AND_ROBOTICS.en,
                        },
                        isMultiLang: true,
                    },
                    {
                        id: 363,
                        text: "menu.mechatronicEngineering",
                        routes: {
                            pl: RoutesPath.MECHATRONIC_ENGINEERING.pl,
                            en: RoutesPath.MECHATRONIC_ENGINEERING.en,
                        },
                        isMultiLang: true,
                    },
                    // {
                    //     id: 366,
                    //     text: "menu.mechanicalAndMaterialsEngineering",
                    //     routes: {
                    //         pl: RoutesPath.MECHANIC_AND_MATERIAL_ENGINEERING.pl,
                    //         en: RoutesPath.MECHANIC_AND_MATERIAL_ENGINEERING.en,
                    //     },
                    //     isMultiLang: true,
                    // },
                    {
                        id: 364,
                        text: "menu.mechanicalAndMaterialsEngineering",
                        routes: {
                            pl: RoutesPath.MECHANICAL_AND_MATERIALS_ENGINEERING.pl,
                            en: RoutesPath.MECHANICAL_AND_MATERIALS_ENGINEERING.en,
                        },
                        isMultiLang: true,
                    },
                    {
                        id: 365,
                        text: "menu.acousticEngineering",
                        routes: {
                            pl: RoutesPath.ACOUSTIC_ENGINEERING.pl,
                            en: RoutesPath.ACOUSTIC_ENGINEERING.en,
                        },
                        isMultiLang: true,
                    },
                    {
                        id: 366,
                        text: "menu.mechatronics",
                        routes: {
                            pl: RoutesPath.KIERUNKI_MECHATRONICS.pl,
                            en: RoutesPath.KIERUNKI_MECHATRONICS.en,
                        },
                        isMultiLang: true,
                    },
                    {
                        id: 367,
                        text: "menu.transportForIndustry",
                        routes: {
                            pl: RoutesPath.TRANSPORT_FOR_INDUSTRY.pl,
                            en: RoutesPath.TRANSPORT_FOR_INDUSTRY.en,
                        },
                        isMultiLang: true,
                    },
                ],
            },
            {
                id: 37,
                text: "menu.specialization",
                routes: {
                    pl: RoutesPath.SPECIALIZATIONS.pl,
                    en: RoutesPath.SPECIALIZATIONS.en,
                },
                isParent: true,
                isMultiLang: true,
                children: [
                    {
                        id: 3700,
                        text: "menu.specialtiesAutomationAndMetreology",
                        routes: {
                            pl: RoutesPath.SPECIALIZATIONS_AUTOMATION_AND_METROLOGY.pl,
                            en: RoutesPath.SPECIALIZATIONS_AUTOMATION_AND_METROLOGY.en,
                        },
                        isMultiLang: true,
                    },
                    {
                        id: 3701,
                        text: "menu.specialtiesRobotics",
                        routes: {
                            pl: RoutesPath.SPECIALIZATIONS_ROBOTICS.pl,
                            en: RoutesPath.SPECIALIZATIONS_ROBOTICS.en,
                        },
                        isMultiLang: true,
                    },
                    {
                        id: 3702,
                        text: "menu.specialtiesVibrationAndNoiseInTechnology",
                        routes: {
                            pl: RoutesPath.SPECIALIZATIONS_VIBRATION_NOISE_IN_TECHNOLOGY.pl,
                            en: RoutesPath.SPECIALIZATIONS_VIBRATION_NOISE_IN_TECHNOLOGY.en,
                        },
                        isMultiLang: true,
                    },
                    {
                        id: 3703,
                        text: "menu.specialtiesSoundEngineeringMediaCulture",
                        routes: {
                            pl: RoutesPath.SPECIALIZATIONS_SOUND_ENGINEERING_MEDIA_CULTURE.pl,
                            en: RoutesPath.SPECIALIZATIONS_SOUND_ENGINEERING_MEDIA_CULTURE.en,
                        },
                        isMultiLang: true,
                    },
                    {
                        id: 3704,
                        text: "menu.specialtiesMechatronicDesign",
                        routes: {
                            pl: RoutesPath.SPECIALIZATIONS_MECHATRONIC_DESIGN.pl,
                            en: RoutesPath.SPECIALIZATIONS_MECHATRONIC_DESIGN.en,
                        },
                        isMultiLang: true,
                    },
                    {
                        id: 3705,
                        text: "menu.specialtiesIntelligentSystems",
                        routes: {
                            pl: RoutesPath.SPECIALIZATIONS_INTELLIGENY_SYSTEMS.pl,
                            en: RoutesPath.SPECIALIZATIONS_INTELLIGENY_SYSTEMS.en,
                        },
                        isMultiLang: true,
                    },
                    {
                        id: 3706,
                        text: "menu.specialtiesMechatronicManufacturing",
                        routes: {
                            pl: RoutesPath.SPECIALIZATIONS_MECHATRONIC_FABRICATION.pl,
                            en: RoutesPath.SPECIALIZATIONS_MECHATRONIC_FABRICATION.en,
                        },
                        isMultiLang: true,
                    },
                    {
                        id: 3707,
                        text: "menu.specialtiesOperationAndTechnologyOfMachinesAndVechicles",
                        routes: {
                            pl: RoutesPath.SPECIALIZATIONS_OPERATION_AND_TECHNOLOGY_OF_MACHINES_AND_VECHICLES.pl,
                            en: RoutesPath.SPECIALIZATIONS_OPERATION_AND_TECHNOLOGY_OF_MACHINES_AND_VECHICLES.en,
                        },
                        isMultiLang: true,
                    },
                    {
                        id: 3708,
                        text: "menu.specialtiesComputerScienceSpecializationInMechanicalEngineering",
                        routes: {
                            pl: RoutesPath.SPECIALIZATIONS_COMPUTER_SCIENCE_SPECIALIZATION_IN_MECHANICAL_ENGINEERING.pl,
                            en: RoutesPath.SPECIALIZATIONS_COMPUTER_SCIENCE_SPECIALIZATION_IN_MECHANICAL_ENGINEERING.en,
                        },
                        isMultiLang: true,
                    },
                    {
                        id: 3709,
                        text: "menu.specialtiesEngineeringOfConstructionMats",
                        routes: {
                            pl: RoutesPath.SPECIALIZATIONS_ENGINEERING_OF_CONSTRUCTION_MATS.pl,
                            en: RoutesPath.SPECIALIZATIONS_ENGINEERING_OF_CONSTRUCTION_MATS.en,
                        },
                        isMultiLang: true,
                    },
                    {
                        id: 3710,
                        text: "menu.specialtiesManufacturingSystemsEngineering",
                        routes: {
                            pl: RoutesPath.SPECIALIZATIONS_MANUFACTURING_SYSTEMS_ENGINEERING.pl,
                            en: RoutesPath.SPECIALIZATIONS_MANUFACTURING_SYSTEMS_ENGINEERING.en,
                        },
                        isMultiLang: true,
                    },
                    {
                        id: 3711,
                        text: "menu.specialtiesEngineeringOfSustainableEnergySystems",
                        routes: {
                            pl: RoutesPath.SPECIALIZATIONS_ENGINEERING_OF_SUSTAINABLE_SYSTEMS.pl,
                            en: RoutesPath.SPECIALIZATIONS_ENGINEERING_OF_SUSTAINABLE_SYSTEMS.en,
                        },
                        isMultiLang: true,
                    },
                    {
                        id: 3712,
                        text: "menu.specialtiesComputerAidedDesign",
                        routes: {
                            pl: RoutesPath.SPECIALIZATIONS_COMPUTER_AIDED_DESIGN.pl,
                            en: RoutesPath.SPECIALIZATIONS_COMPUTER_AIDED_DESIGN.en,
                        },
                        isMultiLang: true,
                    },
                    {
                        id: 3713,
                        text: "menu.specialtiesMachinesForEarthworksAndMaterialHandling",
                        routes: {
                            pl: RoutesPath.SPECIALIZATIONS_MACHINES_FOR_EATHWORKS_AND_MATERIAL_HANDLING.pl,
                            en: RoutesPath.SPECIALIZATIONS_MACHINES_FOR_EATHWORKS_AND_MATERIAL_HANDLING.en,
                        },
                        isMultiLang: true,
                    },
                    {
                        id: 3714,
                        text: "menu.specialtiesMiningMachines",
                        routes: {
                            pl: RoutesPath.SPECIALIZATIONS_MINING_MACHINES.pl,
                            en: RoutesPath.SPECIALIZATIONS_MINING_MACHINES.en,
                        },
                        isMultiLang: true,
                    },
                    {
                        id: 3715,
                        text: "menu.specialtiesRopeTransport",
                        routes: {
                            pl: RoutesPath.SPECIALIZATIONS_ROPE_TRANSPORT.pl,
                            en: RoutesPath.SPECIALIZATIONS_ROPE_TRANSPORT.en,
                        },
                        isMultiLang: true,
                    },
                    {
                        id: 3716,
                        text: "menu.specjalnosci_ime_mechatronic_design",
                        routes: {
                            pl: RoutesPath.SPECIALIZATIONS_IME_MECHATRONIC_DESIGN.pl,
                            en: RoutesPath.SPECIALIZATIONS_IME_MECHATRONIC_DESIGN.en,
                        },
                        isMultiLang: true,
                    },
                    {
                        id: 3717,
                        text: "menu.specialtiesTechnologicalTransport",
                        routes: {
                            pl: RoutesPath.SPECIALIZATIONS_TWP_TECHNOLOGICAL_TRANSPORT.pl,
                            en: RoutesPath.SPECIALIZATIONS_TWP_TECHNOLOGICAL_TRANSPORT.en,
                        },
                        isMultiLang: true,
                    },
                    {
                        id: 3718,
                        text: "menu.specialtiesAutonomousTransport",
                        routes: {
                            pl: RoutesPath.SPECIALIZATIONS_TWP_AUTONOMOUSE_TRANSPORT.pl,
                            en: RoutesPath.SPECIALIZATIONS_TWP_AUTONOMOUSE_TRANSPORT.en,
                        },
                        isMultiLang: true,
                    },
                    {
                        id: 3719,
                        text: "menu.specialtiesIntralogistics",
                        routes: {
                            pl: RoutesPath.SPECIALIZATIONS_TWP_INTRALOGISTICS.pl,
                            en: RoutesPath.SPECIALIZATIONS_TWP_INTRALOGISTICS.en,
                        },
                        isMultiLang: true,
                    },
                ]
            },
            // children: [
            //     {
            //         id: 371,
            //         text: "menu.specialtiesMechanicalEngineering",
            //         href: "/pl/studia/specjalnosci/mechanika_i_budowa_maszyn/",
            //         isMultiLang: true,
            //         children: [
            //             {
            //                 id: 3712,
            //                 text: "menu.specialtiesOperationAndTechnologyOfMachinesAndVechicles",
            //                 href: "/pl/studia/specjalnosci/mechanika_i_budowa_maszyn/eksploatacja_i_technologia_maszyn_i_pojazdow",
            //                 isMultiLang: true,
            //             },
            //             {
            //                 id: 3713,
            //                 text: "menu.specialtiesSustainableEnergySystemEngineering",
            //                 href: "/pl/studia/specjalnosci/mechanika_i_budowa_maszyn/inzynieria_zrownowazonych_systemow_energetycznych/",
            //                 isMultiLang: true,
            //             },
            //             {
            //                 id: 3714,
            //                 text: "menu.specialtiesItInMechanicalEngineering",
            //                 href: "/pl/studia/specjalnosci/mechanika_i_budowa_maszyn/informatyka_w_inzynierii_mechanicznej/",
            //                 isMultiLang: true,
            //             },
            //             {
            //                 id: 3715,
            //                 text: "menu.specialtiesManufacturingSystemEngineering",
            //                 href: "/pl/studia/specjalnosci/mechanika_i_budowa_maszyn/inzynieria_systemow_wytwarzania/",
            //                 isMultiLang: true,
            //             },
            //             {
            //                 id: 3716,
            //                 text: "menu.specialtiesMachinesForEarthmovingAndHandling",
            //                 href: "/pl/studia/specjalnosci/mechanika_i_budowa_maszyn/maszyny_do_robot_ziemnych_i_transportu_bliskiego/",
            //                 isMultiLang: true,
            //             },
            //             {
            //                 id: 3717,
            //                 text: "menu.specialtiesComputerAssistanceOfDesing",
            //                 href: "/pl/studia/specjalnosci/mechanika_i_budowa_maszyn/komputerowe_wspomaganie_projektowania/",
            //                 isMultiLang: true,
            //             },
            //             {
            //                 id: 3718,
            //                 text: "menu.specialtiesRopeTransport",
            //                 href: "/pl/studia/specjalnosci/mechanika_i_budowa_maszyn/transport_linowy/",
            //                 isMultiLang: true,
            //             },
            //         ],
            //     },
            //     {
            //         id: 372,
            //         text: "menu.specialtiesAutomationAndRobotics",
            //         href: "/pl/studia/specjalnosci/automatyka_i_robotyka/",
            //         isMultiLang: true,
            //         children: [
            //             {
            //                 id: 3721,
            //                 text: "menu.specialtiesAutomationAndMetrology",
            //                 href: "/pl/studia/specjalnosci/automatyka_i_robotyka/automatyka_i_metrologia/",
            //                 isMultiLang: true,
            //             },
            //             {
            //                 id: 3722,
            //                 text: "menu.specialtiesAutomationInMiningMachineSystems",
            //                 href: "/pl/studia/specjalnosci/automatyka_i_robotyka/automatyka_w_gorniczych_systemach_maszynowych/",
            //                 isMultiLang: true,
            //             },
            //             {
            //                 id: 3723,
            //                 text: "menu.specialtiesAutomationInTransportSystems",
            //                 href: "/pl/studia/specjalnosci/automatyka_i_robotyka/automatyzacja_w_systemach_transportowych/",
            //                 isMultiLang: true,
            //             },
            //             {
            //                 id: 3724,
            //                 text: "menu.specialtiesRobotics",
            //                 href: "/pl/studia/specjalnosci/automatyka_i_robotyka/robotyka/",
            //                 isMultiLang: true,
            //             },
            //         ],
            //     },
            //     {
            //         id: 373,
            //         text: "menu.specialtiesMechatronicEngineering",
            //         href: "/pl/studia/specjalnosci/inzynieria_mechatroniczna/",
            //         isMultiLang: true,
            //         children: [
            //             {
            //                 id: 3731,
            //                 text: "menu.specialtiesMechatronicDesing",
            //                 href: "/pl/studia/specjalnosci/inzynieria_mechatroniczna/projektowanie_mechatroniczne/",
            //                 isMultiLang: true,
            //             },
            //             {
            //                 id: 3732,
            //                 text: "menu.specialtiesMechatronicManufacturing",
            //                 href: "/pl/studia/specjalnosci/inzynieria_mechatroniczna/wytwarzanie_mechatroniczne/",
            //                 isMultiLang: true,
            //             },
            //             {
            //                 id: 3733,
            //                 text: "menu.specialtiesIntelligentSystems",
            //                 href: "/pl/studia/specjalnosci/inzynieria_mechatroniczna/systemy_inteligentne/",
            //             },
            //         ],
            //     },
            // ],
            // },
            {
                id: 374,
                text: "menu.recruitment",
                isMultiLang: true,
                isParent: true,
                children: [
                    {
                        id: 3741,
                        text: "menu.recruitment",
                        isMultiLang: true,
                        routes: {
                            pl: RoutesPath.RECRUITMENT.pl,
                            en: RoutesPath.RECRUITMENT.en,
                        },
                    },
                    {
                        id: 3742,
                        text: "menu.admissionToFirstDegreeStudies",
                        isMultiLang: true,
                        routes: {
                            pl: RoutesPath.ADMISSION_TO_FIRST_DEGREE_STUDIES.pl,
                            en: RoutesPath.ADMISSION_TO_FIRST_DEGREE_STUDIES.en,
                        },
                        targetBlank: true,
                    },
                    {
                        id: 3743,
                        text: "menu.admissionToSecondDegreeStudies",
                        isMultiLang: true,
                        routes: {
                            pl: RoutesPath.ADMISSION_TO_SECOND_DEGREE_STUDIES.pl,
                            en: RoutesPath.ADMISSION_TO_SECOND_DEGREE_STUDIES.en,
                        },
                    },
                ],
            },
            {
                id: 375,
                text: "menu.statistics",
                isMultiLang: true,
                routes: {
                    pl: RoutesPath.STATISTICS.pl,
                    en: RoutesPath.STATISTICS.en,
                },
            },
            {
                id: 376,
                text: "menu.accreditationABET",
                isParent: true,
                isMultiLang: true,
                routes: {
                    pl: RoutesPath.ACCREDITATION_ABET.pl,
                    en: RoutesPath.ACCREDITATION_ABET.en,
                },
                children: [
                    {
                        id: 3761,
                        isMultiLang: true,
                        text: "menu.mechatronicEngineeringEnglishFirstDegree",
                        routes: {
                            pl: RoutesPath.MECHATRONIC_ENGINEERING_ENGLISH_FIRST_DEGREE.pl,
                            en: RoutesPath.MECHATRONIC_ENGINEERING_ENGLISH_FIRST_DEGREE.en,
                        },
                    },
                    {
                        id: 3762,
                        isMultiLang: true,
                        text: "menu.mechatronicEngineeringEnglishSecondDegree",
                        routes: {
                            pl: RoutesPath.MECHATRONIC_ENGINEERING_ENGLISH_SECOND_DEGREE.pl,
                            en: RoutesPath.MECHATRONIC_ENGINEERING_ENGLISH_SECOND_DEGREE.en,
                        },
                    },
                ],
            },
            {
                id: 377,
                text: "menu.power35",
                isParent: true,
                routes: {
                    pl: RoutesPath.POWER_35.pl,
                },
                children: [
                    {
                        id: 3771,
                        text: "menu.power35News",
                        routes: {
                            pl: RoutesPath.POWER_35_NEWS.pl,
                        },
                    },
                    {
                        id: 3772,
                        text: "menu.power35NewsArchive2018",
                        routes: {
                            pl: RoutesPath.POWER_35_NEWS_ARCHIVE_2018.pl,
                        },
                    },
                    {
                        id: 3773,
                        text: "menu.power35Documents",
                        routes: {
                            pl: RoutesPath.POWER_35_NEWS_DOCUMENTS.pl,
                        },
                    },
                    {
                        id: 3774,
                        text: "menu.power35DocumentsArchive2018",
                        routes: {
                            pl: RoutesPath.POWER_35_NEWS_DOCUMENTS_ARCHIVE_2018.pl,
                        },
                    },
                    {
                        id: 3775,
                        text: "menu.power35tasks2020",
                        routes: {
                            pl: RoutesPath.POWER_35_TASKS_2020.pl,
                        },
                    },
                    {
                        id: 3776,
                        text: "menu.power35tasks2019",
                        routes: {
                            pl: RoutesPath.POWER_35_TASKS_2019.pl,
                        },
                    },
                    {
                        id: 3777,
                        text: "menu.power35tasks2018",
                        routes: {
                            pl: RoutesPath.POWER_35_TASKS_2018.pl,
                        },
                    },
                ],
            },
            {
                id: 378,
                text: "menu.erasmusPlus",
                isParent: true,
                routes: {
                    pl: RoutesPath.ERASMUS_PLUS.pl,
                    en: RoutesPath.ERASMUS_PLUS.en,
                },
                targetBlank: true,
                isMultiLang: true,
            },
            {
                id: 379,
                text: "menu.universityBaseOfMajorCourses",
                isParent: true,
                routes: {
                    pl: RoutesPath.UNIVERSITY_BASE_OF_MAJOR_COURSES.pl,
                },
                targetBlank: true,
            },
        ],
    },
    {
        id: 4,
        text: "menu.students",
        isParent: false,
        categoryName: "students",
        isMultiLang: true,
        children: [
            {
                id: 11241,
                text: "menu.timetablePage",
                isMultiLang: true,
                isParent: true,
                routes: {
                    pl: RoutesPath.BACHELOR_MASTER_ALL.pl,
                    en: RoutesPath.BACHELOR_MASTER_ALL.en,
                },
                children: [
                    {
                        id: 112411,
                        text: "menu.bachelorMaster",
                        routes: {
                            pl: RoutesPath.BACHELOR_MASTER.pl,
                            en: RoutesPath.BACHELOR_MASTER.en,
                        },
                        isMultiLang: true,
                    },
                    {
                        id: 112412,
                        text: "menu.bachelorMasterFall",
                        routes: {
                            pl: RoutesPath.BACHELOR_MASTER_FALL.pl,
                            en: RoutesPath.BACHELOR_MASTER_FALL.en,
                        },
                        isMultiLang: true,
                    },
                    {
                        id: 112413,
                        text: "menu.bachelorMasterExtramural",
                        routes: {
                            pl: RoutesPath.BACHELOR_MASTER_EXTRAMURAL.pl,
                        },
                    },
                ],
            },
            {
                id: 11242,
                text: "menu.newsPage",
                routes: {
                    pl: RoutesPath.NEWS_PAGE.pl,
                },
            },
            {
                id: 11243,
                text: "menu.newsExtramuralPage",
                routes: {
                    pl: RoutesPath.NEWS_EXTRAMURAL_PAGE.pl,
                },
            },
            {
                id: 11244,
                text: "menu.studentsFormAndDocuments",
                isParent: true,
                children: [
                    {
                        id: 112441,
                        text: "menu.studentsForm",
                        routes: {
                            pl: RoutesPath.STUDENTS_FORM.pl,
                        },
                    },
                    {
                        id: 112442,
                        text: "menu.studentsDocuments",
                        routes: {
                            pl: RoutesPath.STUDENTS_DOCUMENTS.pl,
                        },
                    },
                ],
            },
            {
                id: 11245,
                text: "menu.practice",
                isMultiLang: true,
                isParent: true,
                children: [
                    {
                        id: 112451,
                        text: "menu.practiceIndividual",
                        routes: {
                            pl: RoutesPath.PRACTICE_INDIVIDUAL.pl,
                            en: RoutesPath.PRACTICE_INDIVIDUAL.en
                        },
                        isMultiLang: true,
                    },
                    {
                        id: 112452,
                        text: "menu.practiceGraduation",
                        routes: {
                            pl: RoutesPath.PRACTICE_GRADUATION.pl,
                            en: RoutesPath.PRACTICE_GRADUATION.en
                        },
                        isMultiLang: true,
                    },
                    {
                        id: 112453,
                        text: "menu.practiceOffers",
                        routes: {
                            pl: RoutesPath.PRACTICE_OFFERS.pl,
                        },
                    },
                    {
                        id: 112454,
                        text: "menu.practiceGuardians",
                        routes: {
                            pl: RoutesPath.PRACTICE_GUARDIANS.pl,
                            en: RoutesPath.PRACTICE_GUARDIANS.en
                        },
                        isMultiLang: true,
                    },
                ],
            },
            {
                id: 11246,
                text: "menu.studentsScholarship",
                isParent: true,
                children: [
                    {
                        id: 112461,
                        text: "menu.studentsScholarshipRector",
                        routes: {
                            pl: RoutesPath.STUDENTS_SCHOLARSHIP_RECTOR.pl,
                        },
                    },
                    {
                        id: 112462,
                        text: "menu.studentsScholarshipMinister",
                        routes: {
                            pl: RoutesPath.STUDENTS_SCHOLARSHIP_MINISTER.pl,
                        },
                    },
                ],
            },
            {
                id: 112467777777777777,
                text: "menu.studentsSocialHelp",
                routes: {
                    pl: RoutesPath.STUDENTS_SOCIAL_HELP.pl,
                },
            },
            {
                id: 11247,
                text: "menu.diploma",
                isParent: true,
                isMultiLang: true,
                children: [
                    {
                        id: 112471,
                        text: "menu.diploma1class",
                        isMultiLang: true,
                        routes: {
                            pl: RoutesPath.DIPLOMA_1_CLASS.pl,
                            en: RoutesPath.DIPLOMA_1_CLASS.en,
                        },
                    },
                    {
                        id: 112471,
                        text: "menu.diploma2class",
                        isMultiLang: true,
                        routes: {
                            pl: RoutesPath.DIPLOMA_2_CLASS.pl,
                            en: RoutesPath.DIPLOMA_2_CLASS.en,
                        },
                    },
                ],
            },
            {
                id: 11248,
                text: "menu.studentsDisabled",
                routes: {
                    pl: RoutesPath.STUDENTS_DISABLED.pl,
                },
            },
            {
                id: 11249,
                text: "menu.msdn",
                routes: {
                    pl: RoutesPath.MSDN.pl,
                },
            },
            {
                id: 11251,
                text: "menu.bankAccount",
                routes: {
                    pl: RoutesPath.BANK_ACCOUNT.pl,
                },
            },
        ],
    },
] as MenuItemInterface[];
