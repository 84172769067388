// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { Component } from "react";
import { IntlProvider } from "react-intl";
import { connect } from "react-redux";
import LocaleEnum from "../../enums/Locale/LocaleEnum";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { changeLocaleAction } from "../../store/Actions";
import pl from "../../translations/pl.json";
import en from "../../translations/en.json";
import { ApplicationState } from "../../../../store";

interface PropsInterface extends RouteComponentProps {
    locale: LocaleEnum;
    changeLocaleAction: typeof changeLocaleAction;
    children: React.ReactNode | React.ReactNodeArray;
}

interface StateInterface {
    init: boolean;
    ownUpdate: boolean;
    locale: LocaleEnum;
}

class LocaleProvider extends Component<PropsInterface> {
    static defaultProps = {
        locale: LocaleEnum.PL,
    };

    readonly state: StateInterface = {
        locale: this.props.locale,
        init: false,
        ownUpdate: false,
    };

    componentDidMount() {
        const path: string = this.props.location.pathname;
        let locale = this.props.locale;

        if (path && path.length > 3 && path.substring(0, 4) === "/en/") {
            this.props.changeLocaleAction(LocaleEnum.EN);
            locale = LocaleEnum.EN;
        }

        this.setState({
            locale,
            init: true,
            ownUpdate: true,
        });
    }

    getAvailableLocales() {
        return Object.values(LocaleEnum);
    }

    render() {
        if (!this.state.init) return null;

        const messages: { [key: string]: { [key: string]: string } } = {
            pl,
            en,
        };

        return (
            <IntlProvider locale={this.state.locale} messages={messages[this.state.locale]}>
                {this.props.children}
            </IntlProvider>
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    locale: state.locale.locale,
});

export default connect(mapStateToProps, { changeLocaleAction })(withRouter(LocaleProvider));
