import React from "react";
import LayoutError from "./LayoutError";

interface PropsInterface {
    children: React.ReactNode | React.ReactNodeArray;
}

interface StateInterface {
    error?: Error;
}

export class ErrorBoundary extends React.Component<PropsInterface> {
    readonly state: StateInterface = {
        error: undefined,
    };

    constructor(props: PropsInterface) {
        super(props);
        this.onErrorEvent = this.onErrorEvent.bind(this);
        this.onUnhandledrejectionEvent = this.onUnhandledrejectionEvent.bind(this);
    }

    onUnhandledrejectionEvent(event: PromiseRejectionEvent) {
        if (event.reason && event.reason instanceof Error) {
            this.onError(event.reason);
        }
    }

    onErrorEvent(event: ErrorEvent) {
        if (event.error instanceof Error) {
            this.onError(event.error);
        }
    }

    onError(error: Error) {
        this.setState({ error });
    }

    componentDidMount() {
        window.addEventListener("error", () => this.onErrorEvent);
        window.addEventListener("unhandledrejection", this.onUnhandledrejectionEvent);
    }

    componentWillUnmount() {
        window.removeEventListener("error", this.onErrorEvent);
        window.removeEventListener("unhandledrejection", this.onUnhandledrejectionEvent);
    }

    render() {
        if (this.state.error) {
            return <LayoutError />;
        }
        return this.props.children;
    }
}
