// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from "react";
import { Provider } from "react-redux";
import { Store } from "redux";
import { History } from "history";
import { ConnectedRouter } from "connected-react-router";
import Routes from "./routes";
import "./App.css";
import { ApplicationState } from "./store";
import { ErrorBoundary } from "./pages/layout/ErrorBoundary";
import LocaleProvider from "./services/Locale/services/LocaleProvider/LocaleProvider";
import { GlobalCss } from "./shared/theme/GlobalCss";
import { Palette } from "./shared/theme/Palette";
import { setDefaultLanguage, setTranslations } from "react-multi-lang";
import pl from "./shared/lang/pl.json";
import en from "./shared/lang/en.json";
import ReactGA from "react-ga4";

interface MainProps {
    store: Store<ApplicationState>;
    history: History;
}

setTranslations({ pl, en });
setDefaultLanguage("pl");

const App: React.FC<MainProps> = (props: MainProps) => {

    return (
        <Provider store={props.store}>
            <Palette />
            <GlobalCss />
            <ConnectedRouter history={props.history}>
                <LocaleProvider>
                    <ErrorBoundary>
                        <Routes />
                    </ErrorBoundary>
                </LocaleProvider>
            </ConnectedRouter>
        </Provider>
    );
};

export default App;
