import { MediaInterface } from "./MediaInterface";

export enum NewsCategoryEnum {
    News = "Aktualnosci-ogloszenia",
    NewsExtramural = "Aktualnosci-niestacjonarne",
    NewsDoctoralStudies = "Aktualnosci-doktoranckie",
    TimetableSummer = "studenci_rozklady_zajec_stacjonarne_lato",
    TimetableWinter = "studenci_rozklady_zajec_stacjonarne_zima",
    TimetableExtramural = "studenci_rozklady_zajec_niestacjonarne",
}

export interface NewsInterface {
    id: number;
    title?: string;
    publicate: string;
    category: NewsCategoryEnum;
    date_article?: string;
    short_description?: string;
    description?: string;
    author?: string;
    aktualnosci_obrazki: MediaInterface[];
    pliki: MediaInterface[];
}
