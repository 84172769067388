import React, { FC, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DetailHeader from "../components/DetailHeader/DetailHeader";
import DetailContent from "../components/DetailContent/DetailContent";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import DetailHeaderText from "../components/DetailHeader/DetailHeaderText";
import container from "../container";
import LocaleEnum from "../services/Locale/enums/Locale/LocaleEnum";
import { ApplicationState } from "../store";
import { PageStaticItemInterface } from "../services/Api/DataInterface/PageStaticResponseInterface";
import Gallery from "../components/Gallery/GalleryAdaptive";
import EmployeeList from "../components/PublicCouncilOfFaculty/EmployeeList";
import DetailContentChildren from "../components/DetailContent/DetailContentChildren";

interface PropsInterface {
    pageId: string;
    locale: LocaleEnum;
}

const PublicCouncilOfFaculty: FC<PropsInterface> = ({ locale }) => {
    const [data, setData] = useState<PageStaticItemInterface | null>(null);

    useEffect(() => {
        (async () => {
            const data = await container.dynamicModuleApiService.getStatisticPage(locale, { site: "rada_spoleczna_wimir" });
            if (data.items && data.items.length > 0) {
                setData(data.items[0]);
            }
        })();
    }, [locale]);

    if (!data) {
        return null;
    }

    return (
        <>
            <DetailHeader />
            <Breadcrumbs />
            <DetailHeaderText />
            <DetailContent content={data.description} />
            {data.gallery && data.gallery.length > 0 && <Gallery images={data.gallery} />}
            <DetailContentChildren>
                <EmployeeList />
            </DetailContentChildren>
        </>
    );
};

PublicCouncilOfFaculty.propTypes = {
    pageId: PropTypes.string.isRequired,
    locale: PropTypes.oneOf([LocaleEnum.PL, LocaleEnum.EN]).isRequired,
};

const mapStateToProps = (state: ApplicationState) => ({
    locale: state.locale.locale,
});

export default connect(mapStateToProps)(PublicCouncilOfFaculty);
