import React, { FC, useEffect, useState } from "react";
import DetailHeader from "../DetailHeader/DetailHeader";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import Container from "../Container";
import container from "../../container";
import styled from "styled-components";
import BreakpointEnum from "../../shared/enums/BreakpointEnum/BreakpointEnum";
import DetailContentChildren from "../DetailContent/DetailContentChildren";

const HeaderText = styled.h1`
    width: 90%;
    margin: auto;
    padding-top: 31px;
    font-size: calc(32px + var(--font-modificator, 0px));

    @media ${BreakpointEnum.S} {
        font-size: calc(39px + var(--font-modificator, 0px));
        width: 100%;
    }
`;

const ContainerMiddle = styled.div`
    width: 80%;
    margin: 0 auto;
    text-align: center;
    line-height: 34px;
`;

const Button = styled.input`
    padding: 18px 45px;
    background: var(--main-red, red);
    font-size: calc(18px + var(--font-modificator, 0px));
    color: #fff;
    display: flex;
    margin: 40px auto;
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    text-align: center;
`;

interface SessionIdInterface {
    session: string;
}

const LoginStaff: FC = () => {
    const [data, setData] = useState<SessionIdInterface | null>(null);

    useEffect(() => {
        (async () => {
            const data = await container.staffPanelService.getSessionId();
            setData(data.data);
        })();
    }, []);

    return (
        <>
            <DetailHeader />
            <Breadcrumbs />
            <Container>
                <HeaderText>Dostęp do dokumentów</HeaderText>
            </Container>
            <DetailContentChildren>
                <ContainerMiddle>
                    Dostęp do dokumentów zawartych na stronie wymaga autoryzacji <br />
                    Uprawnieni do dostępu są pracownicy WIMiR AGH
                    <br />
                    Autoryzacja realizowana jest przez Panel Usług Sieciowych AGH
                    <br />
                    logowanie odbywa się poprzez podanie adresu e-mail i hasła z serwera poczta.agh.edu.pl.
                    <br />
                    <form className="main_form" action="https://panel.agh.edu.pl/remote/auth.php" method="POST">
                        <input type="hidden" name="service" value="imir-www" />
                        <input type="hidden" name="session" value={`${data?.session || ""}`} />
                        <Button type="submit" value="Zaloguj" />
                    </form>{" "}
                </ContainerMiddle>
            </DetailContentChildren>
        </>
    );
};

export default LoginStaff;
