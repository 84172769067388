import React, { FC } from "react";
import styled from "styled-components";
import FontSizeController from "./components/FontSizeController";
import ThemeColorsController from "./components/ThemeColorsController";
import { FontThemeEnum, ThemeEnum } from "../../../../../../shared/enums/ThemeEnum/ThemeEnum";
import TopBarHamburger from "../../../Hamburger/TopBarHamburger";
import PropTypes from "prop-types";

const ControllersRow = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
`;

interface ThemeControllersProps {
    setIsFullscreenMenuOpen: (isOpen: boolean) => void;
    setActiveElementIndex: (index: number | boolean) => void;
    currentGreatestParentIndex: number | boolean;
    setOpenedFromTopBar: (opened: boolean) => void;
}

const ThemeControllers: FC<ThemeControllersProps> = ({
    setIsFullscreenMenuOpen,
    setActiveElementIndex,
    currentGreatestParentIndex,
    setOpenedFromTopBar,
}) => {
    const handleHamburgerClick = () => {
        setActiveElementIndex(currentGreatestParentIndex);
        setIsFullscreenMenuOpen(true);
        setOpenedFromTopBar(true);
    };

    return (
        <ControllersRow>
            <TopBarHamburger onClick={handleHamburgerClick} />
            <FontSizeController label={"A"} supLabel={""} fontSizeModificator={""} />
            <FontSizeController label={"A"} supLabel={"+"} fontSizeModificator={FontThemeEnum.Medium} />
            <FontSizeController label={"A"} supLabel={"++"} fontSizeModificator={FontThemeEnum.Big} />

            <ThemeColorsController borderColor={"white"} textColor={"white"} backgroundColor={"#00305c"} themeName={ThemeEnum.Default} />
            <ThemeColorsController borderColor={"yellow"} textColor={"yellow"} backgroundColor={"black"} themeName={ThemeEnum.Contrast} />
            <ThemeColorsController borderColor={"black"} textColor={"black"} backgroundColor={"yellow"} themeName={ThemeEnum.Contrast2} />
        </ControllersRow>
    );
};

ThemeControllers.propTypes = {
    setIsFullscreenMenuOpen: PropTypes.func.isRequired,
    setActiveElementIndex: PropTypes.func.isRequired,
    currentGreatestParentIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
    setOpenedFromTopBar: PropTypes.func.isRequired,
};

export default ThemeControllers;
