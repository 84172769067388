import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

const LayoutErrorBody = styled.div`
    width: 100vw;
    height: 100vh;
    background: var(--main-blue, #000);
    color: var(--font-light, #000);
    font-size: 16px;
    font-family: "Barlow-Light", sans-serif;
`;

const LayoutErrorHeader = styled.div`
    height: 30px;
`;

const LayoutErrorMenu = styled.div`
    min-height: 100px;
    background: white;
    text-align: center;
`;

const LayoutErrorMenuLogo = styled.a`
    margin-left: 20px;
    margin-right: 20px;
`;

const LayoutErrorDescription = styled.div`
    margin-top: 20px;
    text-align: center;
`;

interface PropsInterface {}

const LayoutError: React.FC<PropsInterface> = () => {
    return (
        <LayoutErrorBody>
            <LayoutErrorHeader />
            <LayoutErrorMenu>
                <LayoutErrorMenuLogo href="//www.imir.agh.edu.pl/" target="_blank" rel={"noreferrer"}>
                    <img src={"/images/logo_agh.png"} alt={"agh-logo"} />
                </LayoutErrorMenuLogo>
                <LayoutErrorMenuLogo href="/" target="_blank" rel={"noreferrer"}>
                    <img src={"/images/logo_wimir.png"} alt={"wimir-logo"} />
                </LayoutErrorMenuLogo>
            </LayoutErrorMenu>
            <LayoutErrorDescription>Strona tymczasowo niedostępna spróbuj ponownie później.</LayoutErrorDescription>
        </LayoutErrorBody>
    );
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(LayoutError);
