import React, { FC } from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import Container from "../Container";
import BreakpointEnum from "../../shared/enums/BreakpointEnum/BreakpointEnum";
import LocaleEnum from "../../services/Locale/enums/Locale/LocaleEnum";
import {ApplicationState} from "../../store";

const CathedralsRow = styled.div`
    width: 100%;
    background: var(--image-background-color, #005f9f)
    color: var(--font-dark, #000);
    min-height: 500px;

    @media ${BreakpointEnum.S} {
        background-color: var(--image-background-color, none)!important;
        background-blend-mode: var(--image-background-blend-mode, none);
        background: url("/images/cathedrals-bg.jpg") no-repeat center;
    }
`;

const CathedralList = styled.div`
    padding: 60px 0;
    justify-content: space-around;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: auto;

    @media ${BreakpointEnum.MD} {
        justify-content: center;
        padding: 80px 0;
    }

    @media ${BreakpointEnum.XXL} {
        padding: 130px 0;
    }

    &::-webkit-scrollbar {
        display: none;
        scrollbar-width: none;
    }
`;

const ListItem = styled.div`
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    background: var(--background-color, #FFF);
    margin: 15px 10px;
    max-width: 330px;

    @media ${BreakpointEnum.MD} {
        margin: 15px 10px;
        max-width: 265px;
    }

    @media ${BreakpointEnum.XXL} {
        margin: 20px 15px;
        max-width: 370px;
    }
`;

const CathedralLogo = styled.img`
    max-width: 330px;
    margin: auto;

    @media ${BreakpointEnum.MD} {
        max-width: 250px;
    }

    @media ${BreakpointEnum.XXL} {
        max-width: 370px;
    }
`;

interface PropsInterface {
    locale: LocaleEnum;
}

const Cathedrals: FC<PropsInterface> = ({ locale }) => {
    const cathedralsPicturePath = `/images/cathedrals/${locale}`
    const cathedralsItemsMultiLang = {
        pl : [
            {
                id: 1,
                img: `${cathedralsPicturePath}/logo_KIMiT.png`,
                url: `https://kimit.agh.edu.pl/`,
                title: `kimit.agh.edu.pl`,
            },
            {
                id: 2,
                img: `${cathedralsPicturePath}/logo_KSW.png`,
                url: `https://ksw.agh.edu.pl/`,
                title: `ksw.agh.edu.pl`,
            },
            {
                id: 3,
                img: `${cathedralsPicturePath}/logo_KAP.png`,
                url: `https://kap.agh.edu.pl/`,
                title: `kap.agh.edu.pl`,
            },
            {
                id: 4,
                img: `${cathedralsPicturePath}/logo_KSEiUOS.png`,
                url: `https://www.kseiuos.agh.edu.pl/`,
                title: `kseiuos.agh.edu.pl`,
            },
            {
                id: 5,
                img: `${cathedralsPicturePath}/logo_KMIW.png`,
                url: `https://kmiw.imir.agh.edu.pl/`,
                title: `kmiw.imir.agh.edu.pl`,
            },
            {
                id: 6,
                img: `${cathedralsPicturePath}/logo_KRiM.png`,
                url: `https://krim.agh.edu.pl/`,
                title: `krim.agh.edu.pl`,
            },
            {
                id: 7,
                img: `${cathedralsPicturePath}/logo_KPiEM.png`,
                url: `https://www.kkiem.agh.edu.pl/`,
                title: `kkiem.agh.edu.pl`,
            },
        ],
        en : [
            {
                id: 1,
                img: `${cathedralsPicturePath}/logo_KIMiT.png`,
                url: `https://kimit.agh.edu.pl/en/`,
                title: `kimit.agh.edu.pl`,
            },
            {
                id: 2,
                img: `${cathedralsPicturePath}/logo_KSW.png`,
                url: `https://ksw.agh.edu.pl/`,
                title: `ksw.agh.edu.pl`,
            },
            {
                id: 3,
                img: `${cathedralsPicturePath}/logo_KAP.png`,
                url: `https://kap.agh.edu.pl/en/`,
                title: `kap.agh.edu.pl`,
            },
            {
                id: 4,
                img: `${cathedralsPicturePath}/logo_KSEiUOS.png`,
                url: `https://www.kseiuos.agh.edu.pl/index.php?n0=glowna&l=en`,
                title: `kseiuos.agh.edu.pl`,
            },
            {
                id: 5,
                img: `${cathedralsPicturePath}/logo_KMIW.png`,
                url: `https://kmiw.imir.agh.edu.pl/`,
                title: `kmiw.imir.agh.edu.pl`,
            },
            {
                id: 6,
                img: `${cathedralsPicturePath}/logo_KRiM_en.png`,
                url: `https://krim.agh.edu.pl/en/`,
                title: `krim.agh.edu.pl`,
            },
            {
                id: 7,
                img: `${cathedralsPicturePath}/logo_KPiEM.png`,
                url: `https://www.kkiem.agh.edu.pl/`,
                title: `kkiem.agh.edu.pl`,
            },
        ],
    }

    const cathedralsItems = cathedralsItemsMultiLang[locale];

    return (
        <CathedralsRow>
            <Container withPadding={false}>
                <CathedralList>
                    {cathedralsItems.map((cathedral, index) => (
                        <a href={cathedral.url} title={`Przejdź do ${cathedral.url}`} key={`cath${cathedral.id}${index}`} target={'_blank'} rel={'noreferrer'}>
                            <ListItem>
                                <CathedralLogo src={cathedral.img} alt={cathedral.title} />
                            </ListItem>
                        </a>
                    ))}
                </CathedralList>
            </Container>
        </CathedralsRow>
    );
};

Cathedrals.propTypes = {
    locale: PropTypes.oneOf([LocaleEnum.PL, LocaleEnum.EN]).isRequired,
};

const mapStateToProps = (state: ApplicationState) => ({
    locale: state.locale.locale,
});

export default connect(mapStateToProps)(Cathedrals);
