import FrontApiNestedErrorInterface from "./FrontApiNestedErrorInterface";
import _isArray from "lodash/isArray";
import _find from "lodash/find";
import _get from "lodash/get";
import FrontApiErrorCodeEnum from "./FrontApiErrorCodeEnum";

export default class FrontApiErrorsCollection {
    errors: FrontApiNestedErrorInterface[] = [];

    constructor(errors: FrontApiNestedErrorInterface[]) {
        this.errors = _isArray(errors) ? errors : [];
    }

    findByCode(code: FrontApiErrorCodeEnum): FrontApiNestedErrorInterface | null {
        return _find(this.errors, (e) => _get(e, "error.code", null) === code) || null;
    }

    toJson() {
        return this.errors;
    }
}
