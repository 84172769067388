import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import Container from "../../../Container";
import ThemeControllers from "./components/ThemeControllers/ThemeControllers";
import { TopBarPropsInterface } from "../../Interface/TopBarInterface";
import BreakpointEnum from "../../../../shared/enums/BreakpointEnum/BreakpointEnum";
import container from "../../../../container";
import LocaleEnum from "../../../../services/Locale/enums/Locale/LocaleEnum";
import { TopMenuItemInterface } from "../../../../services/Api/DataInterface/TopMenuResponseInterface";
import { ApplicationState } from "../../../../store";
import LangIco from "./../LangIco/LangIco";
import FullscreenMenu from "../FullscreenMenu/FullscreenMenu";
import { GetListMenuResponseInterface } from "../../../../services/Api/DataInterface/GetListMenuResponseInterface";

const TopBarWrapper = styled.div`
    width: 100%;
    display: flex;
    background: var(--main-blue, #00305c);
    justify-content: space-between;
    color: var(--font-light, #fff);
    flex-wrap: nowrap;
    align-items: center;
    position: fixed;
    bottom: 0;
    transform: translateY(100%);
    transition: 0.4s transform;
    ${(props: { isVisible: boolean; isMenuOpen: boolean }) =>
        props.isVisible &&
        `
        transform: translateY(0);
    `}

    ${(props: { isVisible: boolean; isMenuOpen: boolean }) =>
        !props.isMenuOpen &&
        `
        display: none;
    `}

    z-index: 9;
    border-top: 1px solid;

    @media ${BreakpointEnum.MD} {
        height: 35px;
        position: relative;
        transform: translateY(0);
        border: none;
    }
`;

const List = styled.ul`
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    padding: 15px 0;
    align-items: center;

    @media ${BreakpointEnum.MD} {
        flex-wrap: nowrap;
        padding: 0;
    }
`;

const ListElement = styled.li`
    display: inline-flex;
    padding: 15px;
    text-transform: uppercase;
    position: relative;
    width: 50%;
    justify-content: center;

    @media ${BreakpointEnum.MD} {
        width: auto;
        padding: 0 30px;
    }

    &:not(:last-of-type):after {
        @media ${BreakpointEnum.MD} {
            content: "";
            width: 2px;
            height: 10px;
            background: #284c6e;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            position: absolute;
        }
    }

    &:last-of-type {
        position: fixed;
        top: 18px;
        right: 15px;
        padding: 0;
        width: auto;

        @media ${BreakpointEnum.MD} {
            position: initial;
            padding: 0 0 0 30px;
        }
    }
`;

const Link = styled.a`
    text-decoration: none;
    outline: none;
    color: var(--font-light, #fff);
    transition: 0.2s;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
        color: var(--font-light-hover, #c3c6c9);
    }
`;

const TopBarContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 10px;

    @media ${BreakpointEnum.MD} {
        flex-wrap: nowrap;
        justify-content: space-between;
        padding: 0;
    }
`;

const TopBarArrow = styled.div`
    position: absolute;
    width: 56px;
    height: 30px;
    background: url("/images/ico_wimir_more2.png") 66px 0;
    margin: auto;
    left: 0;
    right: 0;
    top: -35px;
    cursor: pointer;

    @media ${BreakpointEnum.MD} {
        display: none;
    }

    ${(props: { visible: boolean }) =>
        props.visible &&
        `
       background: url("/images/ico_wimir_more2.png") 0 0;
    `}
`;

const TopBar: FC<TopBarPropsInterface> = ({ isMenuOpen, locale, mainMenuItems }) => {
    const location = useLocation();
    const [isVisible, setIsVisible] = useState(false);
    const [topMenu, setTopMenu] = useState<TopMenuItemInterface[]>([]);
    const [isFullscreenMenuOpen, setIsFullscreenMenuOpen] = useState(false);
    const [activeElementIndex, setActiveElementIndex] = useState<number | boolean>(false);
    const [openedFromTopBar, setOpenedFromTopBar] = useState(false);

    useEffect(() => {
        (async () => {
            const data = await container.dynamicModuleApiService.getTopMenu(locale);
            setTopMenu(data.items);
        })();
    }, [locale]);

    const getGreatestParentIndex = (pathname: string): number | boolean => {
        const findGreatestParent = (items: GetListMenuResponseInterface[] | null, path: string): number | boolean => {
            if(!items) {
                return false;
            }
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                if (item.routes && item.routes[locale] === path) {
                    return item.id;
                }
                if (item.children) {
                    const childResult = findGreatestParent(item.children, path);
                    if (childResult !== false) {
                        return item.id;
                    }
                }
            }
            return false;
        };
        return findGreatestParent(mainMenuItems, pathname);
    };

    const currentGreatestParentIndex = getGreatestParentIndex(location.pathname);

    useEffect(() => {
        setActiveElementIndex(currentGreatestParentIndex);
    }, [location.pathname, currentGreatestParentIndex]);

    return (
        <>
            <TopBarWrapper isMenuOpen={isMenuOpen} isVisible={isVisible}>
                <TopBarArrow visible={isVisible} onClick={() => setIsVisible(!isVisible)} />
                <Container>
                    <TopBarContainer>
                        <ThemeControllers
                            setIsFullscreenMenuOpen={setIsFullscreenMenuOpen}
                            setActiveElementIndex={setActiveElementIndex}
                            currentGreatestParentIndex={currentGreatestParentIndex}
                            setOpenedFromTopBar={setOpenedFromTopBar}
                        />

                        <List>
                            {topMenu.map((item) => (
                                <ListElement key={`tbli${item.id}`}>
                                    <Link href={item?.url || "#"} title={item?.name || ""} target={"_blank"}>
                                        {item?.name || ""}
                                    </Link>
                                </ListElement>
                            ))}
                            <ListElement>
                                <LangIco isTopBar />
                            </ListElement>
                        </List>
                    </TopBarContainer>
                </Container>
            </TopBarWrapper>
            <FullscreenMenu
                isOpen={isFullscreenMenuOpen}
                mainMenuItems={mainMenuItems}
                activeElementIndex={activeElementIndex}
                closeFullScreenMenu={() => {
                    setIsFullscreenMenuOpen(false);
                    setActiveElementIndex(false);
                    setOpenedFromTopBar(false);
                }}
                openingSource={openedFromTopBar}
            />
        </>
    );
};

TopBar.propTypes = {
    isMenuOpen: PropTypes.bool.isRequired,
    locale: PropTypes.oneOf([LocaleEnum.PL, LocaleEnum.EN]).isRequired,
    mainMenuItems: PropTypes.array
};

const mapStateToProps = (state: ApplicationState) => ({
    locale: state.locale.locale,
    activeElement: state.menu.activeElement,
    isOpenMenu: state.menu.isOpenMenu,
});

export default connect(mapStateToProps)(TopBar);
