import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { StyledSubmenuItem, StyledSubmenuItemTitle, StyledSubmenuList, StyledSubmenuListItem } from "../StyledFullscreenComponents";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { useWindowSize } from "../../../../../../shared/hooks/useWindowSize";
import { BreakpointValuesEnum } from "../../../../../../shared/enums/BreakpointEnum/BreakpointEnum";
import LocaleEnum from "../../../../../../services/Locale/enums/Locale/LocaleEnum";
import { connect } from "react-redux";
import { ApplicationState } from "../../../../../../store";
import { GetListMenuResponseInterface } from "../../../../../../services/Api/DataInterface/GetListMenuResponseInterface";

interface SubmenuItemProps extends GetListMenuResponseInterface, WrappedComponentProps {
    locale: LocaleEnum;
}

const SubmenuItem: FC<SubmenuItemProps> = (props) => {
    const [isActive, setIsActive] = useState(false);
    const { children, id, routes, closeFullScreenMenu } = props;
    const hasChildren: boolean = Array.isArray(children) && children.length > 0;
    const { width } = useWindowSize();

    const wrappedWithLink = (text: string, href: string, targetBlank: boolean) =>
        !targetBlank ? (
            <Link
                onClick={() => {
                    closeFullScreenMenu && closeFullScreenMenu();
                }}
                to={href}
                title={href}
            >
                {text}
            </Link>
        ) : (
            <a href={href} target={"_blank"} rel={"noreferrer"}>
                {text}
            </a>
        );

    return (
        <StyledSubmenuItem
            hasChildren={hasChildren}
            onClick={() => {
                if (width && width < BreakpointValuesEnum.MD) {
                    setIsActive(!isActive);
                }
            }}
            onMouseEnter={() => {
                if (width && width >= BreakpointValuesEnum.MD) {
                    setIsActive(true);
                }
            }}
            onMouseLeave={() => {
                if (width && width >= BreakpointValuesEnum.MD) {
                    setIsActive(false);
                }
            }}
            onFocus={() => {
                if (width && width >= BreakpointValuesEnum.MD) {
                    setIsActive(true);
                }
            }}
            onBlur={() => {
                if (width && width >= BreakpointValuesEnum.MD) {
                    setIsActive(false);
                }
            }}
        >
            <StyledSubmenuItemTitle isActive={isActive && hasChildren}>
                { routes && routes[props.locale] 
                ? wrappedWithLink(props?.text || "", routes[props.locale], props?.targetBlank || false) : props?.text || "" }
            </StyledSubmenuItemTitle>
            <StyledSubmenuList isActive={(isActive && hasChildren) || id === 0} justifyTop={!!children && children.length >= 10}>
                {children?.map((child, index) => {
                    if (props.locale === LocaleEnum.EN && !child.isMultiLang) {
                        return false;
                    }

                    return (
                        <StyledSubmenuListItem key={`slsik_${id}_${index}`}>
                            { child.routes && child.routes[props.locale] 
                            ? wrappedWithLink(child?.text || "", child.routes[props.locale], child?.targetBlank || false) : child?.text || "" }
                        </StyledSubmenuListItem>
                    );
                })}
            </StyledSubmenuList>
        </StyledSubmenuItem>
    );
};

SubmenuItem.propTypes = {
    id: PropTypes.number.isRequired,
    text: PropTypes.string.isRequired,
    children: PropTypes.array,
    routes: PropTypes.any.isRequired,
    intl: PropTypes.any.isRequired,
    targetBlank: PropTypes.bool,
    closeFullScreenMenu: PropTypes.func,
    locale: PropTypes.oneOf([LocaleEnum.PL, LocaleEnum.EN]).isRequired,
};

const mapStateToProps = (state: ApplicationState) => ({
    locale: state.locale.locale,
});

SubmenuItem.defaultProps = {
    targetBlank: false,
};
export default connect(mapStateToProps)(injectIntl(SubmenuItem));
