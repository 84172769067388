import React, { FC, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Container from "../../../Container";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import BreakpointEnum from "../../../../shared/enums/BreakpointEnum/BreakpointEnum";
import CarouselArrow from "../../../CarouselArrow/CarouselArrow";
import Button from "../../../Button/Button";
import { TrailersItemInterface } from "../../../../services/Api/DataInterface/TrailersResponseInterface";

interface CarouselPropsInterface {
    color?: string;
    data: TrailersItemInterface[];
}

interface BlendLayerInterface {
    color?: string;
}

const CarouselRow = styled.section`
    background: var(--background-color, #FFF);
    position: relative;

    &:not(:first-of-type) {
        margin-top: 20px;
    }
`;

const CarouselWrapper = styled.div`
    margin: auto;
    position: relative;
    overflow: hidden;
    width: 100%;
`;

const Blend = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${(props: BlendLayerInterface) => props.color || "transparent"};
    opacity: 0.5;
    top: 0;
    left: 0;
    z-index: 1;
`;

const Slide = styled.div`
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: var(--image-background-color, none)!important;
    background-blend-mode: var(--image-background-blend-mode, none);
`;

const SlideTextContent = styled.div`
    padding: 35px 0;
    width: calc(100% - 60px);
    margin: auto;
    color: #fff;
    z-index: 2;
    position: relative;
    text-align: left;

    @media ${BreakpointEnum.S} {
        width: 83%;
    }

    @media ${BreakpointEnum.XL} {
        padding: 40px 0;
    }

    @media ${BreakpointEnum.XXL} {
        padding: 50px 0;
    }
`;

const SlideTitle = styled.div`
    font-family: "Barlow-Bold", sans-serif;
    font-size: calc(27px + var(--font-modificator, 0px));
    line-height: 1.15;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;

    @media ${BreakpointEnum.S} {
        -webkit-line-clamp: 2;
        font-size: calc(32px + var(--font-modificator, 0px));
    }

    @media ${BreakpointEnum.MD} {
        font-size: calc(36px + var(--font-modificator, 0px));
    }

    @media ${BreakpointEnum.XL} {
        font-size: calc(42px + var(--font-modificator, 0px));
    }

    @media ${BreakpointEnum.XXL} {
        font-size: calc(46px + var(--font-modificator, 0px));
    }
`;

const SlideLead = styled.div`
    font-family: "Barlow-Light", sans-serif;
    line-height: 1.25;
    font-size: calc(18px + var(--font-modificator, 0px));
    margin-top: 20px;
    overflow: hidden;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;

    display: -webkit-box;

    @media ${BreakpointEnum.S} {
        margin-top: 35px;
        font-size: calc(20px + var(--font-modificator, 0px));
        -webkit-line-clamp: 5;
    }

    @media ${BreakpointEnum.XL} {
        font-size: calc(22px + var(--font-modificator, 0px));
        -webkit-line-clamp: 3;
    }

    @media ${BreakpointEnum.XXL} {
        font-size: calc(24px + var(--font-modificator, 0px));
    }
`;

const CarouselContainer: FC<CarouselPropsInterface> = ({ color, data }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    if (data.length === 0) {
        return <></>;
    }

    return (
        <CarouselRow>
            <Container>
                <CarouselWrapper>
                    {data.length > 1 && (
                        <CarouselArrow onClick={() => setCurrentSlide(currentSlide - 1)} direction={"left"} overwritePosition={-10} />
                    )}
                    <Carousel
                        showThumbs={false}
                        showIndicators={false}
                        showStatus={false}
                        showArrows={false}
                        infiniteLoop
                        preventMovementUntilSwipeScrollTolerance
                        swipeScrollTolerance={100}
                        selectedItem={currentSlide}
                        onChange={setCurrentSlide}
                    >
                        {data.map((item) => (
                            <Slide key={`kslide${item.id}`} style={{ backgroundImage: `url(${item.media?.url})` }}>
                                <Blend color={color} />
                                <SlideTextContent>
                                    <SlideTitle>{item.title}</SlideTitle>
                                    <SlideLead dangerouslySetInnerHTML={{ __html: item.description }} />
                                    {!!item.link && <Button href={item.link} target={item.new_card ? "_blank" : "_self"} />}
                                </SlideTextContent>
                            </Slide>
                        ))}
                    </Carousel>
                    {data.length > 1 && (
                        <CarouselArrow onClick={() => setCurrentSlide(currentSlide + 1)} direction={"right"} overwritePosition={-10} />
                    )}
                </CarouselWrapper>
            </Container>
        </CarouselRow>
    );
};

CarouselContainer.propTypes = {
    color: PropTypes.string,
    data: PropTypes.any,
};

CarouselContainer.defaultProps = {
    color: "transparent",
};
export default CarouselContainer;
