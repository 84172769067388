import React, {FC} from "react";
import {connect} from "react-redux";
import {injectIntl} from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";
import LocaleEnum from "../../../../services/Locale/enums/Locale/LocaleEnum";
import {ApplicationState} from "../../../../store";
import {BreakpointValuesEnum} from "../../../../shared/enums/BreakpointEnum/BreakpointEnum";


interface LangIcoProps {
    locale: string,
    isTopBar: boolean,
    intl: any;
}

type styledPropsModel = {
    currentLang: string,
    isTopBar: boolean
}

const LangIcoLink = styled.a`
    text-transform: uppercase;
    color: color: var(--font-light);;
    outline: none;
    margin: auto;
    cursor: pointer;
    z-index: 99;
    padding: ${(props: {styledProps: styledPropsModel}) => props.styledProps.isTopBar ? 'initial' : '10px'};
    position: ${(props: {styledProps: styledPropsModel}) => props.styledProps.isTopBar ? 'initial' : 'absolute'};
       &:hover {
        color: var(--font-light-hover, #c3c6c9);
    }
    ${(props: {styledProps: styledPropsModel}) => props.styledProps.isTopBar 
    ? `@media (max-width: ${BreakpointValuesEnum.MD}px) {display:none;}` 
    : `@media (min-width: ${BreakpointValuesEnum.MD}px) {display:none;}`}
`;

const LangIco: FC<LangIcoProps> = ({locale, isTopBar, intl}) => {
    const setLang = (): void => {
        if (locale === LocaleEnum.PL) {
            window.location.href = "/en/";
            return;
        }
        window.location.href = "/";
        return;
    };

    const styledProps = {
        currentLang: locale,
        isTopBar
    }

    return (
        <LangIcoLink href={"#"} title={locale} onClick={setLang} styledProps={styledProps}>
            {intl.formatMessage({ id: `langSwitch.${locale}` })}
        </LangIcoLink>
    )
}


LangIco.propTypes = {
    locale: PropTypes.oneOf([LocaleEnum.PL, LocaleEnum.EN]).isRequired,
    intl: PropTypes.any.isRequired,
};

const mapStateToProps = (state: ApplicationState) => ({
    locale: state.locale.locale,
});

export default connect(mapStateToProps )(injectIntl(LangIco));