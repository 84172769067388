import React, { FC } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import BreakpointEnum from "../../../../../../../shared/enums/BreakpointEnum/BreakpointEnum";
import { connect } from "react-redux";
import { changeContrastActions } from "../../../../../../../services/Locale/store/Contrast/ContrastActions";
import { ThemeEnum } from "../../../../../../../shared/enums/ThemeEnum/ThemeEnum";
import { ApplicationState } from "../../../../../../../store";

interface ThemeControllerProps {
    borderColor: string;
    textColor: string;
    backgroundColor: string;
    themeName: ThemeEnum;
    changeContrastActions: typeof changeContrastActions;
    contrast?: ThemeEnum;
}

const ThemeColorController = styled.div`
    display: flex;
    flex-wrap: nowrap;
    cursor: pointer;
    border: 1px solid ${(props: ThemeControllerProps) => props.borderColor};
    color: ${(props: ThemeControllerProps) => props.textColor};
    background-color: ${(props: ThemeControllerProps) => props.backgroundColor};
    width: 30px;
    height: 30px;
    text-align: center;
    justify-content: center;
    margin-right: 5px;
    font-family: "Barlow-Bold", sans-serif;
    font-size: calc(14px + var(--font-modificator, 0px));
    align-items: center;

    @media ${BreakpointEnum.MD} {
        width: 20px;
        height: 20px;
        margin-right: 2px;
    }
`;

const changeFontHandler = (themeName: string | undefined) => {
    const htmlElement = document.getElementsByTagName("html")[0].classList;
    htmlElement.forEach((item) => {
        if (item.startsWith("theme--")) {
            htmlElement.remove(item);
        }
    });

    if (!htmlElement) return;

    htmlElement.add(`theme--${themeName}`);
};

const ThemeColorsController: FC<ThemeControllerProps> = ({
    textColor,
    backgroundColor,
    themeName,
    borderColor,
    changeContrastActions,
}) => {
    return (
        <ThemeColorController
            borderColor={borderColor}
            textColor={textColor}
            backgroundColor={backgroundColor}
            changeContrastActions={changeContrastActions}
            themeName={themeName}
            onClick={() => {
                changeFontHandler(themeName);
                changeContrastActions(themeName)
            }}
        >
            A
        </ThemeColorController>
    );
};

ThemeColorsController.propTypes = {
    changeContrastActions: PropTypes.func.isRequired,
    contrast: PropTypes.any.isRequired,
};

const mapStateToProps = (state: ApplicationState) => ({
    contrast: state.contrast.contrast,
});

export default connect(mapStateToProps, {changeContrastActions})(ThemeColorsController);