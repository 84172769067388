export enum BreakpointValuesEnum {
    XS = 440,
    S = 720,
    MD = 960,
    XL = 1140,
    XXL = 1600,
}

enum BreakpointEnum {
    XS = `(min-width: 440px)`,
    S = `(min-width: 720px)`,
    MD = `(min-width: 960px)`,
    XL = `(min-width: 1140px)`,
    XXL = `(min-width: 1600px)`,
}

export default BreakpointEnum;
