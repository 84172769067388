import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import CarouselContainer from "./components/CarouselContainer/CarouselContainer";
import BreakpointEnum from "../../shared/enums/BreakpointEnum/BreakpointEnum";
import container from "../../container";
import PropTypes from "prop-types";
import LocaleEnum from "../../services/Locale/enums/Locale/LocaleEnum";
import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import { changeLocaleAction } from "../../services/Locale/store/Actions";
import { TrailersItemInterface } from "../../services/Api/DataInterface/TrailersResponseInterface";

const AchievementsSection = styled.section`
    background: var(--background-color, #FFF);
    position: relative;
    padding: 80px 0;

    @media ${BreakpointEnum.S} {
        padding: 80px 0;
    }

    @media ${BreakpointEnum.XL} {
        padding: 90px 0 170px;
    }
`;

const SectionSeparator = styled.div`
    width: 100%;
    position: absolute;
    bottom: -50px;
    height: 50px;
    overflow: hidden;

    &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        top: 0;
        border-top: 0 solid transparent;
        border-bottom: 50px solid transparent;
        border-right: 100vw solid white;
    }
`;

export interface AchievementsPropsInterface {
    locale: LocaleEnum;
}

const Achievements: FC<AchievementsPropsInterface> = ({ locale }) => {
    const [carousel1, setCarousel1] = useState<TrailersItemInterface[]>([]);
    const [carousel2, setCarousel2] = useState<TrailersItemInterface[]>([]);

    useEffect(() => {
        (async () => {
            const data = await container.dynamicModuleApiService.getTrailersHomePage(locale);
            const dataLength = data.items.length;
            if (dataLength === 1) {
                setCarousel1(data.items);
            } else if (dataLength > 1) {
                const partFirstLength = Math.ceil(dataLength / 2);
                setCarousel1(data.items.slice(0, partFirstLength));
                setCarousel2(data.items.slice(partFirstLength, dataLength));
            }
        })();
    }, [locale]);

    if (carousel1.length === 0) {
        return <></>;
    }

    return (
        <AchievementsSection>
            <CarouselContainer data={carousel1} color={"#000"} key={`carousel1`} />

            <CarouselContainer data={carousel2} color={"#000"} key={`carousel2`} />

            <SectionSeparator />
        </AchievementsSection>
    );
};

Achievements.propTypes = {
    locale: PropTypes.oneOf([LocaleEnum.PL, LocaleEnum.EN]).isRequired,
};

const mapStateToProps = (state: ApplicationState) => ({
    locale: state.locale.locale,
});

export default connect(mapStateToProps, { changeLocaleAction })(Achievements);
