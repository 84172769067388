import { AxiosError } from "axios";
import FrontApiErrorDto from "../Error/FrontApiErrorDto";

const frontApiResponseErrorTransformer = () => {
    return async (e: AxiosError) => {
        if (!e.response) throw e;

        const error = FrontApiErrorDto.httpErrorToFrontApiError(e);

        throw error;
    };
};

export default frontApiResponseErrorTransformer;
