import React, { FC, ReactNode, useEffect, useState } from "react";
import styled from "styled-components";
import Container from "../Container";
import LightGallery from "lightgallery/react";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import { useWindowSize } from "../../shared/hooks/useWindowSize";
import BreakpointEnum, { BreakpointValuesEnum } from "../../shared/enums/BreakpointEnum/BreakpointEnum";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-thumbnail.css";
import { Carousel } from "react-responsive-carousel";
import CarouselArrow from "../CarouselArrow/CarouselArrow";
import PropTypes from "prop-types";
import { MediaInterface } from "../../services/Api/DataInterface/MediaInterface";

const StyledGallery = styled.div`
    width: 100%;
    background: var(--main-red, #c0001b);
    padding: 40px 15px;
    margin: 0;
    justify-content: center;

    @media ${BreakpointEnum.MD} {
        padding: 60px 15px;
    }
    @media ${BreakpointEnum.XXL} {
        padding: 145px 80px;
    }

    &.is-not-carousel {
        .lg-react-element {
            display: flex;
            gap: 2px;
            flex-wrap: wrap;
            justify-content: center;
        }
    }
`;

const GalleryListItem = styled.a`
    display: inline-flex;
    height: 290px;
    flex-grow: 1;
    width: 292px;
    &:last-child {
        flex-grow: 10;
    }
    overflow: hidden;

    &:hover img {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }

    @media ${BreakpointEnum.MD} {
        width: calc(100% * (1 / 4) - 10px - 1px);
    }
`;

const GalleryListItemImage = styled.img`
    max-height: 100%;
    min-width: 100%;
    object-fit: cover;
    vertical-align: bottom;
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;

    &:hover {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }
`;

const GallerySlideItem = styled.div`
    display: flex;
`;

interface SlideInterface {
    slideIndex: number;
    items: MediaInterface[];
}

const divideGalleryPerSlides = (items: MediaInterface[], imagesPerSlide = 8): SlideInterface[] => {
    const slides = [];
    const slidesNumber = items.length / imagesPerSlide;

    for (let i = 0; i < slidesNumber; i++) {
        const offset = i * imagesPerSlide;
        const slide = {
            slideIndex: i,
            items: items.slice(offset, offset + imagesPerSlide),
        };

        slides.push(slide);
    }

    return slides;
};

const checkShouldBeCarousel = (width: number | undefined, images: MediaInterface[]) =>
    !!(width && width > BreakpointValuesEnum.MD && images.length > 8);

interface GalleryPropsInterface {
    images?: MediaInterface[];
}

const Gallery: FC<GalleryPropsInterface> = ({ images = []}) => {
    const licenseKey = "932ED4E8-4EC54668-B0FA7123-0F84DD6F";
    const size = useWindowSize();
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isCarousel, setIsCarousel] = useState<boolean>(checkShouldBeCarousel(size.width, images));
    let gallery: ReactNode = null;

    useEffect(() => {
        const { width } = size;
        setIsCarousel(checkShouldBeCarousel(width, images));
    }, [size.width]);

    if (!images || !Array.isArray(images) || !images.length) {
        return null;
    }

    const getSubHtml = (item: MediaInterface): string => {
        const prefix = window.location.pathname.includes('pl/') ? 'fot. ' : '';
        if (!item.author) {
            return `
                <span style="width: 100%;">${item.description}</span>
            `;
        }
        if(item.author && item.description === "") {
            return `
            <span style="width: 100%;">${prefix} ${item.author.surname} ${item.author.name}</span>
        `;
        }
        return `
            <span class="desc" style="float: left; max-width: 65%;">${item.description}</span>
            <span class="author" style="float: right; max-width: 25%;">${prefix} ${item.author.surname} ${item.author.name}</span>
        `;
    };

    if (isCarousel) {
        const slides = divideGalleryPerSlides(images);
        gallery = (
            <>
                <LightGallery
                    licenseKey={licenseKey}
                    selector={".galleryItem"}
                    key={`galleryKey$`}
                    speed={500}
                    plugins={[lgThumbnail]}
                    download={false}
                    hash={true}
                >
                    <CarouselArrow
                        onClick={() => setCurrentSlide(currentSlide - 1)}
                        direction={"left"}
                        overwritePosition={-20}
                        hidden={currentSlide === 0}
                    />
                    <Carousel
                        preventMovementUntilSwipeScrollTolerance={true}
                        swipeScrollTolerance={35}
                        className={"slider--gallery"}
                        showThumbs={false}
                        showIndicators={false}
                        showStatus={false}
                        showArrows={false}
                        selectedItem={currentSlide}
                        onChange={setCurrentSlide}
                        transitionTime={300}
                    >
                        {slides.map((slide: SlideInterface, slideIndex) => {
                            return (
                                <GallerySlideItem key={`gis_${slideIndex}`}>
                                    {slide.items.map((item: MediaInterface, index: number) => (
                                        <GalleryListItem className={`galleryItem`} href={item.url} key={`gik_${index}`} data-sub-html={getSubHtml(item)}>
                                            <GalleryListItemImage alt={item.description ? item.description : ""} src={item.url} />
                                        </GalleryListItem>
                                    ))}
                                </GallerySlideItem>
                            );
                        })}
                    </Carousel>
                    <CarouselArrow
                        onClick={() => setCurrentSlide(currentSlide + 1)}
                        direction={"right"}
                        overwritePosition={-20}
                        hidden={currentSlide === slides.length - 1}
                    />
                </LightGallery>
            </>
        );
    } else {
        gallery = (
            <LightGallery speed={500} plugins={[lgThumbnail]} download={false} licenseKey={licenseKey}>
                {images.map((item, index) => (
                    <GalleryListItem href={item.url} key={`gik_${index}`} data-sub-html={getSubHtml(item)}>
                        <GalleryListItemImage alt={item.description ? item.description : ""} src={item.url} />
                    </GalleryListItem>
                ))}
            </LightGallery>
        );
    }

    return (
        <StyledGallery className={!isCarousel ? "is-not-carousel" : ""}>
            <Container withPadding={false}>{gallery}</Container>
        </StyledGallery>
    );
};

Gallery.propTypes = {
    images: PropTypes.array,
};

export default Gallery;
