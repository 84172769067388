import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { createBrowserHistory } from "history";
import configureStore from "./configure-store";
import ReactGA from "react-ga4";

const history = createBrowserHistory();

/* eslint-disable  @typescript-eslint/no-explicit-any */
const initialState: any = {};

const store = configureStore(history, initialState);

if (!window.location.href.includes("localhost")) {
    ReactGA.initialize('G-89TK93B6BG');
}

ReactDOM.render(<App store={store} history={history} />, document.getElementById("root"));
