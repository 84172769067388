import React, { FC, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DetailHeader from "../components/DetailHeader/DetailHeader";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import DetailHeaderText from "../components/DetailHeader/DetailHeaderText";
import LocaleEnum from "../services/Locale/enums/Locale/LocaleEnum";
import { ApplicationState } from "../store";
import DetailContentChildren from "../components/DetailContent/DetailContentChildren";
import container from "../container";
import styled from "styled-components";
import BreakpointEnum from "../shared/enums/BreakpointEnum/BreakpointEnum";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { ImportantEventsInterface } from "../services/Api/DataInterface/ImportantEventsInterface";
import { StyledArticleContent } from "../components/DetailContent/DetailContent";

const StyledArticleListItem = styled.article`
    width: 100%;
    margin: auto;
    display: flex;
    font-size: calc(18px + var(--font-modificator, 0px));
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 20px 0;
    color: var(--font-dark, #000);
    border-bottom: 1px solid var(--contrast-yellow, #ddd);;

    @media ${BreakpointEnum.XS} {
        padding: 20px;
    }

    @media ${BreakpointEnum.MD} {
        padding: 45px 20px;
        width: 100%;
    }
`;

const StyledDate = styled.div`
    font-size: calc(18px + var(--font-modificator, 0px));
    color: var(--main-red, #a71931);
    margin-bottom: 20px;
    @media ${BreakpointEnum.MD} {
        font-size: calc(28px + var(--font-modificator, 0px));
    }
`;

const StyledArticleItemContent = styled(StyledArticleContent)`
    @media ${BreakpointEnum.XS} {
        padding-bottom: 20px;
    }

    @media ${BreakpointEnum.MD} {
        padding-bottom: 25px;
`;

const StyledShortContent = styled.div``;

interface PropsInterface extends WrappedComponentProps {
    locale: LocaleEnum;
}

const ImportantEventsPage: FC<PropsInterface> = ({ locale }) => {
    const [data, setData] = useState<ImportantEventsInterface[]>([]);

    useEffect(() => {
        (async () => {
            const data = await container.dynamicModuleApiService.getImportantEvents(locale);
            setData(data.items);
        })();
    }, [locale]);

    return (
        <>
            <DetailHeader />
            <Breadcrumbs />
            <DetailHeaderText />
            <DetailContentChildren>
                {data.map((item) => (
                    <StyledArticleListItem key={`important-item-${item.id}`}>
                        <StyledDate>{item.date}</StyledDate>

                        <StyledShortContent>
                            <StyledArticleItemContent dangerouslySetInnerHTML={{ __html: item.text }} />
                        </StyledShortContent>
                    </StyledArticleListItem>
                ))}
            </DetailContentChildren>
        </>
    );
};

ImportantEventsPage.propTypes = {
    locale: PropTypes.oneOf([LocaleEnum.PL, LocaleEnum.EN]).isRequired,
    intl: PropTypes.any.isRequired,
};

const mapStateToProps = (state: ApplicationState) => ({
    locale: state.locale.locale,
});

export default connect(mapStateToProps)(injectIntl(ImportantEventsPage));
