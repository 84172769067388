import { applyMiddleware, createStore, Store } from "redux";

import thunk from "redux-thunk";

import { routerMiddleware } from "connected-react-router";

import { History } from "history";

import { ApplicationState, createRootReducer } from "./store";

/* eslint-disable  @typescript-eslint/no-explicit-any */
export default function configureStore(history: History, initialState: any): Store<ApplicationState> {
    const store = createStore(createRootReducer(history), initialState, applyMiddleware(routerMiddleware(history), thunk));
    return store;
}
