import React, { FC, useEffect, useState } from "react";
import { connect } from "react-redux";
import DetailHeader from "../../components/DetailHeader/DetailHeader";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import NewsList from "../../components/NewsList/NewsList";
import { DynamicObjectSlugEnum, PageResult } from "../../services/Api/DynamicModuleService";
import { NewsCategoryEnum, NewsInterface } from "../../services/Api/DataInterface/NewsInterface";
import { getLanguage } from "react-multi-lang";
import LocaleEnum from "../../services/Locale/enums/Locale/LocaleEnum";
import container from "../../container";
import PropTypes from "prop-types";
import DetailHeaderText from "../../components/DetailHeader/DetailHeaderText";

interface PropsInterface {
    slug: DynamicObjectSlugEnum;
    category?: NewsCategoryEnum;
    page?: number;
    openItemId?: number;
}

const ITEMS_PER_PAGE = 25;

const LayoutNewsList: FC<PropsInterface> = ({ category, slug, page, openItemId }) => {
    const lang = getLanguage() as LocaleEnum;
    const [newsPageListData, setNewsPageListData] = useState<null | PageResult<NewsInterface>>(null);
    const pageNumber = page || 1;

    useEffect(() => {
        (async () => {
            const data = await container.dynamicModuleApiService.getPageList<NewsInterface>(lang, slug, pageNumber, ITEMS_PER_PAGE, {
                ...(category ? { category } : {}),
            });
            setNewsPageListData(data);
        })();
    }, [slug, lang, category, pageNumber]);
    return (
        <>
            <DetailHeader />
            <Breadcrumbs />
            <DetailHeaderText />
            {newsPageListData && <NewsList newsPageListData={newsPageListData} openItemId={openItemId} itemsPerPage={ITEMS_PER_PAGE} />}
        </>
    );
};

LayoutNewsList.propTypes = {
    slug: PropTypes.any.isRequired,
    category: PropTypes.any,
    page: PropTypes.number,
    openItemId: PropTypes.number,
};

LayoutNewsList.defaultProps = {
    page: 1,
    openItemId: undefined,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(LayoutNewsList);
