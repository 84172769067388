import React, { FC, useEffect, useState } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";
import TopBar from "../components/TopBar/TopBar";
import Container from "../../Container";
import FullscreenMenu from "../components/FullscreenMenu/FullscreenMenu";
import LocaleEnum from "../../../services/Locale/enums/Locale/LocaleEnum";
import { ApplicationState } from "../../../store";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { changeMenuActions } from "../../../services/Locale/store/Menu/MenuActions";
import container from "../../../container";
import { GetListMenuResponseInterface } from "../../../services/Api/DataInterface/GetListMenuResponseInterface";
import GeneratePathApi from "../GeneratePathApi";
interface MenuDesktopProps extends WrappedComponentProps {
    mainMenuItems: GetListMenuResponseInterface[] | null;
    activeElement: number | boolean;
    isOpenMenu: boolean;
    changeMenuActions: typeof changeMenuActions;
    locale: LocaleEnum;
}

const MenuWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
`;

const LogosRow = styled.div`
    display: inline-flex;
    margin-top: 15px;
`;

const Logo = styled.img`
    display: inline-flex;
    position: relative;
    margin-right: 33px;
    cursor: pointer;
`;
const LogoDesc = styled.img`
    font-size: calc(20px + var(--font-modificator, 0px));
    font-family: "Barlow-Medium", sans-serif;
    position: absolute;
    transition: transform 0.3s;
    transform: translateY(-200%);
    left: 100%;
    height: 100%;
    z-index: 1;
    background: var(--background-color, #FFF);
    max-width: 300px;

    &.showImg {
        transform: translateY(0);
    }

    &.hiddenImg {
        transform: translateY(-200%);
    }

    ${(props: { agh?: boolean }) => (props.agh ? `width: 70vw;` : `width: 40vw;`)}
    &:before {
        position: absolute;
        width: 100vw;
        height: 150%;
        background: var(--background-color, #FFF);
        content: "";
        display: inline-flex;
        z-index: -1;
        top: -15px;
    }
`;

const LogoLink = styled.a`
    display: inline-flex;
    position: relative;
    align-items: center;

    &:hover {
        ${LogoDesc} {
            transition-delay: 0.3s;
            transform: translateY(0);
        }
    }
`;

const MiddleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    overflow: hidden;
`;

// const ScienceCategoryLink = styled.a`
//     display: inline-flex;
//     padding-bottom: 8px;

//     img {
//         align-self: flex-end;
//     }
// `;

const MainMenuWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;

const MainMenuList = styled.ul`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-self: right;
`;

const MainMenuItem = styled.li`
    display: inline-flex;
    text-align: center;
    justify-content: center;
    text-transform: uppercase;
    font-family: "Barlow-Bold", sans-serif;
    margin: 16px 0 16px 40px;
    cursor: pointer;
    transition: 0.2s color;
    a {
        color: var(--font-dark, #000);
        &:hover {
            color: var(--font-hover, #2263a1);
        }
    }

    &:nth-of-type(1) {
        align-items: center;
        font-size: calc(30px + var(--font-modificator, 0px));
        line-height: 0.5;
        padding-bottom: calc(4px + var(--font-modificator,0px));
    }

    &:hover {
        color: var(--font-hover, #2263a1);
    }
`;
const wrappedWithLink = (text: string, href: string) => (
    <Link to={href}>
        {text}
    </Link>
);

const MenuDesktop: FC<MenuDesktopProps> = ({ mainMenuItems, activeElement, isOpenMenu, changeMenuActions, intl, locale}) => {
    const [hoverAgh, setHoverAgh] = useState(false);

    useEffect(() => {
        if (isOpenMenu) {
            window.document.body.style.overflow = "hidden";
        } else {
            window.document.body.style.overflow = "auto";
        }
    }, [isOpenMenu]);

    return (
        <MenuWrapper>
            <TopBar isMenuOpen mainMenuItems={mainMenuItems} />

            <Container>
                <MiddleWrapper>
                    <LogosRow>
                        <LogoLink
                            href={"https://www.agh.edu.pl/"}
                            title={"AGH"}
                            target="_blank"
                            onMouseEnter={() => setHoverAgh(true)}
                            onMouseLeave={() => setHoverAgh(false)}
                        >
                            <Logo src={"/images/logo_agh.png"} alt={"AGH"} />
                            <LogoDesc agh src={locale === LocaleEnum.PL ? "/images/agh_descr.png" : "/images/agh_descr_en.png"} />
                        </LogoLink>

                        <LogoLink href={"/"} title={"WIMiR"}>
                            <Logo src={"/images/logo_wimir.png"} alt={"WIMIR"} />
                            <LogoDesc
                                agh
                                src={locale === LocaleEnum.PL ? "/images/wimir_descr.png" : "/images/wimir_descr_en.png"}
                                className={hoverAgh ? "hiddenImg" : "showImg"}
                            />
                        </LogoLink>
                    </LogosRow>
                </MiddleWrapper>

                <MainMenuWrapper>
                    <MainMenuList>
                    {mainMenuItems instanceof Array &&  mainMenuItems?.map((item, index) => {
                        if (locale === LocaleEnum.EN && !item.isMultiLang) {
                            return false;
                        }
                        const path = GeneratePathApi(item);
                        
                        return (
                            <MainMenuItem
                                onClick={() => {
                                    if (!item.routes) {
                                        changeMenuActions(item.id, true);
                                    }
                                }}
                                onKeyDown={(event: React.KeyboardEvent<HTMLLIElement>) => {
                                    if (event.key === 'Enter') {
                                        if (!item.routes) {
                                            changeMenuActions(item.id, true);
                                        }
                                    }
                                }}
                                key={`mmik_${item.id}_${index}`}
                                {...(!item.routes && { tabIndex: 0 })}
                            >
                                {path.length && item.routes ? wrappedWithLink(item?.text || "", item?.routes[locale]) : item?.text || ""}
                            </MainMenuItem>
                        );
                    })}
                    </MainMenuList>
                </MainMenuWrapper>
            </Container>
            <FullscreenMenu
                isOpen={!!activeElement}
                mainMenuItems={mainMenuItems}
                activeElementIndex={activeElement}
                closeFullScreenMenu={() => {
                    changeMenuActions(false, false);
                }}
            />
        </MenuWrapper>
    );
};

MenuDesktop.propTypes = {
    mainMenuItems: PropTypes.array,
    intl: PropTypes.any.isRequired,
    locale: PropTypes.oneOf([LocaleEnum.PL, LocaleEnum.EN]).isRequired,
    activeElement: PropTypes.any.isRequired,
    isOpenMenu: PropTypes.bool.isRequired,
    changeMenuActions: PropTypes.func.isRequired,
};

const mapStateToProps = (state: ApplicationState) => ({
    locale: state.locale.locale,
    activeElement: state.menu.activeElement,
    isOpenMenu: state.menu.isOpenMenu,
});

export default connect(mapStateToProps, { changeMenuActions })(injectIntl(MenuDesktop));
