import React, { FC, useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { BreakpointValuesEnum } from "../../shared/enums/BreakpointEnum/BreakpointEnum";
import Container from "../Container";
import CarouselArrow from "../CarouselArrow/CarouselArrow";
import { useWindowSize } from "../../shared/hooks/useWindowSize";
import {
    DateContainer,
    DateDay,
    DateMonth,
    ImageContainer,
    NewsImage,
    NewsListSection,
    SlideItem,
    SlideLead,
    SlideRow,
    SlideTitle,
} from "./NewsCarouselStyledComponents";
import LocaleEnum from "../../services/Locale/enums/Locale/LocaleEnum";
import container from "../../container";
import { categoryNews, SliderNewsItemInterface } from "../../services/Api/DataInterface/SliderNewsResponseInterface";
import { ApplicationState } from "../../store";
import { changeLocaleAction } from "../../services/Locale/store/Actions";
import arrayShuffle from "../../services/ArrayShuffle/ArrayShuffle";

const getNameMonth = (item: SliderNewsItemInterface): string => {
    const nameShortMonth = ["STY", "LUTY", "MARZ", "KWIE", "MAJ", "CZER", "LIP", "SIER", "WRZE", "PAŹ", "LIST", "GRU"];

    if (!item.date_article) {
        return "";
    }

    const date = new Date(item.date_article);
    if (nameShortMonth[date.getMonth()]) {
        return nameShortMonth[date.getMonth()];
    }

    return "";
};

const getDay = (item: SliderNewsItemInterface): string | number => {
    const date = new Date(item.date_article);
    return date.getDate();
};

const divideItems = (items: SliderNewsItemInterface[], slidesPerRow = 4) => {
    const slides = [];
    const slidesNumber = items.length / slidesPerRow;

    for (let i = 0; i < slidesNumber; i++) {
        const offset = i * slidesPerRow;
        const slide = {
            slideIndex: i,
            items: items.slice(offset, offset + slidesPerRow),
        };

        slides.push(slide);
    }

    return slides;
};

const calcItemsNumberPerSlide = (width: number): number => {
    let itemsPerRowBasedOnWindowWidth = 1;

    if (width > BreakpointValuesEnum.S) {
        itemsPerRowBasedOnWindowWidth = 2;
    }
    if (width > BreakpointValuesEnum.MD) {
        itemsPerRowBasedOnWindowWidth = 4;
    }

    return itemsPerRowBasedOnWindowWidth;
};

export interface NewsCarouselPropsInterface {
    locale: LocaleEnum;
}

const NewsCarousel: FC<NewsCarouselPropsInterface> = ({ locale }) => {
    const size = useWindowSize();
    const [itemsPerRow, setItemsPerRow] = useState(calcItemsNumberPerSlide(size?.width || 0));
    const [currentSlide, setCurrentSlide] = useState(0);
    const [data, setData] = useState<SliderNewsItemInterface[]>([]);
    const preparedList = itemsPerRow && divideItems(data, itemsPerRow);
    const emptyLogos = ["/images/news_empty_logo.png", "/images/news_2.png", "/images/news_3.png"];
    useEffect(() => {
        (async () => {
            const data = await container.dynamicModuleApiService.getNewsHomePage(locale);
            setData(data.items);
        })();
    }, [locale]);

    useEffect(() => {
        const { width } = size;
        if (!width) {
            return;
        }

        const itemsNumber = calcItemsNumberPerSlide(width);

        setItemsPerRow(itemsNumber);
    }, [size.width]);

    if (!preparedList) {
        return null;
    }

    if (locale !== LocaleEnum.PL) {
        return null;
    }

    return (
        <NewsListSection>
            <Container>
                <CarouselArrow onClick={() => setCurrentSlide(currentSlide - 1)} direction={"left"} overwritePosition={-20} />

                <Carousel
                    preventMovementUntilSwipeScrollTolerance={true}
                    swipeScrollTolerance={35}
                    className={"slider--news-list"}
                    showThumbs={false}
                    showIndicators={false}
                    showStatus={false}
                    showArrows={false}
                    infiniteLoop
                    selectedItem={currentSlide}
                    onChange={setCurrentSlide}
                    transitionTime={300}
                >
                    {preparedList.map((slide) => {
                        const elementsInCurrentSlide = slide.items.length;
                        const emptyFullfillersArr = Array(itemsPerRow - elementsInCurrentSlide).fill(null);
                        return (
                            <SlideRow key={`row${slide.slideIndex}`}>
                                {slide.items.map((item: SliderNewsItemInterface, index: number) => (
                                    <SlideItem
                                        key={`newsslide${slide.slideIndex}${index}`}
                                        isEmpty={false}
                                        to={
                                            item.category === categoryNews.NEWS
                                                ? `/pl/studenci/aktualnosci-ogloszenia#${item.id}`
                                                : `/pl/studenci/aktualnosci-niestacjonarne#${item.id}`
                                        }
                                    >
                                        <ImageContainer>
                                            <NewsImage
                                                src={item?.aktualnosci_obrazki[0]?.url || arrayShuffle(emptyLogos)[0]}
                                                alt={item?.title || ""}
                                            />
                                            {item?.date_article && (
                                                <DateContainer>
                                                    <DateDay>{getDay(item)}</DateDay>
                                                    <DateMonth>{getNameMonth(item)}</DateMonth>
                                                </DateContainer>
                                            )}
                                        </ImageContainer>
                                        <SlideTitle>{item.title}</SlideTitle>
                                        <SlideLead dangerouslySetInnerHTML={{ __html: item?.short_description || item?.description || "" }} />
                                    </SlideItem>
                                ))}
                                {emptyFullfillersArr.map((fullfiller, index) => (
                                    <SlideItem key={`empty${slide.slideIndex}${index}`} isEmpty to={"#"} />
                                ))}
                            </SlideRow>
                        );
                    })}
                </Carousel>

                <CarouselArrow onClick={() => setCurrentSlide(currentSlide + 1)} direction={"right"} overwritePosition={-20} />
            </Container>
        </NewsListSection>
    );
};

NewsCarousel.propTypes = {
    locale: PropTypes.oneOf([LocaleEnum.PL, LocaleEnum.EN]).isRequired,
};

const mapStateToProps = (state: ApplicationState) => ({
    locale: state.locale.locale,
});

export default connect(mapStateToProps, { changeLocaleAction })(NewsCarousel);
