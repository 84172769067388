import React, { FC, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Link, useLocation } from "react-router-dom";
import BreakpointEnum from "../../shared/enums/BreakpointEnum/BreakpointEnum";
import Container from "../Container";
import { injectIntl, WrappedComponentProps } from "react-intl";
import PropTypes from "prop-types";
import LocaleEnum from "../../services/Locale/enums/Locale/LocaleEnum";
import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import FullscreenMenu from "../Menu/components/FullscreenMenu/FullscreenMenu";
import { GetListMenuResponseInterface } from "../../services/Api/DataInterface/GetListMenuResponseInterface";
import container from "../../container";

const sharedStyle = css`
    @media ${BreakpointEnum.S} {
        cursor: default;
        position: relative;
        display: inline-flex;
        min-width: 25%;
        padding: 20px;
        font-size: calc(24px + var(--font-modificator, 0px));
        color: var(--breadcrumbs-font-color, #00305c);
        background: var(--main-grey, #ccc);
        margin-right: 30px;

        &.element-breadcrumbs {
            &:after {
                position: absolute;
                content: "›";
                width: 30px;
                right: -30px;
                height: 30px;
                text-align: center;
                font-size: calc(22px + var(--font-modificator, 0px));
                margin: auto;
                top: 0;
                bottom: 0;
            }
        }

        &.element-breadcrumbs-last {
            color: var(--breadcrumbs-font-color, #fff);
            background: var(--font-dark, #00305c);
        }

        &:nth-child(1) {
            min-width: auto;
        }
    }
`;

const StyledBreadcrumbs = styled.div`
    width: 100%;
    padding: 30px 0;
    row-gap: 15px;
    flex-wrap: wrap;
    display: none;
    @media ${BreakpointEnum.S} {
        display: flex;
    }
`;

const StyledBreadcrumbItemLink = styled(Link)`
    cursor: pointer !important;
    ${sharedStyle}
    @media ${BreakpointEnum.S} {
        &:hover {
            text-decoration: underline var(--font-dark, #00305c);
        }

        &.element-breadcrumbs-last {
            &:hover {
                text-decoration: underline var(--font-light, #fff);
            }
        }

        ${(props: { hasHref: boolean }) =>
            !props.hasHref &&
            `
                cursor: default;
                &:hover {
                    text-decoration: none;
                }
            `}
    }
`;

const StyledBreadcrumbItem = styled.div`
    ${sharedStyle}
    cursor: pointer !important;
`;

const getDataForBreadcrumbs = (menu: GetListMenuResponseInterface[], activeElements: GetListMenuResponseInterface[], pathname: string) => {
    for (const item of menu) {
        if (item.routes && ((item.routes.pl && item.routes.pl === pathname) || (item.routes.en && item.routes.en === pathname))) {
            activeElements.push(item);
            break;
        }

        if (!item.children) {
            continue;
        }

        getDataForBreadcrumbs(item.children, activeElements, pathname);
        if (activeElements.length > 0) {
            activeElements.push(item);
            break;
        }
    }
};

const getGreatestParentIndex = (menu: GetListMenuResponseInterface[], id: number): number | boolean => {
    for (let i = 0; i < menu.length; i++) {
        if (menu[i].id === id) {
            return menu[i].id;
        }

        const children = menu[i].children ?? [];
        if (children.length > 0) {
            const childResult = getGreatestParentIndex(children, id);
            if (childResult !== false) {
                return menu[i].id;
            }
        }
    }
    return false;
};


interface PropsInterface extends WrappedComponentProps {
    locale: LocaleEnum;
}

export const Breadcrumbs: FC<PropsInterface> = ({ intl, locale }) => {
    const location = useLocation();
    const breadcrumbsElement: GetListMenuResponseInterface[] = [];
    const [isFullscreenMenuOpen, setIsFullscreenMenuOpen] = useState(false);
    const [activeElementIndex, setActiveElementIndex] = useState<number | boolean>(false);
    const [openedFromBreadcrumbs, setOpenedFromBreadcrumbs] = useState(false);
    const [menuShortcut, setMenuShortcut] = useState<GetListMenuResponseInterface[] | null>(null);

    useEffect(() => {
        (async () => {
            const data = await container.menuService.getListMenu(locale)
            setMenuShortcut(data);
        })();
    }, [locale]);

    if(!menuShortcut) {
        return null;
    }

    const openFullscreenMenu = (id: number) => {
        const parentIndex = getGreatestParentIndex(menuShortcut, id);
        setActiveElementIndex(parentIndex);
        setIsFullscreenMenuOpen(true);
        setOpenedFromBreadcrumbs(true);
    };

    getDataForBreadcrumbs(menuShortcut, breadcrumbsElement, location.pathname);

    if (breadcrumbsElement.length === 0) {
        return null;
    }

    const homeBreadcumb = menuShortcut.find(item => item.id === 1);
    if (homeBreadcumb) {
        breadcrumbsElement.push(homeBreadcumb);
    }
    
    const breadcrumbsElementReverse = breadcrumbsElement.reverse();
    const lengthBreadcrumbsElement = breadcrumbsElementReverse.length;

    return (
        <Container>
            <StyledBreadcrumbs>
                {breadcrumbsElementReverse.map((item, index) => {
                    if (
                        !item.routes ||
                        (item.routes.pl && item.routes.pl === window.location.pathname) ||
                        (item.routes.en && item.routes.en === window.location.pathname)
                    ) {
                        return (
                            <StyledBreadcrumbItem
                                key={`kbread${item.id}`}
                                title={ item?.text || "" }
                                className={lengthBreadcrumbsElement == index + 1 ? "element-breadcrumbs-last" : "element-breadcrumbs"}
                                onClick={() => openFullscreenMenu(item.id)}
                            >
                                { item?.text || "" }
                            </StyledBreadcrumbItem>
                        );
                    } else {
                        return (
                            <StyledBreadcrumbItemLink
                                hasHref={!!item.routes && !!item.routes[locale]}
                                key={`kbread${item.id}`}
                                to={item.routes[locale]}
                                title={ item?.text || "" }
                                className={lengthBreadcrumbsElement == index + 1 ? "element-breadcrumbs-last" : "element-breadcrumbs"}
                            >
                                { item?.text || "" }
                            </StyledBreadcrumbItemLink>
                        );
                    }
                })}
            </StyledBreadcrumbs>
            <FullscreenMenu
                isOpen={isFullscreenMenuOpen}
                mainMenuItems={menuShortcut}
                activeElementIndex={activeElementIndex}
                closeFullScreenMenu={() => {
                    setIsFullscreenMenuOpen(false);
                    setActiveElementIndex(false);
                    setOpenedFromBreadcrumbs(false);
                }}
                openingSource={openedFromBreadcrumbs}
            />
        </Container>
    );
};

Breadcrumbs.propTypes = {
    intl: PropTypes.any.isRequired,
    locale: PropTypes.oneOf([LocaleEnum.PL, LocaleEnum.EN]).isRequired,
};

Breadcrumbs.defaultProps = {};

const mapStateToProps = (state: ApplicationState) => ({
    locale: state.locale.locale,
});

export default connect(mapStateToProps)(injectIntl(Breadcrumbs));