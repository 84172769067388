import React, { FC, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DetailHeader from "../components/DetailHeader/DetailHeader";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import DetailHeaderText from "../components/DetailHeader/DetailHeaderText";
import LocaleEnum from "../services/Locale/enums/Locale/LocaleEnum";
import { ApplicationState } from "../store";
import DetailContentChildren from "../components/DetailContent/DetailContentChildren";
import container from "../container";
import { HistoryDeansInterface } from "../services/Api/DataInterface/HistoryDeansInterface";
import styled from "styled-components";
import BreakpointEnum from "../shared/enums/BreakpointEnum/BreakpointEnum";
import { injectIntl, WrappedComponentProps } from "react-intl";

const ListItem = styled.div`
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 30px 40px 30px;
    width: 200px;

    @media ${BreakpointEnum.MD} {
        margin: 30px 50px 30px;
    }

    @media ${BreakpointEnum.XXL} {
        margin: 30px 50px 30px;
    }
`;

const ListItemTitle = styled.div`
    font-size: calc(14px + var(--font-modificator, 0px));
    text-align: center;
    margin-top: 10px;

    @media ${BreakpointEnum.MD} {
        font-size: calc(18px + var(--font-modificator, 0px));
        margin-top: 10px;
    }

    @media ${BreakpointEnum.XXL} {
        font-size: calc(20px + var(--font-modificator, 0px));
    }
`;

const ListItemIco = styled.img`
    margin: auto;
`;

const ListItemIcoEmpty = styled.div`
    background-color: red;
    height: 160px;
    width: 120px;
    margin: auto;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: var(--font-dark, #00305c);
`;

interface PropsInterface extends WrappedComponentProps {
    locale: LocaleEnum;
}

const DeansPage: FC<PropsInterface> = ({ locale, intl }) => {
    const [data, setData] = useState<HistoryDeansInterface[]>([]);

    useEffect(() => {
        (async () => {
            const data = await container.dynamicModuleApiService.getHistoryDeans(locale);
            setData(data.items);
        })();
    }, [locale]);

    return (
        <>
            <DetailHeader />
            <Breadcrumbs />
            <DetailHeaderText />
            <DetailContentChildren>
                <p>{intl.formatMessage({ id: "deansPage.description" })}</p>
                {data.map((item) => (
                    <ListItem key={item.id}>
                        {item.dziekani_obrazki[0]?.url ? (
                            <ListItemIco src={item.dziekani_obrazki[0].url} alt={item.name} />
                        ) : (
                            <ListItemIcoEmpty style={{ backgroundImage: 'url("/images/logo_agh_empty_photo.png")' }} />
                        )}
                        <ListItemTitle>{item.date}</ListItemTitle>
                        <ListItemTitle>{item.name}</ListItemTitle>
                    </ListItem>
                ))}
            </DetailContentChildren>
        </>
    );
};

DeansPage.propTypes = {
    locale: PropTypes.oneOf([LocaleEnum.PL, LocaleEnum.EN]).isRequired,
    intl: PropTypes.any.isRequired,
};

const mapStateToProps = (state: ApplicationState) => ({
    locale: state.locale.locale,
});

export default connect(mapStateToProps)(injectIntl(DeansPage));
