import React, { FC } from "react";
import PropTypes from "prop-types";
import DetailHeader from "../DetailHeader/DetailHeader";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import Container from "../Container";
import { FilesForStaffInterface } from "../../services/Api/DataInterface/FilesForStaffInterface";
import FileItems from "./FileItems";
import "react-tabs/style/react-tabs.css";

export interface FileForStaffPropsInterface {
    data: FilesForStaffInterface[] | null;
}

const FileForStaff: FC<FileForStaffPropsInterface> = ({ data }) => {
    if (!data) {
        return null;
    }

    return (
        <>
            <DetailHeader />
            <Breadcrumbs />
            <Container>
                <FileItems data={data} />
            </Container>
        </>
    );
};

FileForStaff.propTypes = {
    data: PropTypes.any,
};

export default FileForStaff;
