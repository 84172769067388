import { ChangeMenuActionInterface, MenuActionTypesEnum, MenuStateInterface } from "./MenuTypes";

const initialeMenuState: MenuStateInterface = {
    activeElement: false,
    isOpenMenu: false,
};

const menuReducer = function (state = initialeMenuState, action: ChangeMenuActionInterface): MenuStateInterface {
    switch (action.type) {
        case MenuActionTypesEnum.CHANGE_MENU:
            return {
                ...state,
                activeElement: action.activeElement,
                isOpenMenu: action.isOpenMenu,
            };
        default:
            return state;
    }
};

export default menuReducer;
