import React, { FC } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

interface HamburgerProps {
    isArrow?: boolean;
    isActive?: boolean;
}

const StyledHamburger = styled.div`
    width: auto;
    margin: 12px auto;
    transition: all 0.3s ease;
    z-index: 99;
    .bar {
        display: block;
        height: 2px;
        width: 40px;
        background: var(--font-light, #FFF);
        margin: 8px auto;
        transition: all 0.3s ease;
    }

    &.active .arrow-top {
        transform: rotateZ(-45deg) translateY(3px);
        width: 25px;
    }

    &.active .arrow-middle {
        transform: translateX(15px);
    }

    &.active .arrow-bottom {
        transform: rotateZ(45deg) translateY(-3px);
        width: 25px;
    }
`;

const Hamburger: FC<HamburgerProps> = ({ isActive }) => {
    return (
        <StyledHamburger className={isActive ? "active" : ""}>
            <div className="bar arrow-top" />
            <div className="bar arrow-middle" />
            <div className="bar arrow-bottom" />
        </StyledHamburger>
    );
};

Hamburger.propTypes = {
    isActive: PropTypes.bool,
    isArrow: PropTypes.bool,
};
export default Hamburger;
