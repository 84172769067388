import React, { FC } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import BreakpointEnum from "../../shared/enums/BreakpointEnum/BreakpointEnum";

interface ContainerProps {
    withPadding: boolean;
}

const StyledContainer = styled.div`
    width: 100%;
    margin: auto;
    position: relative;

    @media ${BreakpointEnum.S} {
        max-width: 720px;
        padding: ${(props: ContainerProps) => (props.withPadding ? "0 10px" : "0")};
    }

    @media ${BreakpointEnum.MD} {
        max-width: 960px;
        padding: ${(props: ContainerProps) => (props.withPadding ? "0 18px" : "0")};
    }

    @media ${BreakpointEnum.XL} {
        max-width: 1140px;
        padding: ${(props: ContainerProps) => (props.withPadding ? "0 26px" : "0")};
    }

    @media ${BreakpointEnum.XXL} {
        max-width: 1600px;
        padding: ${(props: ContainerProps) => (props.withPadding ? "0 42px" : "0")};
    }
`;

interface IContainerProps {
    children: React.ReactNode | React.ReactNodeArray;
    withPadding?: boolean;
}

export const Container: FC<IContainerProps> = ({ children, withPadding = true }) => {
    return <StyledContainer withPadding={withPadding}>{children}</StyledContainer>;
};

Container.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
    withPadding: PropTypes.bool,
};

Container.defaultProps = {
    withPadding: true,
};

export default Container;
