import { AxiosError } from "axios";
import _get from "lodash/get";
import FrontApiError from "./FrontApiError";

export default class FrontApiErrorDto {
    static httpErrorToFrontApiError(e: AxiosError) {
        const status: number = _get(e, "response.status", 500);
        return new FrontApiError(status, _get(e, "response.data", {}));
    }
}
