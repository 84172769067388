import AbstractRestApi from "../AbstractRest/AbstractRestApi";
import AbstractApiConstructorInterface from "../AbstractRest/AbstractRestApiOptionsInterface";
import frontApiResponseErrorTransformer from "./Transformers/frontApiResponseErrorTransformer";
import frontApiRequestTransformer from "./Transformers/frontApiRequestTransformer";
import LocaleEnum from "../Locale/enums/Locale/LocaleEnum";
import ApiRequestOptionsInterface from "./ApiRequestOptionsInterface";
import { TopMenuResponseInterface } from "./DataInterface/TopMenuResponseInterface";
import { SliderHomepageResponseInterface } from "./DataInterface/SliderHomepageResponseInterface";
import { SliderNewsResponseInterface } from "./DataInterface/SliderNewsResponseInterface";
import { TrailersResponseInterface } from "./DataInterface/TrailersResponseInterface";
import { PageStaticResponseInterface } from "./DataInterface/PageStaticResponseInterface";
import { HistoryDeansInterface } from "./DataInterface/HistoryDeansInterface";
import { ImportantEventsInterface } from "./DataInterface/ImportantEventsInterface";
import { SliderOtherPageInterface } from "./DataInterface/SliderOtherPageInterface";
import {EmployeeListResponseInterface} from "./DataInterface/EmployeeListResponseInterface";

export interface PageResult<T> {
    numOfItems: number;
    items: T[];
}

export enum DynamicObjectSlugEnum {
    Announcement = "ogloszenia",
    DeanOfficeInformation = "biuro_dziekana_informacje",
    FromLifeOfTheFaculty = "z_zycia_wydzialu",
    Timetable = "rozklad_zajec",
    PageStatic = "strony_statyczne",
    HistoryDeans = "hw_dziekani",
    SliderOtherPage = "slider_pozostale_strony",
    ImportantEvents = "hw_wazniejsze_wydarzenia",
}

export default class DynamicModuleService extends AbstractRestApi<ApiRequestOptionsInterface> {
    constructor(options: AbstractApiConstructorInterface) {
        super(options);
    }

    public addInterceptor() {
        this.addInterceptors(frontApiResponseErrorTransformer(), frontApiRequestTransformer());
    }

    public async getTopMenu(lang: LocaleEnum): Promise<TopMenuResponseInterface> {
        const response = await this.get("/dynamic-module/top_menu", { params: { lang, limit: 4 } });

        return response.data;
    }

    public async getSliderHomePage(lang: LocaleEnum): Promise<SliderHomepageResponseInterface> {
        const response = await this.get("/dynamic-module/slider_strona_glowna", { params: { lang } });

        return response.data;
    }

    public async getNewsHomePage(lang: LocaleEnum): Promise<SliderNewsResponseInterface> {
        const response = await this.get("/dynamic-module/ogloszenia", { params: { lang, limit: 12, "filters[publicate]": "Tak" } });

        return response.data;
    }

    public async getTrailersHomePage(lang: LocaleEnum): Promise<TrailersResponseInterface> {
        const response = await this.get("/dynamic-module/trailers", { params: { lang, limit: 8 } });

        return response.data;
    }

    public async getImportantEvents(lang: LocaleEnum): Promise<PageResult<ImportantEventsInterface>> {
        const response = await this.get(`/dynamic-module/${DynamicObjectSlugEnum.ImportantEvents}`, { params: { lang, limit: 100 } });

        return response.data;
    }

    public async getHistoryDeans(lang: LocaleEnum): Promise<PageResult<HistoryDeansInterface>> {
        const response = await this.get(`/dynamic-module/${DynamicObjectSlugEnum.HistoryDeans}`, { params: { lang, limit: 30 } });

        return response.data;
    }

    public async getSliderOtherPage(): Promise<PageResult<SliderOtherPageInterface>> {
        const response = await this.get(`/dynamic-module/${DynamicObjectSlugEnum.SliderOtherPage}`, { params: { limit: 30 } });

        return response.data;
    }

    public async getStatisticPage(lang: LocaleEnum, filters: { [key: string]: string }): Promise<PageStaticResponseInterface> {
        const queryFilters: { [key: string]: string } = {};
        Object.keys(filters).map((filterKey) => {
            queryFilters[`filters[${filterKey}]`] = filters[filterKey];
        });
        const response = await this.get(`/dynamic-module/${DynamicObjectSlugEnum.PageStatic}`, { params: { lang, ...queryFilters } });

        return response.data;
    }

    public async getStatisticPageById(lang: LocaleEnum, itemId: number ): Promise<PageStaticResponseInterface> {
        const response = await this.get(`/dynamic-module/${DynamicObjectSlugEnum.PageStatic}/${itemId}`, { params: { lang } });

        return response.data;
    }

    public async getEmployeeList(lang: LocaleEnum): Promise<PageResult<EmployeeListResponseInterface>> {
        const response = await this.get(`/dynamic-module/rada_spolecznia_wimir__osoby`, { params: { lang, limit: 100 } });

        return response.data;
    }

    public async getPageList<T>(
        lang: LocaleEnum,
        slug: DynamicObjectSlugEnum,
        page: number,
        perPage: number,
        filters: { [key: string]: string } = {}
    ): Promise<PageResult<T>> {
        const queryFilters: { [key: string]: string } = {};
        Object.keys(filters).map((filterKey) => {
            queryFilters[`filters[${filterKey}]`] = filters[filterKey];
        });
        const response = await this.get(`/dynamic-module/${slug}`, {
            params: { lang, ...queryFilters, offset: (page - 1) * perPage, limit: perPage },
        });

        const numOfItems: number = response.data.numOfItems;
        const items: T[] = response.data.items;

        return {
            numOfItems: numOfItems,
            items: items,
        };
    }
}
