import { LocaleActionTypesEnum, LocaleActionTypes } from "./Types";
import LocaleEnum from "../enums/Locale/LocaleEnum";

export function changeLocaleAction(locale: LocaleEnum): LocaleActionTypes {
    return {
        type: LocaleActionTypesEnum.CHANGE_LOCALE,
        locale,
    };
}

