import React, { FC } from "react";
import styled from "styled-components";
import BreakpointEnum from "../../shared/enums/BreakpointEnum/BreakpointEnum";
import Container from "../Container";
import PropTypes from "prop-types";
import { StyledArticleContent } from "./DetailContent";

const StyledDetailContent = styled.div`
    width: 90%;
    margin: auto;
    display: flex;
    font-size: calc(18px + var(--font-modificator, 0px));
    padding: 45px 0;
    width: ${(props: { isFullWidth?: boolean }) => (props.isFullWidth ? "100%" : "90%")};
    @media ${BreakpointEnum.S} {
        width: 100%;
    }
`;

export const DetailContentChildren: FC<{ isFullWidth?: boolean; children: React.ReactNode | React.ReactNodeArray }> = ({
    isFullWidth,
    children,
}) => {
    return (
        <StyledDetailContent isFullWidth={isFullWidth}>
            <Container>
                <StyledArticleContent> {children}</StyledArticleContent>
            </Container>
        </StyledDetailContent>
    );
};

DetailContentChildren.propTypes = {
    isFullWidth: PropTypes.bool,
    children: PropTypes.object,
};

DetailContentChildren.defaultProps = {
    isFullWidth: false,
};

export default DetailContentChildren;
