import styled from "styled-components";
import BreakpointEnum from "../../../../../shared/enums/BreakpointEnum/BreakpointEnum";

const MenuContent = styled.div`
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    transition: 0.2s;
    opacity: 0;
    transition-delay: 0s;
    display: flex;
    z-index: 1;
`;

const FullscreenMenuWrapper = styled.div`
    position: fixed;
    background: var(--main-blue, #00305c);
    height: 0;
    width: 0;
    margin: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: 0.3s;
    z-index: 9;
    overflow: auto;

    ${(props: {isOpen: boolean}) =>
            props.isOpen &&
            `
        width: 100%;
        height: 80%;
        
        ${MenuContent} {
             transform: translateX(0);
             opacity:1;
             transition-delay: .3s;
        }
    `};
`;

const FullscreenMenuItem = styled.li`
    color: var(--font-light, #fff);
    font-size: calc(27px + var(--font-modificator, 0px));
    cursor: pointer;
    text-transform: uppercase;
    font-family: "Barlow-Light", sans-serif;
    padding: 16px 70px 0;

    &:nth-child(1) {
        padding: 0 70px;
    }
    
    @media ${BreakpointEnum.MD} {
        font-size: calc(2.7vw + var(--font-modificator, 0px));
        padding: 15px 0;
    }
`;

const FullscreenMenuList = styled.ul`
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    list-style: none;
    text-align: center;
    transform: translateY(10vh);
    transition: transform 0.3s, max-height 0.3s, margin 0.3s;
    position: relative;
    z-index: 2;
    background: var(--main-blue, #00305c);
    padding: 15px 0;

    @media ${BreakpointEnum.MD} {
        max-height: none;
        position: fixed;
        -webkit-transform-origin: 0 0;
        top: 100%;
        transform: rotate(-90deg) translateY(0);
        left: 0;
        width: 100vh;
        min-height: 80px;
        padding-right: var(--vertical-menu-max-height, 80px);
    }

    ${(props: {hasActiveParent?: boolean; isClosed?: boolean}) =>
    props.hasActiveParent &&
    `
        transform: translateY(5vh); 
        overflow: hidden;
        border-bottom: 2px solid #fff;
        
        @media ${BreakpointEnum.MD} {
            transform: translateY(0) rotate(-90deg); 
        }
    `}

    ${(props: {hasActiveParent?: boolean; isClosed?: boolean}) =>
    props.isClosed &&
    `
        max-height: 60px;
     
        @media ${BreakpointEnum.MD} {
            max-height: var(--vertical-menu-max-height, 80px);
        }
    `}

    ${(props: {hasActiveParent?: boolean; isClosed?: boolean}) =>
    props.isClosed && props.hasActiveParent && `
       ${FullscreenMenuItem}:not(:first-of-type) {
            display: none;
       }
    `}
`;

const ActiveFullscreenMenuItem = styled(FullscreenMenuItem)`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    min-height: 60px;
    
    &:empty {
        display: none;
    }

    @media ${BreakpointEnum.MD} {
        min-height: var(--vertical-menu-max-height, 80px);
        font-size: calc(2.7vw + var(--font-modificator, 0px));
        padding: 15px 0;
    }
`;

const SubmenuContent = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    z-index: 1;
    margin: auto;
    padding-top: 10vh;
    justify-content: normal;
    align-items: center;

    @media ${BreakpointEnum.MD} {
        left: var(--vertical-menu-max-height, 80px); // max-height of collapsed verticalMenu
        width: calc(100% - var(--vertical-menu-max-height, 80px));
        top: 0;
        bottom: 0;
        align-items: flex-start;
        max-height: 100vh;
        overflow: auto;
        padding: 0;
        
        @media(max-height: 800px){
            padding-top: 5vh;
            justify-content: normal;
        }
    }
`;

const FullscreenSubmenuList = styled.ul`
    display: flex;
    flex-direction: column;
    list-style: none;
`;

const StyledHamburgerBox = styled.div`
    position: absolute;
    left: 10px;
    top: 10px;
    
    @media ${BreakpointEnum.MD} {
        transform: rotate(90deg);
        right: 25%;
        left: auto;
        top: 2.25vw;
    }
`;

const StyledSubmenuItem = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    color: var(--font-light, #fff);
    font-size: calc(22px + var(--font-modificator, 0px));
    text-transform: uppercase;
    font-family: "Barlow-Light", sans-serif;
    text-align: center;
    cursor: pointer;
    width: 100%;

    @media ${BreakpointEnum.MD} {
        width: 50%;
    }
    
    ${(props: {hasChildren: boolean}) =>
        props.hasChildren &&
    `
        cursor: default;
    `}
`;

const StyledSubmenuItemTitle = styled.div`
    display: inline-flex;
    text-align: center;
    justify-content: center;
    padding: 15px 0;
    transition: background-color 0.2s;
    position: relative;

    a {
        color: var(--font-light, #fff);
        width: 100%;

        &:hover {
            color: var(--font-light, #fff);
            font-weight: 600;
        }
    }

    @media ${BreakpointEnum.MD} {
        padding: 1vw;
        font-size: calc(1.5vw + var(--font-modificator, 0px));
    }

    &:hover {
        background: var(--main-red, #a71931);
    }

    ${(props: {isActive: boolean}) =>
            props.isActive &&
            `
        background: var(--main-red, #a71931);
    `}
`;

const StyledSubmenuList = styled.ul`
    display: inline-flex;
    max-height: 0;
    overflow: hidden;
    list-style: none;
    flex-direction: column;
    flex-wrap: nowrap;
    transition: 0.2s max-height;
    z-index: 2;
    transition-timing-function: ease-in;

    @media ${BreakpointEnum.MD} {
        position: fixed;
        right: 0;
        top: 0;
        width: 0;
        height: 100%;
        background: var(--main-red, #c0001b);
        align-items: center;
        justify-content: center;
        transition: 0.2s width;
        transition-delay: 0.4s;
        max-height: 1999px;
        overflow: auto;

        @media (max-height: 800px) {
            padding-top: 10vh;
            justify-content: normal;
        }
        
        ${(props: { isActive: boolean, justifyTop: boolean }) => props.justifyTop && '' +
                'justify-content: normal;' +
                'padding-top: 10vh;'}
    }

    ${(props: { isActive: boolean, justifyTop: boolean }) =>
        props.isActive &&
        `
        max-height: 1999px;
        
        @media ${BreakpointEnum.MD} {
            width: 50%
        }
    `}
`;

const StyledSubmenuListItem = styled.li`
    display: inline-flex;
    padding: 15px 0;
    justify-content: center;
    text-align: center;
    font-size: calc(18px + var(--font-modificator, 0px));

    a {
        color: var(--font-light, #fff);
        width: 100%;

        &:hover {
            color: var(--font-light, #fff);
            font-weight: 600;
        }
    }

    @media ${BreakpointEnum.MD} {
        padding: 1vw;
        font-size: calc(1.5vw + var(--font-modificator, 0px));
    }

    &:hover {
        font-weight: 600;
    }
`;

const CloseIconWrapper = styled.div`
    .hamburger-react {
        position: fixed !important;
        z-index: 99;
        top: 0;
        right: 15px;
    }
`;

const ScrollArrow = styled.div<{ isVisible: boolean }>`
    position: fixed;
    width: 46.6%;
    text-align: center;
    z-index: 1;
    cursor: pointer;
    font-size: 3.5em;
    color: #FFF;
    background-color: var(--main-blue, #00305c);
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
    height: ${({ isVisible }) => (isVisible ? 'auto' : '0')};
    overflow: hidden;
    transition: opacity 0.3s, visibility 0.3s, height 0.3s;
    user-select: none;

    &:hover {
        background-color: var(--main-red, #c0001b);
    }

    &.top {
        top: 0px;
    }

    &.bottom {
        bottom: 0px;
    }
`;

export {
    SubmenuContent,
    FullscreenMenuItem,
    FullscreenMenuList,
    FullscreenMenuWrapper,
    MenuContent,
    ActiveFullscreenMenuItem,
    FullscreenSubmenuList,
    StyledHamburgerBox,
    StyledSubmenuList,
    StyledSubmenuItem,
    StyledSubmenuListItem,
    StyledSubmenuItemTitle,
    CloseIconWrapper,
    ScrollArrow,
};
