import { combineReducers } from "redux";
import { connectRouter, RouterState } from "connected-react-router";
import { History } from "history";
import { LocaleStateInterface } from "../services/Locale/store/Types";
import localeReducer from "../services/Locale/store/Reducers";
import { MenuStateInterface } from "../services/Locale/store/Menu/MenuTypes";
import menuReducer from "../services/Locale/store/Menu/MenuReducer";
import { ContrastStateInterface } from "../services/Locale/store/Contrast/ContrastTypes";
import contrastReducer from "../services/Locale/store/Contrast/ContrastReducer";

export interface ApplicationState {
    router: RouterState;
    locale: LocaleStateInterface;
    menu: MenuStateInterface;
    contrast: ContrastStateInterface;
}

export const createRootReducer = (history: History) =>
    combineReducers({
        router: connectRouter(history),
        locale: localeReducer,
        menu: menuReducer,
        contrast: contrastReducer,
    });
