import AbstractRestApi from "../AbstractRest/AbstractRestApi";
import AbstractApiConstructorInterface from "../AbstractRest/AbstractRestApiOptionsInterface";
import frontApiResponseErrorTransformer from "./Transformers/frontApiResponseErrorTransformer";
import frontApiRequestTransformer from "./Transformers/frontApiRequestTransformer";
import ApiRequestOptionsInterface from "./ApiRequestOptionsInterface";

export default class StaffPanelService extends AbstractRestApi<ApiRequestOptionsInterface> {
    constructor(options: AbstractApiConstructorInterface) {
        super(options);
    }

    public addInterceptor() {
        this.addInterceptors(frontApiResponseErrorTransformer(), frontApiRequestTransformer());
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public async getUserContent(): Promise<any> {
        const response = await this.get("/user/content");

        return response.data;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public async getSessionId(): Promise<any> {
        const response = await this.get("/user/form");

        return response.data;
    }
}
