import React from "react";
import ErrorContent from "../../components/ErrorContent/ErrorContent";

interface PropsInterface {}

const Layout404: React.FC<PropsInterface> = () => {
    return <ErrorContent content={"strona nie została znaleziona"} backUrl={"/"} backLabel={"Przejdź na stronę główną"} />;
};

export default Layout404;
