import { Action } from "redux";
import {ThemeEnum} from "../../../../shared/enums/ThemeEnum/ThemeEnum";

export enum ContrastActionTypesEnum {
    CHANGE_CONTRAST = "CHANGE_CONTRAST",
}

export interface ContrastStateInterface {
    contrast: ThemeEnum;
}

interface ChangeContrastActionInterface extends Action<ContrastActionTypesEnum.CHANGE_CONTRAST> {
    contrast: ThemeEnum;
}

export type ContrastActionTypes = ChangeContrastActionInterface;
