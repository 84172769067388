import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore [BUG]https://github.com/leandrowd/react-responsive-carousel/issues/593
import Carousel from "react-responsive-carousel/lib/js/components/Carousel/index";

import Container from "../Container";
import BreakpointEnum, { BreakpointValuesEnum } from "../../shared/enums/BreakpointEnum/BreakpointEnum";
import { Arrow, ArrowProps } from "../CarouselArrow/CarouselArrow";
import { useWindowSize } from "../../shared/hooks/useWindowSize";
import LocaleEnum from "../../services/Locale/enums/Locale/LocaleEnum";
import container from "../../container";
import { SliderHomepageItemInterface } from "../../services/Api/DataInterface/SliderHomepageResponseInterface";
import arrayShuffle from "../../services/ArrayShuffle/ArrayShuffle";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { ApplicationState } from "../../store";

interface MainCarouselArrowProps extends ArrowProps {
    isMobile?: boolean;
}

const MainCarouselArrow = styled(Arrow)`
    width: 40px;
    height: 40px;
    z-index: 1;
    background: ${(props: ArrowProps) => `#333 url("/images/main-arrow-${props.isLeft ? "left" : "right"}.jpg") no-repeat center`};
    background-size: 40px;
    ${(props: MainCarouselArrowProps) => (props.isLeft ? `left: 10px;` : `right: 10px`)};
    display: none;
    ${(props: MainCarouselArrowProps) => (props.isMobile ? `display:block;` : `display: none;`)};

    @media ${BreakpointEnum.S} {
        width: 80px;
        height: 80px;
        background-size: 80px;
        top: auto;
        ${(props: MainCarouselArrowProps) => (props.isLeft ? `left: 26px;` : `right: 26px`)};
        ${(props: MainCarouselArrowProps) => (!props.isMobile ? `display: block;` : `display: none;`)};
    }
`;

const MainCarouselSection = styled.section`
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const SlideWrapper = styled.div`
    width: 100%;
    position: relative;
    font-size: calc(28px + var(--font-modificator, 0px));
    height: 480px;
    background-color: var(--image-background-color, none)!important;
    background-blend-mode: var(--image-background-blend-mode, none);

    @media ${BreakpointEnum.XL} {
        height: 500px;
    }

    @media ${BreakpointEnum.XXL} {
        height: 800px;
    }
`;

const SlideTitleContainer = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 0;
    background: linear-gradient(0deg, rgb(0 0 0 / 85%) 0%, rgb(0 0 0 / 0%) 100%);
    min-height: 150px;
    text-align: left;
    padding: 200px 50px 0 50px;
`;

const SlideLink = styled.a`
    width: 100%;
    font-family: "Barlow-Light", sans-serif;
    letter-spacing: 2px;
    color: var(--carousel-font-color, #fff);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    margin-bottom: 20px;
    font-size: calc(37px + var(--font-modificator, 0px));

    @media ${BreakpointEnum.S} {
        padding: 0 90px 0 120px;
    }

    @media ${BreakpointEnum.XL} {
        font-size: calc(42px + var(--font-modificator, 0px));
    }

    @media ${BreakpointEnum.XXL} {
        font-size: calc(47px + var(--font-modificator, 0px));
    }
`;

interface PropsInterface {
    locale: LocaleEnum;
}

const MainCarousel: FC<PropsInterface> = ({ locale }) => {
    const size = useWindowSize();
    const [currentSlide, setCurrentSlide] = useState(0);
    const [animationType, setAnimationType] = useState(window?.outerWidth < BreakpointValuesEnum.MD ? "slide" : "fade");
    const [isMobile, setIsMobileDriver] = useState(window?.outerWidth < BreakpointValuesEnum.MD);
    const [data, setData] = useState<SliderHomepageItemInterface[]>([]);

    useEffect(() => {
        (async () => {
            const data = await container.dynamicModuleApiService.getSliderHomePage(locale);
            const slider: SliderHomepageItemInterface[] = data && data.items.length > 0 ? arrayShuffle(data.items) : [];

            setData(slider);
        })();
    }, [locale]);

    useEffect(() => {
        const { width } = size;

        if (!width) {
            return;
        }

        if (width < BreakpointValuesEnum.MD) {
            setAnimationType("slide");
            setIsMobileDriver(true);
            return;
        }

        setAnimationType("fade");
        setIsMobileDriver(false);
    }, [size.width]);

    return (
        <MainCarouselSection>
            <Carousel
                preventMovementUntilSwipeScrollTolerance={true}
                swipeScrollTolerance={35}
                className={"slider--main"}
                showThumbs={false}
                showIndicators={false}
                showStatus={false}
                showArrows={false}
                infiniteLoop
                selectedItem={currentSlide}
                onChange={setCurrentSlide}
                swipeable={isMobile}
                animationHandler={animationType}
                autoPlay
            >
                {data.map((slide) => (
                    <SlideWrapper
                        key={`mslide${slide.id}`}
                        style={{
                            background: `url(${
                                window?.outerWidth < BreakpointValuesEnum.MD && slide?.slider_duze_mobile.length > 0 ?
                                    locale === LocaleEnum.EN && slide?.slider_duze_en_mobile.length > 0
                                        ? slide.slider_duze_en_mobile[0].url
                                        : slide?.slider_duze_mobile[0].url || "" :
                                    locale === LocaleEnum.EN && slide?.slider_duze_en.length > 0
                                        ? slide.slider_duze_en[0].url
                                        : slide?.slider_duze[0].url || ""
                            }) no-repeat center / cover`,
                        }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <MainCarouselArrow className="mobile" onClick={() => setCurrentSlide(currentSlide - 1)} isLeft isMobile />
                        <SlideTitleContainer onClick={(e) => e.stopPropagation()}>
                            <Container>
                                <MainCarouselArrow onClick={() => setCurrentSlide(currentSlide - 1)} isLeft />
                                <SlideLink href={slide?.url || ""}>{slide?.title || ""}</SlideLink>
                                <MainCarouselArrow onClick={() => setCurrentSlide(currentSlide + 1)} isLeft={false} />
                            </Container>
                        </SlideTitleContainer>
                        <MainCarouselArrow className="mobile" onClick={() => setCurrentSlide(currentSlide + 1)} isLeft={false} isMobile />
                    </SlideWrapper>
                ))}
            </Carousel>
        </MainCarouselSection>
    );
};

MainCarousel.propTypes = {
    locale: PropTypes.oneOf([LocaleEnum.PL, LocaleEnum.EN]).isRequired,
};

const mapStateToProps = (state: ApplicationState) => ({
    locale: state.locale.locale,
});

export default connect(mapStateToProps)(MainCarousel);
