import React, { FC } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Footer from "../../components/Footer";
import Menu from "../../components/Menu/Menu";

interface PropsInterface {
    children: React.ReactNode | React.ReactNodeArray;
}

const LayoutPage: FC<PropsInterface> = (props) => {

    return (
        <>
            <Menu />
            {props.children}
            <Footer />
        </>
    );
};

LayoutPage.propTypes = {
    children: PropTypes.object,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(LayoutPage);
