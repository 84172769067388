import {
    ContrastActionTypesEnum,
    ContrastActionTypes,
    ContrastStateInterface
} from "./ContrastTypes";
import { ThemeEnum } from "../../../../shared/enums/ThemeEnum/ThemeEnum";

const initialState: ContrastStateInterface = {
    contrast: ThemeEnum.Default,
};

const contrastReducer = function (state = initialState, action: ContrastActionTypes): ContrastStateInterface {
    switch (action.type) {
        case ContrastActionTypesEnum.CHANGE_CONTRAST:
            return {
                ...state,
                contrast: action.contrast
            };
        default:
            return state;
    }
};

export default contrastReducer;
