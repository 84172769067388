import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import BreakpointEnum from "../../shared/enums/BreakpointEnum/BreakpointEnum";
import { useLocation } from "react-router-dom";
import menuStructure from "../Menu/menuStructure";
import container from "../../container";
import { SliderOtherPageInterface } from "../../services/Api/DataInterface/SliderOtherPageInterface";
import arrayShuffle from "../../services/ArrayShuffle/ArrayShuffle";
import { GetListMenuResponseInterface } from "../../services/Api/DataInterface/GetListMenuResponseInterface";

const StyledHeaderWrapper = styled.header`
    width: 100%;
    margin: auto;
    display: flex;

    @media ${BreakpointEnum.S} {
    }
`;

const HeaderBackground = styled.div`
    height: 500px;
    width: 100%;
    background-color: var(--image-background-color, none)!important;
    background-blend-mode: var(--image-background-blend-mode, none);

    ${(props: { mobileBackground?: string; desktopBackground: string }) => !props.desktopBackground && `display:none; `}

    ${(props: { mobileBackground?: string; desktopBackground: string }) =>
        props.desktopBackground &&
        `background: url(${props.mobileBackground ? props.mobileBackground : props.desktopBackground}) no-repeat center center;
             background-size: cover;
             `} 
    
    // defeault bg from 720px
    @media ${BreakpointEnum.S} {
        ${(props: { mobileBackground?: string; desktopBackground: string }) =>
            props.desktopBackground && `background: url(${props.desktopBackground}) no-repeat center center;`}
    }
`;

const getDataForBreadcrumbs = (
    menu: GetListMenuResponseInterface[],
    activeElements: GetListMenuResponseInterface[],
    pathname: string,
    greatestParent: GetListMenuResponseInterface | null = null
): GetListMenuResponseInterface | null => {
    for (const item of menu) {
        const currentGreatestParent = greatestParent ?? item;

        if (item.routes && ((item.routes.pl && item.routes.pl === pathname) || (item.routes.en && item.routes.en === pathname))) {
            activeElements.push(item);
            return currentGreatestParent;
        }

        if (!item.children) {
            continue;
        }

        const foundParent = getDataForBreadcrumbs(item.children, activeElements, pathname, currentGreatestParent);
        if (foundParent) {
            activeElements.push(item);
            return foundParent;
        }
    }

    return null;
};

const getSliderByCategory = (data: SliderOtherPageInterface[], categoryName: string | null) => {
    for (const item of data) {
        if (categoryName && item.category_page && item.category_page === categoryName) {
            return item;
        }

        if (!categoryName) {
            return item;
        }
    }

    return null;
};

export const DetailHeader: FC = () => {
    const [data, setData] = useState<SliderOtherPageInterface | null>(null);
    const location = useLocation();
    const breadcrumbsElement: GetListMenuResponseInterface[] = [];
    const greatestParent = getDataForBreadcrumbs(menuStructure, breadcrumbsElement, location.pathname);
    const categoryName = greatestParent?.categoryName ?? null;
    
    useEffect(() => {
        (async () => {
            const data = await container.dynamicModuleApiService.getSliderOtherPage();
            const dataSlider = getSliderByCategory(data.items, categoryName);
            if (dataSlider) {
                setData(dataSlider);
                return;
            }
            setData(getSliderByCategory(data.items, null));
        })();
    }, [categoryName]);

    if (data) {
        let slider = data && data.slider_male.length > 0 ? arrayShuffle(data.slider_male) : "";
        let sliderMobile = data && data.slider_male_mobile.length > 0 ? arrayShuffle(data.slider_male_mobile) : "";
        if(location.pathname.includes('en/')) {
            slider = data && data.slider_male_en.length > 0 ? arrayShuffle(data.slider_male_en) : "";
            sliderMobile = data && data.slider_male_en_mobile.length > 0 ? arrayShuffle(data.slider_male_en_mobile) : "";
        }

        return (
            <StyledHeaderWrapper>
                <HeaderBackground
                    mobileBackground={sliderMobile && sliderMobile.length > 0 ? sliderMobile[0].url : ""}
                    desktopBackground={slider && slider.length > 0 ? slider[0].url : ""}
                />
            </StyledHeaderWrapper>
        );
    }

    return <></>;
};

DetailHeader.propTypes = {};

DetailHeader.defaultProps = {};

export default DetailHeader;
