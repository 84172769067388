import { Action } from "redux";

export enum MenuActionTypesEnum {
    CHANGE_MENU = "CHANGE_MENU",
}

export interface MenuStateInterface {
    activeElement: number | boolean;
    isOpenMenu: boolean;
}

export interface ChangeMenuActionInterface extends Action<MenuActionTypesEnum.CHANGE_MENU> {
    activeElement: number | boolean;
    isOpenMenu: boolean;
}
