import React, { FC, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DetailHeader from "../../components/DetailHeader/DetailHeader";
import DetailContent from "../../components/DetailContent/DetailContent";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import DetailHeaderText from "../../components/DetailHeader/DetailHeaderText";
import container from "../../container";
import LocaleEnum from "../../services/Locale/enums/Locale/LocaleEnum";
import { ApplicationState } from "../../store";
import { PageStaticItemInterface } from "../../services/Api/DataInterface/PageStaticResponseInterface";
import Gallery from "../../components/Gallery/GalleryAdaptive";

interface PropsInterface {
    pageId: number;
    locale: LocaleEnum;
}

const PageStatic: FC<PropsInterface> = ({ pageId, locale }) => {
    const [data, setData] = useState<PageStaticItemInterface | null>(null);

    useEffect(() => {
        (async () => {
            const data = await container.dynamicModuleApiService.getStatisticPageById(locale, pageId );

            if (data.data) {
                setData(data.data);
            }
        })();
    }, [locale, pageId]);

    if (!data) {
        return null;
    }

    return (
        <>
            <DetailHeader />
            <Breadcrumbs />
            <DetailHeaderText />
            <DetailContent content={data.description} />
            {data.gallery && data.gallery.length > 0 && <Gallery images={data.gallery} />}
        </>
    );
};

PageStatic.propTypes = {
    pageId: PropTypes.number.isRequired,
    locale: PropTypes.oneOf([LocaleEnum.PL, LocaleEnum.EN]).isRequired,
};

const mapStateToProps = (state: ApplicationState) => ({
    locale: state.locale.locale,
});

export default connect(mapStateToProps)(PageStatic);
