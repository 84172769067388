import styled from "styled-components";
import BreakpointEnum from "../../shared/enums/BreakpointEnum/BreakpointEnum";
import { Link } from "react-router-dom";

export const SlideTitle = styled.div`
    width: 100%;
    font-size: calc(21px + var(--font-modificator, 0px));
    color: var(--font-dark, #000);
    font-weight: 600;
    font-family: "Barlow", sans-serif;
    text-align: left;
    margin-top: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 20px;

    @media ${BreakpointEnum.XS} {
        font-size: calc(19px + var(--font-modificator, 0px));
        height: calc(50px + var(--font-modificator, 0px));
    }

    @media ${BreakpointEnum.MD} {
        font-size: calc(17px + var(--font-modificator, 0px));
        height: calc(48px + var(--font-modificator, 0px));
    }
`;

export const SlideLead = styled.div`
    width: 100%;
    font-size: calc(15px + var(--font-modificator, 0px));
    line-height: 1.2;
    color: var(--font-dark, #333);
    font-weight: 400;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    max-height: calc(3.6rem + var(--font-modificator, 0px));
    
    em {
        font-size: calc(15px + var(--font-modificator, 0px));
        line-height: 1.2;
        color: #333;
        font-weight: 400;
        background: none;
    }
`;

export const NewsListSection = styled.section`
    background: var(--background-color, #FFF);
    position: relative;
    padding-top: 50px;
    padding-bottom: 20px;
    overflow: hidden;
`;

export const SlideRow = styled.div`
    height: 100%;
    flex-direction: row;
    display: flex;
    column-gap: 0;
    padding: 0 15px;

    @media ${BreakpointEnum.XS} {
        column-gap: 15px;
        padding: 0 28px;
    }
    @media ${BreakpointEnum.S} {
        padding: 0;
    }

    @media ${BreakpointEnum.MD} {
        column-gap: 20px;
    }

    @media ${BreakpointEnum.XXL} {
        column-gap: 30px;
    }
`;

export const SlideItem = styled(Link)`
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative;
    max-width: 380px;
    margin: auto;
    cursor: default;

    @media ${BreakpointEnum.S} {
        width: 50%;
        margin: 0;
    }

    @media ${BreakpointEnum.MD} {
        width: 25%;
    }

    ${(props: {isEmpty: boolean}) =>
            !props.isEmpty &&
            `
        cursor: pointer;
        
    :before {
        position: absolute;
        content: '';
        background: var(--main-red, red);
        z-index: -1;
        bottom: 0;
        right: 0;
        margin: auto;
        opacity: 0;
        transition: opacity .2s;
        
        @media ${BreakpointEnum.MD} {
            height: calc(100% + 30px);
            width: calc(100% + 20px);
            left: -10px;
            top: 9px;
        }

        @media ${BreakpointEnum.XXL} {
            height: calc(100% + 60px);
            width: calc(100% + 40px);
            left: -20px;
            top: 15px;
        }
    }

    &:hover:before {
        @media ${BreakpointEnum.MD} {
            opacity: 1;
        }
    }

    &:hover {
        @media ${BreakpointEnum.MD} {
            ${SlideLead} {
                color: var(--font-light, #fff);
            }

            ${SlideTitle} {
                color: var(--font-light, #fff);
            }
        }
        
        a {
            color: var(--font-dark, #000);
        }
    }
    `}
`;

export const ImageContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;

export const NewsImage = styled.img`
    height: 220px;
    display: inline-flex;
    margin: auto;

    @media ${BreakpointEnum.XS} {
        width: 50%;
    }

    @media ${BreakpointEnum.MD} {
        width: 25%;
        height: 160px;
    }

    @media ${BreakpointEnum.XXL} {
        height: 220px;
    }
`;

export const DateContainer = styled.div`
    position: absolute;
    left: -2px;
    bottom: -2px;
    display: inline-flex;
    background: url("/images/date-shape.png") no-repeat center;
    background-color: var(--image-background-color, none)!important;
    background-blend-mode: var(--image-background-blend-mode, none);
    width: 75px;
    height: 75px;
    flex-direction: column;
    color: var(--font-light, #fff);
    justify-content: center;
`;

export const DateDay = styled.div`
    font-size: calc(32px + var(--font-modificator, 0px));
    line-height: 1.25;
    font-family: "Barlow Bold", sans-serif;
    text-align: center;
    width: 100%;
`;

export const DateMonth = styled.div`
    font-size: calc(14px + var(--font-modificator, 0px));
    text-align: center;
    width: 100%;
`;
