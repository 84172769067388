import React, { FC } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { injectIntl, WrappedComponentProps } from "react-intl";

interface ButtonProps extends WrappedComponentProps {
    href?: string;
    title?: string;
    target?: string;
}

const ButtonIco = styled.img`
    display: inline-flex;
    margin: auto 0 auto auto;
    height: 15px;
    width: 9px !important;
`;

const ButtonLink = styled.a`
    padding: 18px 25px;
    background: var(--button-background-color, #a71931);
    font-size: calc(18px + var(--font-modificator, 0px));
    color: #fff;
    display: flex;
    width: 220px;
    margin-top: 40px;
    text-transform: uppercase;
`;

const Button: FC<ButtonProps> = (props) => {
    const { href, title, target } = props;

    return (
        <ButtonLink href={href} title={title} target={target}>
            {props.intl.formatMessage({ id: "more" })} <ButtonIco src="/images/button-arrow.png" title="btn-arrow" />
        </ButtonLink>
    );
};

Button.propTypes = {
    href: PropTypes.string,
    title: PropTypes.string,
    target: PropTypes.string,
    intl: PropTypes.any.isRequired,
};

Button.defaultProps = {
    href: "#",
    title: "",
    target: "_self",
};

export default injectIntl(Button);
