import { createGlobalStyle } from "styled-components";
import { ThemeEnum, FontThemeEnum } from "../enums/ThemeEnum/ThemeEnum";

export const Palette = createGlobalStyle`
    /* default */
    :root {
        --main-blue: #00305c;
        --main-red: #a71931;
        --main-green: #017f01;
        --main-green-hover: #00B900;
        --main-bg-light: #fff;
        --main-grey: #ccc;
        --font-dark: #00305c;
        --font-light: #fff;
        --font-light-hover: #c9c9c9;
        --font-hover: #2263a1;
        --font-detail-content-color: #020202;
        --anchor-color: #bb2525;
        
        --vertical-menu-max-height: 6vw;
        
        --font-modificator: 0px;
        --background-color: #FFF;
        --button-background-color: #a71931;
        
    }
    
    html.theme--${ThemeEnum.Contrast} {
        --main-blue: #000;
        --main-red: #141414;
        --main-green: #000;
        --main-green-hover: #000;
        --main-bg-light: #ff0;
        --main-grey: #ff0;
        --font-dark: #ff0;
        --font-light: #ff0;
        --font-light-hover: #ff0;
        --font-hover: #ff0;
        --font-detail-content-color: #ff0;
        --anchor-color: #bb2525;
        
        --contrast-yellow: #ff0;
        
        --background-color: #000;
        --breadcrumbs-font-color: #000;
        --imgage-grayscale-color: grayscale(100%);
        --image-background-color: black;
        --image-background-blend-mode: luminosity;
        --button-background-color: #141414;
    }

    html.theme--${ThemeEnum.Contrast2} {
        --main-blue: #ff0;
        --main-red: #fff;
        --main-green: #ff0;
        --main-green-hover: #ff0;
        --main-bg-light: #000;
        --main-grey: #000;
        --font-dark: #000;
        --font-light: #000;
        --font-light-hover: #000;
        --font-hover: #000;
        --font-detail-content-color: #000;
        
        --contrast-yellow: #000;
        
        --background-color: #ff0;
        --breadcrumbs-font-color: #ff0;
        --imgage-grayscale-color: grayscale(100%);
        --image-background-color: #ff0;
        --image-background-blend-mode: luminosity;
        --carousel-font-color: #ff0;
        --button-background-color: #000;
    }

    html.theme--${ThemeEnum.Contrast3} {
        --main-blue: pink;
        --main-red: pink;
        --font-dark: green;
        --main-grey: green;
        --secondary-red: pink;
        --font-light: green;
        --font-detail-content-color: green;
        --font-light-hover: #fff;
    }

    html.theme--${ThemeEnum.Contrast4} {
        --main-blue: teal;
        --main-red: teal;
        --font-dark: gold;
        --main-grey: gold;
        --secondary-red: teal;
        --font-light: gold;
        --font-detail-content-color: gold;
        --font-light-hover: #000;
    }
    
    html.font-size--${FontThemeEnum.Medium} {
        --font-modificator: 4px;
    }

    html.font-size--${FontThemeEnum.Big} {
        --font-modificator: 12px;
    }
`;
