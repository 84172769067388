import React, { FC, useEffect } from "react";
import { injectIntl } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Divide as Hamburger } from "hamburger-react";
import FullscreenMenu from "../components/FullscreenMenu/FullscreenMenu";
import TopBar from "../components/TopBar/TopBar";
import { ApplicationState } from "../../../store";
import { connect } from "react-redux";
import { changeMenuActions } from "../../../services/Locale/store/Menu/MenuActions";
import {ThemeEnum, FontThemeEnum} from "../../../shared/enums/ThemeEnum/ThemeEnum";
import FontSizeController from "../components/TopBar/components/ThemeControllers/components/FontSizeController";
import ThemeColorsController from "../components/TopBar/components/ThemeControllers/components/ThemeColorsController";
import { GetListMenuResponseInterface } from "../../../services/Api/DataInterface/GetListMenuResponseInterface";
interface MenuMobileProps {
    mainMenuItems: GetListMenuResponseInterface[] | null;
    changeMenuActions: typeof changeMenuActions;
    activeElement: boolean | number;
    isOpenMenu: boolean;
    intl: any;
    contrast: ThemeEnum;
}

const MenuMobileComponent = styled.div`
    width: 100%;
    display: flex;
    background: var(--main-blue, #00305c);
    color: var(--font-light, #fff);
    position: relative;
    min-height: 48px;

    .hamburger-react {
        z-index: 99;
        margin-left: auto;
        ${(props: { isOpenMenu?: boolean }) =>
            props.isOpenMenu &&
            `
             position: fixed !important;
             right: 0;
             
        `}

        @media (max-width: 360px) {
            margin-right: 0;
        }
    }
`;

const MobileLogosWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px 0 0;
`;
const MobileLogosRow = styled.div`
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: start;

    a:first-of-type {
        margin-right: 2vw;
    }

    img {
        max-width: 100%;
    }
`;

const StyledWimirName = styled.div`
    font-size: calc(23px + var(--font-modificator, 0px));
    color: var(--font-dark, #00305c);
    width: 90%;
    margin: auto;
    padding: 15px 8px;
`;

const ControllersRow = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 0 0 0 5%;
`;

const MenuMobile: FC<MenuMobileProps> = ({ mainMenuItems, activeElement, changeMenuActions, isOpenMenu, intl, contrast }) => {
    const hamburgerExitColor = contrast !== ThemeEnum.Contrast2 ? '#FFF' : '#000'
    
    useEffect(() => {
        if (activeElement) {
            window.document.body.style.overflow = "hidden";
        } else {
            window.document.body.style.overflow = "auto";
        }
    }, [activeElement]);

    const onToggle = () => {
        if (isOpenMenu) {
            changeMenuActions(false, false)
        } else {
            changeMenuActions(activeElement, true)
        }
    };

    return (
        <>
            <MenuMobileComponent isOpenMenu={isOpenMenu}>
                {!isOpenMenu && 
                    <ControllersRow>
                        <FontSizeController label={"A"} supLabel={""} fontSizeModificator={""} />
                        <FontSizeController label={"A"} supLabel={"+"} fontSizeModificator={FontThemeEnum.Medium} />
                        <FontSizeController label={"A"} supLabel={"++"} fontSizeModificator={FontThemeEnum.Big} />

                        <ThemeColorsController borderColor={"white"} textColor={"white"} backgroundColor={"#00305c"} themeName={ThemeEnum.Default} />
                        <ThemeColorsController borderColor={"yellow"} textColor={"yellow"} backgroundColor={"black"} themeName={ThemeEnum.Contrast} />
                        <ThemeColorsController borderColor={"black"} textColor={"black"} backgroundColor={"yellow"} themeName={ThemeEnum.Contrast2} />
                    </ControllersRow>
                }
                {!isOpenMenu && <Hamburger toggled={isOpenMenu} toggle={onToggle} color={hamburgerExitColor} direction={"right"} />}
                <FullscreenMenu isOpen={isOpenMenu} mainMenuItems={mainMenuItems} activeElementIndex={activeElement} closeFullScreenMenu={onToggle} />
            </MenuMobileComponent>
            <TopBar isMenuOpen={isOpenMenu} mainMenuItems={mainMenuItems} />
            <MobileLogosWrapper>
                <MobileLogosRow>
                    <a href="//www.imir.agh.edu.pl/" target="_blank" rel={"noreferrer"}>
                        <img src={"/images/logo_agh.png"} alt={"agh-logo"} />
                    </a>
                    <a href="/" target="_blank" rel={"noreferrer"}>
                        <img src={"/images/logo_wimir.png"} alt={"wimir-logo"} />
                    </a>
                </MobileLogosRow>
                <StyledWimirName>{intl.formatMessage({ id: "logo.descWIMIR" })}</StyledWimirName>
            </MobileLogosWrapper>
        </>
    );
};

MenuMobile.propTypes = {
    mainMenuItems: PropTypes.array,
    changeMenuActions: PropTypes.func.isRequired,
    activeElement: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
    isOpenMenu: PropTypes.bool.isRequired,
    intl: PropTypes.any.isRequired,
    contrast: PropTypes.any
};

const mapStateToProps = (state: ApplicationState) => ({
    activeElement: state.menu.activeElement,
    isOpenMenu: state.menu.isOpenMenu,
    contrast: state.contrast.contrast,
});

export default connect(mapStateToProps, { changeMenuActions })(injectIntl(MenuMobile));
