import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";

const ErrorContentContainer = styled.div`
    height: 850px;
    max-width: 500px;
    padding: 30px 20px;
    margin: 0px auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 2rem;
`;
const ErrorContentContent = styled.div`
    margin-top: 100px;
    text-align: center;
    font-size: 3rem;
`;

interface PropsInterface {
    content: string;
    backUrl?: string;
    backLabel?: string;
}

const ErrorContent: React.FC<PropsInterface> = ({ content, backLabel, backUrl }) => {
    return (
        <ErrorContentContainer>
            <ErrorContentContent>
                <img src={"/images/404.png"} />
                {content}
            </ErrorContentContent>
            {backLabel && <Link to={backUrl || "/"}>{backLabel}</Link>}
        </ErrorContentContainer>
    );
};

ErrorContent.propTypes = {
    content: PropTypes.string.isRequired,
    backLabel: PropTypes.string,
    backUrl: PropTypes.string,
};

export default ErrorContent;
