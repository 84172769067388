import React, { FC } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {injectIntl, WrappedComponentProps} from "react-intl";
import Container from "../Container";
import BreakpointEnum from "../../shared/enums/BreakpointEnum/BreakpointEnum";
import LocaleEnum from "../../services/Locale/enums/Locale/LocaleEnum";
import {ApplicationState} from "../../store";
import RoutesPath from "../../shared/enums/RoutesPath";
import {ThemeEnum} from "../../shared/enums/ThemeEnum/ThemeEnum";


const StyledFooter = styled.footer`
    width: 100%;
    padding: 15px;
    background: var(--main-blue, #000);
    color: var(--font-light, #000);
    font-size: calc(16px + var(--font-modificator, 0px));
    display: flex;
    flex-wrap: wrap;
    font-family: "Barlow-Light", sans-serif;

    @media ${BreakpointEnum.S} {
        padding: 20px 15px;
        font-size: calc(18px + var(--font-modificator, 0px));
    }
    @media ${BreakpointEnum.MD} {
        padding: 35px 15px 110px;
        font-size: calc(20px + var(--font-modificator, 0px));
    }
    a {
        color: var(--font-light);

        &:hover {
            color: var(--anchor-color);
        }
    }
`;

const SocialIco = styled.img`
    height: 30px;
    display: inline-flex;
    margin-right: 15px;
`;

const AmuIco = styled.img`
    position: absolute;
    right: 0;
    bottom: 0;
    width: 27px;
`;

const TextBlocksWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
`;

const TextBlock = styled.div`
    padding-top: 35px;
    max-width: 380px;
    margin-right: 172px;
    @media (max-width: 960px) {
        max-width: 100%;
        width: 100%;
    }
    
`;

const PlaceContact = styled.a`
    margin-top: 20px;
    img {
        width: 100px;
    }
`
const AccessibilityDeclaration = styled.a`
    color: var(--font-light, #000);
    margin: 98px auto 0;
`

const Separator = styled.hr`
    margin: 15px 0 0;
    border-color: var(--contrast-yellow, #2169ab);
`;


interface PropsInterface extends WrappedComponentProps {
    locale: LocaleEnum;
    contrast: ThemeEnum;
}

const Footer: FC<PropsInterface> = ({ locale, intl, contrast }) => {
    const adressContent = {
        pl: {
            content:
                `Akademia Górniczo-Hutnicza im. Stanisława Staszica w Krakowie<br/>
                Wydział Inżynierii Mechanicznej i Robotyki <br/>
                al. Mickiewicza 30 <br/>
                30-059 Kraków`,
            contact:
                `tel: 48 12 617 30 50<br/>
                fax: 48 12 633 91 03<br/>
                e-mail: <a href="mailto:dziekanat@imir.agh.edu.pl">dziekanat@imir.agh.edu.pl</a>`,
        },
        en:
            {
                content:
                `AGH University of Science and Technology in Krakow<br/>
                Faculty of Mechanical Engineering and Robotics<br/>
                30 Mickiewicza Avenue<br/>
                30-059 Krakow`,
                contact:
                    `phone: 48 12 617 30 50<br/>
                fax: 48 12 633 91 03<br/>
                e-mail: <a href="mailto:dziekanat@imir.agh.edu.pl">dziekanat@imir.agh.edu.pl</a>`,
            }
    }

    return (
        <StyledFooter>
            <Container>
                <>
                    <a href={"https://www.facebook.com/WIMiR.AGH/"} target={"_blank"} rel={"noreferrer"}>
                        <SocialIco src={"/images/logo_fb.png"} />
                    </a>
                    <a href={"https://www.youtube.com/user/tvagh"} target={"_blank"} rel={"noreferrer"}>
                        <SocialIco src={"/images/logo_youtube.png"} />
                    </a>
                    <a href={"https://www.instagram.com/agh_wimir/"} target={"_blank"} rel={"noreferrer"}>
                        <SocialIco src={"/images/logo_insta.svg"} />
                    </a>
                    <a href={"https://www.linkedin.com/company/agh-wimir/"} target={"_blank"} rel={"noreferrer"}>
                        <SocialIco src={"/images/logo_linkedin.svg"} />
                    </a>
                    <Separator />
                    <TextBlocksWrapper>
                        <TextBlock dangerouslySetInnerHTML={{ __html: adressContent[locale].content }} />
                        <TextBlock dangerouslySetInnerHTML={{ __html: adressContent[locale].contact }} />
                        <PlaceContact href={RoutesPath.CONTACT[locale]}>
                            <img src={`/images/contrast/${contrast}/place.png`} alt="Kontakt"/>
                        </PlaceContact>
                        <AccessibilityDeclaration href={RoutesPath.ACCESSIBILITY_DECLARATION[locale]}>
                            {intl.formatMessage({ id: "footer.accessibility.statement" })}
                        </AccessibilityDeclaration>
                        <a href="https://www.amu.pl/" rel="noreferrer" target="_blank">
                            <AmuIco src={"/images/logo_amu.png"} alt={"amu"} />
                        </a>
                    </TextBlocksWrapper>
                </>
            </Container>
        </StyledFooter>
    );
};

Footer.propTypes = {
    locale: PropTypes.oneOf([LocaleEnum.PL, LocaleEnum.EN]).isRequired,
    intl: PropTypes.any.isRequired,
};

const mapStateToProps = (state: ApplicationState) => ({
    locale: state.locale.locale,
    contrast: state.contrast.contrast,
});

export default connect(mapStateToProps)(injectIntl(Footer));
