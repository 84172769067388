import RoutesPathInterface from "./RoutesPathInterface";

const RoutesPath: RoutesPathInterface = {
    HOMEPAGE: { pl: "/", en: "/en/" },
    NEWS: { pl: "/pl/studenci/aktualnosci-ogloszenia" },
    ABOUT_DEOARTMENT: { pl: "/pl/wydzial/o_wydziale/", en: "/en/faculty/the_faculty/" },
    DEAN: { pl: "/pl/wydzial/wladze_wydzialu/dziekan/", en: "/en/faculty/authorities_of_faculty/dean/" },
    ELECTIONS: { pl: "/pl/wydzial/wladze_wydzialu/wybory-2024/" },
    DEPUTY_DEANS: { pl: "/pl/wydzial/wladze_wydzialu/prodziekani/", en: "/en/faculty/authorities_of_faculty/deputy_deans/" },
    PLENIPOTENTIARY: { pl: "/pl/wydzial/wladze_wydzialu/koordynatorzy/", en: "/en/faculty/authorities_of_faculty/plenipotentiary_/" },
    ADMINISTRATION: { pl: "/pl/wydzial/wladze_wydzialu/biuro_administracyjne_wydzialu/", en: "/en/faculty/authorities_of_faculty/administration/" },
    DEANS_OFFICE: { pl: "/pl/wydzial/wladze_wydzialu/dziekanat/", en: "/en/faculty/authorities_of_faculty/deans_office/" },
    COUNCIL_OF_THE_FACULTY: {
        pl: "/pl/wydzial/wladze_wydzialu/kolegium_wydzialowe/",
        en: "/en/faculty/authorities_of_faculty/council_of_the_faculty/",
    },
    FACULTY_TEAMS: {
        pl: "/pl/wydzial/wladze_wydzialu/zespoly_wydzialowe/",
        en: "/en/faculty/authorities_of_faculty/faculty_teams/"
    },
    SUPERVISORS_OF_COURSES_AND_SPECIALTIES: {
        pl: "/pl/wydzial/wladze_wydzialu/opiekunowie-kierunkow-i-specjalnosci/",
        en: "/en/faculty/authorities_of_faculty/supervisors-of-courses-and-specialties/"
    },
    DEPARTMENTS: { pl: "/pl/wydzial/katedry/", en: "/en/faculty/departments/" },
    FACULTYS_HISTORY: { pl: "/pl/wydzial/historia_wydzialu/historia_wydzialu/", en: "/en/faculty/history/the_facultys_history/" },
    DEANS: { pl: "/pl/wydzial/historia_wydzialu/dziekani/", en: "/en/faculty/history/deans/" },
    IMPORTANTS_EVENTS: { pl: "/pl/wydzial/historia_wydzialu/wazniejsze_wydarzenia/", en: "/en/faculty/history/important_events/" },
    MENU_TEAM: { pl: "/pl/wydzial/rada_dyscypliny/sklad/" },
    DOCTORATES: {
        pl: "https://www.agh.edu.pl/biuletyn-informacji-publicznej/stopnie-naukowe/postepowania-w-sprawie-nadanie-stopnia-dr/inzynieria-mechaniczna-dziedzina-nauk-inzynieryjno-technicznych/",
    },
    HABILITATION: {
        pl: "https://www.agh.edu.pl/biuletyn-informacji-publicznej/stopnie-naukowe/postepowania-w-sprawie-nadanie-stopnia-dr-hab/inzynieria-mechaniczna-dziedzina-nauk-inzynieryjno-technicznych/",
    },
    QUALIFICATION_RULES: { pl: "/pl/wydzial/rada_dyscypliny/zasady_kwalifikacji_do_rnn/" },
    PUBLIC_COUNCIL_OF_FACULTY: { pl: "/pl/wydzial/rada_spoleczna_wimir/", en: "/en/faculty/the_public_council_of_faculty/" },
    QUALITY_EDUCATION: { pl: "/pl/wydzial/jakosc_ksztalcenia/" },
    QUALITY_EDUCATION_TEAM: { pl: "/pl/wydzial/jakosc_ksztalcenia/wydzialowy_zespol_ds__jakosci_ksztalcenia/" },
    QUALITY_EDUCATION_TEAM_AUDIT: { pl: "/pl/wydzial/jakosc_ksztalcenia/wydzialowy_zespol_audytu_dydaktycznego/" },
    QUALITY_EDUCATION_WIMIR_AUDIT: { pl: "/pl/wydzial/jakosc_ksztalcenia/audyt_wimir/" },
    QUALITY_EDUCATION_ACCREDITATION: { pl: "/pl/wydzial/jakosc_ksztalcenia/akredytacje/" },
    QUALITY_EDUCATION_ACCREDITATION_KAUT_MIBM: { pl: "/pl/wydzial/jakosc_ksztalcenia/akredytacje/akredytacja_kaut-mibm/" },
    QUALITY_EDUCATION_ACCREDITATION_KAUT_AIR: { pl: "/pl/wydzial/jakosc_ksztalcenia/akredytacje/akredytacja_kaut-air/" },
    QUALITY_EDUCATION_DISTINCTION: { pl: "/pl/wydzial/jakosc_ksztalcenia/akredytacje/wyroznienie_pka_dla_mibm/" },
    QUALITY_EDUCATION_ACCREDITATION_AIR: { pl: "/pl/wydzial/jakosc_ksztalcenia/akredytacje/akredytacja_pka_dla_air/" },
    QUALITY_EDUCATION_RANKING: { pl: "/pl/wydzial/jakosc_ksztalcenia/ranking_perspektywy/" },
    QUALITY_EDUCATION_2021: { pl: "/pl/wydzial/jakosc_ksztalcenia/mt_i_miejsce_w_rankingu_perspektywy_2021/" },
    QUALITY_EDUCATION_2020: { pl: "/pl/wydzial/jakosc_ksztalcenia/mt_i_miejsce_w_rankingu_perspektywy_2020/" },
    QUALITY_EDUCATION_2019: { pl: "/pl/wydzial/jakosc_ksztalcenia/mibm_mt_i_miejsce_w_rankingu_perspektywy_2019/" },
    QUALITY_EDUCATION_2018: { pl: "/pl/wydzial/jakosc_ksztalcenia/mibm_air_mt_i_miejsce_w_rankingu_perspektywy_2018/" },
    QUALITY_EDUCATION_2017: { pl: "/pl/wydzial/jakosc_ksztalcenia/mibm_i_miejsce_w_rankingu_perspektywy_2017/" },
    QUALITY_EDUCATION_2016: { pl: "/pl/wydzial/jakosc_ksztalcenia/mibm_air_mt_i_miejsce_w_rankingu_perspektywy_2016/" },
    QUALITY_EDUCATION_2015: { pl: "/pl/wydzial/jakosc_ksztalcenia/mibm_i_miejsce_w_rankingu_perspektywy_2015/" },
    QUALITY_EDUCATION_2014: { pl: "/pl/wydzial/jakosc_ksztalcenia/mibm_i_miejsce_w_rankingu_perspektywy_2014/" },
    SURVEY_RESULTS: { pl: "/pl/wydzial/jakosc_ksztalcenia/wyniki_ankiet_studenckich/" },
    SURVEY_RESULTS_DOCTORAL: { pl: "/pl/wydzial/jakosc_ksztalcenia/wyniki_ankiet_doktoranckich/" },
    DOCUMENT_TEMPLATES: { pl: "/pl/wydzial/dla_pracownikow/wzory_dokumentow/" },
    SUCCESS: { pl: "/pl/wydzial/dla_pracownikow/nagrody_i_odznaczenia/" },
    FACULTY_ADMINISTRATION_OFFICE: { pl: "/pl/wydzial/biuro_administracyjne_wydzialu_-_informacje/" },
    FROM_LIFE_OF_THE_FACULTY: { pl: "/pl/wydzial/z_zycia_wydzialu/" },
    CONTACT: { pl: "/pl/wydzial/kontakt/", en: "/en/faculty/contact/" },
    ACCESSIBILITY_DECLARATION: { pl: "/pl/wydzial/deklaracja_dostepnosci/", en: "/en/faculty/accessibility-statement/" },
    DECISIONS_DEANS: { pl: "/pl/dzialalnosc_naukowa/decyzje_dziekana/" },
    COMMUNICATION_VIDEO_DEAN: { pl: "/pl/dzialalnosc_naukowa/komunikaty_prodziekana_ds_nauki/" },
    SCIENTIFIC_AND_RESEARCH_OFFER: { pl: "/pl/dzialalnosc_naukowa/oferta_naukowo_badawcza/" },
    CATEGORIZATION_MINISTRY_2017: {
        pl: "/pl/dzialalnosc_naukowa/kategoryzacja_mnisw_2017/",
        en: "/en/research/categorization_of_the_ministry_2017/",
    },
    PUBLICATIONS: { pl: "https://www.bpp.agh.edu.pl/", en: "https://www.bpp.agh.edu.pl/" },
    LABORATORIES: {
        pl: "https://www.uci.agh.edu.pl/laboratoria/?wydzial=3&#160;rodzaj=3",
        en: "https://www.uci.agh.edu.pl/laboratoria/?wydzial=3&#160;rodzaj=3",
    },
    FOUND_UE: { pl: "/pl/dzialalnosc_naukowa/projekty_wspolfinansowane_z_funduszy_ue/" },
    IMPLEMENTATION_DOCTORATE: { pl: "/pl/dzialalnosc_naukowa/program_doktorat_wdrozeniowy/" },
    YOUNG_RESEARCHERS_WIMIR: { pl: "/pl/dzialalnosc_naukowa/kola_naukowe_wimir/" },
    PHD_STUDENTS: { pl: "/pl/dzialalnosc_naukowa/doktoranci/", en: "/en/research/phd_students/" },
    STATIONARY_1_CLASS: { pl: "/pl/studia/stacjonarne_i-st/", en: "/en/education_offer/1st_cycle_of_studies/" },
    STATIONARY_2_CLASS: { pl: "/pl/studia/stacjonarne_ii-st/", en: "/en/education_offer/2nd_cycle_of_studies/" },
    STATIONARY_3_CLASS: { pl: "/pl/studia/studia_iii-o_stopnia_-_doktoranckie/", en: "/en/education_offer/3rd_cycle_of_studies_-_doctoral/" },
    PART_TIME_STUDIES: { pl: "/pl/studia/niestacjonarne/", en: "/en/education_offer/non_stationary/" },
    POSTGRADUATE_STUDIES: { pl: "/pl/studia/studia_podyplomowe/", en: "/en/education_offer/postgraduate_studies/" },
    MECHANICAL_ENGINEERING: {
        pl: "/pl/studia/kierunki/mechanika_i_budowa_maszyn/",
        en: "/en/education_offer/dyscyplines/mechanical_engineering_v1/",
    },
    AUTONATION_AND_ROBOTICS: { pl: "/pl/studia/kierunki/automatyka_i_robotyka/", en: "/en/education_offer/dyscyplines/automatics_and_robotics_v1/" },
    MECHATRONIC_ENGINEERING: {
        pl: "/pl/studia/kierunki/inzynieria_mechatroniczna/",
        en: "/en/education_offer/dyscyplines/mechatronic_engineering_v1/",
    },
    MECHANICAL_AND_MATERIALS_ENGINEERING: {
        pl: "/pl/studia/kierunki/inzynieria_mechaniczna_i_materialowa/",
        en: "/en/education_offer/dyscyplines/mechanic_and_material_v1/",
    },
    ACOUSTIC_ENGINEERING: {
        pl: "/pl/studia/kierunki/inzynieria_akustyczna/",
        en: "/en/education_offer/dyscyplines/acoustic_engineering/",
    },
    KIERUNKI_MECHATRONICS: {
        pl: "/pl/studia/kierunki/mechatronics/",
        en: "/en/education_offer/dyscyplines/mechatronics/",
    },
    TRANSPORT_FOR_INDUSTRY: {
        pl: "/pl/studia/kierunki/mechatronics/transport_w_przemysle_4.0/",
        en: "/en/education_offer/dyscyplines/transport_for_industry_4.0/",
    },
    SPECIALTIES_MECHANICAL_ENGINEERING: {
        pl: "/pl/studia/specjalnosci/mechanika_i_budowa_maszyn/",
        en: "/en/education_offer/dyscyplines/mechatronic_engineering_v1/",
    },
    RECRUITMENT: { pl: "/pl/studia/rekrutacja/", en: "/en/education_offer/admission/" },
    ADMISSION_TO_FIRST_DEGREE_STUDIES: { pl: "https://www.rekrutacja.agh.edu.pl/", en: "https://www.rekrutacja.agh.edu.pl/" },
    ADMISSION_TO_SECOND_DEGREE_STUDIES: {
        pl: "/pl/studia/rekrutacja/przyjecia_na_studia_ii_stopnia/",
        en: "/en/education_offer/admission/admission_on_2nd_cycle_studies/",
    },
    STATISTICS: { pl: "/pl/studia/statystyki/", en: "/en/education_offer/statistics/" },
    ACCREDITATION_ABET: { pl: "/pl/studia/akredytacja_-_abet/", en: "/en/education_offer/accreditation_-_abet/" },
    MECHATRONIC_ENGINEERING_ENGLISH_FIRST_DEGREE: {
        pl: "/pl/studia/akredytacja_-_abet/inzynieria_mechatroniczna_z_jezykiem_angielskim_jako_jezykiem_wykladowym__studia_pierwszego_stopnia/",
        en: "/en/education_offer/accreditation_-_abet/mechatronic_engineering_with_english_as_instruction_language__bsc_studies/",
    },
    MECHATRONIC_ENGINEERING_ENGLISH_SECOND_DEGREE: {
        pl: "/pl/studia/akredytacja_-_abet/inzynieria_mechatroniczna_z_jezykiem_angielskim_jako_jezykiem_wykladowym__studia_drugiego_stopnia__specjalnosc_projektowanie_mechatroniczne/",
        en: "/en/education_offer/accreditation_-_abet/mechatronic_engineering_with_english_as_instruction_language__msc_studies/",
    },
    POWER_35: { pl: "/pl/studia/power_3_5/" },
    POWER_35_NEWS: { pl: "/pl/studia/power_3_5/aktualnosci/" },
    POWER_35_NEWS_ARCHIVE_2018: { pl: "/pl/studia/power_3_5/aktualnosci/archiwum_2018/" },
    POWER_35_NEWS_DOCUMENTS: { pl: "/pl/studia/power_3_5/dokumenty/" },
    POWER_35_NEWS_DOCUMENTS_ARCHIVE_2018: { pl: "/pl/studia/power_3_5/dokumenty/dokumenty_2018/" },
    POWER_35_TASKS_2020: { pl: "/pl/studia/power_3_5/zadania_2020/" },
    POWER_35_TASKS_2019: { pl: "/pl/studia/power_3_5/zadania_2019/" },
    POWER_35_TASKS_2018: { pl: "/pl/studia/power_3_5/zadania_2018/" },
    ERASMUS_PLUS: {
        pl: "https://www.erasmusplus.agh.edu.pl/",
        en: "https://www.erasmusplus.agh.edu.pl/",
    },
    UNIVERSITY_BASE_OF_MAJOR_COURSES: { pl: "https://www.cok.agh.edu.pl/ubpo/" },
    BACHELOR_MASTER_ALL: {pl: "/pl/studenci/rozklady_zajec/", en: "/en/students_affairs/timetable/"},
    BACHELOR_MASTER: { pl: "/pl/studenci/rozklady_zajec/studia_stacjonarne_lato/", en: "/en/students_affairs/timetable/bachelor-master/" },
    BACHELOR_MASTER_FALL: { pl: "/pl/studenci/rozklady_zajec/studia_stacjonarne_zima/", en: "/en/students_affairs/timetable/bachelormaster_fall/" },
    BACHELOR_MASTER_EXTRAMURAL: { pl: "/pl/studenci/rozklady_zajec/studia_niestacjonarne/" },
    NEWS_PAGE: { pl: "/pl/studenci/aktualnosci-ogloszenia" },
    NEWS_EXTRAMURAL_PAGE: { pl: "/pl/studenci/aktualnosci-niestacjonarne/" },
    STUDENTS_FORM: { pl: "/pl/studenci/blankiety_dokumenty/blankiety/" },
    STUDENTS_DOCUMENTS: { pl: "/pl/studenci/blankiety_dokumenty/dokumenty/" },
    PRACTICE_INDIVIDUAL: {
         pl: "/pl/studenci/praktyki/praktyka_indywidualna/",
         en: "/en/students/dyscyplines/individual_practice/" 
    },
    PRACTICE_GRADUATION: { 
        pl: "/pl/studenci/praktyki/praktyka_dyplomowa/",
        en: "/en/students/dyscyplines/diploma_internship/" 
    },
    PRACTICE_OFFERS: { pl: "/pl/studenci/praktyki/praktyka_oferty/" },
    PRACTICE_GUARDIANS: { 
        pl: "/pl/studenci/praktyki/opiekunowie_praktyk/", 
        en: "/en/students/dyscyplines/internship_supervisors/"
    },
    STUDENTS_SCHOLARSHIP_RECTOR: { pl: "/pl/studenci/stypendia/stypendium_rektora/" },
    STUDENTS_SOCIAL_HELP: { pl: "/pl/studenci/pomoc_socjalna/" },
    STUDENTS_SCHOLARSHIP_MINISTER: { pl: "/pl/studenci/stypendia/stypendium_ministra/" },
    DIPLOMA_1_CLASS: {
        pl: "/pl/studenci/dyplom/dyplom_inzyniera_-_studia_i-stopnia/",
        en: "/en/students_affairs/diploma/engineer_diploma_-_first-degree_studies/",
    },
    DIPLOMA_2_CLASS: {
        pl: "/pl/studenci/dyplom/dyplom_magistra_-_studia_ii-stopnia/",
        en: "/en/students_affairs/diploma/master_diploma_-_second-degree_studies/",
    },
    STUDENTS_DISABLED: { pl: "/pl/studenci/studenci_z_niepelnosprawnosciami/" },
    MSDN: { pl: "/pl/studenci/msdn/" },
    BANK_ACCOUNT: { pl: "/pl/studenci/nr_konta_bankowego/" },
    PANEL_FOR_STAFF: { pl: "/pl/dla-pracownikow/", en: "/pl/dla-pracownikow/" },
    SPECIALIZATIONS: {pl: "/pl/studia/specjalnosci/", en: "/en/studies/specialties/"},
    SPECIALIZATIONS_AUTOMATION_AND_METROLOGY: {
        pl: "/pl/studia/specjalnosci/automatyka-i-metrologia/",
        en: "/en/studies/specialties/automation-and-metrology/"
    },
    SPECIALIZATIONS_ROBOTICS: {pl: "/pl/studia/specjalnosci/robotyka/", en: "/en/studies/specialties/robotics/"},
    SPECIALIZATIONS_VIBRATION_NOISE_IN_TECHNOLOGY: {
        pl: "/pl/studia/specjalnosci/drgania-i-halas-w-technice/",
        en: "/en/studies/specialties/vibrations-and-noise-in-technology/"
    },
    SPECIALIZATIONS_SOUND_ENGINEERING_MEDIA_CULTURE: {
        pl: "/pl/studia/specjalnosci/inzynieria-dzwieku-media-kultura/",
        en: "/en/studies/specialties/sound-engineering-media-culture/"
    },
    SPECIALIZATIONS_MECHATRONIC_DESIGN: {
        pl: "/pl/studia/specjalnosci/projektowanie-mechatroniczne/",
        en: "/en/studies/specialties/mechatronic-design/"
    },
    SPECIALIZATIONS_INTELLIGENY_SYSTEMS: {
        pl: "/pl/studia/specjalnosci/systemy-inteligentne/",
        en: "/en/studies/specialties/smart-systems/"
    },
    SPECIALIZATIONS_MECHATRONIC_FABRICATION: {
        pl: "/pl/studia/specjalnosci/wytwarzanie-mechatroniczne/",
        en: "/en/studies/specialties/mechatronic-concepts/"
    },
    SPECIALIZATIONS_OPERATION_AND_TECHNOLOGY_OF_MACHINES_AND_VECHICLES: {
        pl: "/pl/studia/specjalnosci/eksploatacja-i-technologia-maszyn-i-pojazdow/",
        en: "/en/studies/specialties/operation-and-technology-of-machines-and-vehicles/"
    },
    SPECIALIZATIONS_COMPUTER_SCIENCE_SPECIALIZATION_IN_MECHANICAL_ENGINEERING: {
        pl: "/pl/studia/specjalnosci/informatyka-w-inzynierii-mechanicznej/",
        en: "/en/studies/specialties/computer-science-in-mechanical-engineering/"
    },
    SPECIALIZATIONS_ENGINEERING_OF_CONSTRUCTION_MATS: {
        pl: "/pl/studia/specjalnosci/inzynieria-mat-konstrukcyjnych/",
        en: "/en/studies/specialties/engineering-of-construction-mats/"
    },
    SPECIALIZATIONS_MANUFACTURING_SYSTEMS_ENGINEERING: {
        pl: "/pl/studia/specjalnosci/inzynieria-systemow-wytwarzania/",
        en: "/en/studies/specialties/engineering-of-manufacturing-systems/"
    },
    SPECIALIZATIONS_ENGINEERING_OF_SUSTAINABLE_SYSTEMS: {
        pl: "/pl/studia/specjalnosci/inzynieria-zrownowazonych-systemow-energetycznych/",
        en: "/en/studies/specialties/engineering-of-sustainable-energy-systems/"
    },
    SPECIALIZATIONS_COMPUTER_AIDED_DESIGN: {
        pl: "/pl/studia/specjalnosci/komputerowe-wspomaganie-projektowania/",
        en: "/en/studies/specialties/computer-aided-design/"
    },
    SPECIALIZATIONS_MACHINES_FOR_EATHWORKS_AND_MATERIAL_HANDLING: {
        pl: "/pl/studia/specjalnosci/maszyny-do-robot-ziemnych-i-transportu-bliskiego/",
        en: "/en/studies/specialties/machines-for-earthworks-and-handling"
    },
    SPECIALIZATIONS_MINING_MACHINES: {
        pl: "/pl/studia/specjalnosci/maszyny-gornicze/",
        en: "/en/studies/specialties/mining-machinery/"
    },
    SPECIALIZATIONS_ROPE_TRANSPORT: {
        pl: "/pl/studia/specjalnosci/transport-liniowy/",
        en: "/en/studies/specialties/linear-transport"
    },
    SPECIALIZATIONS_IME_MECHATRONIC_DESIGN: {
        pl: "/pl/studia/specjalnosci/ime-mechatronic-design/",
        en: "/en/studies/specialties/ime-mechatronic-design"
    },
    SPECIALIZATIONS_TWP_TECHNOLOGICAL_TRANSPORT: {
        pl: "/pl/studia/specjalnosci/twp-transport-technologiczny",
        en: "/en/studies/specialties/twp-technological-transport"
    },
    SPECIALIZATIONS_TWP_AUTONOMOUSE_TRANSPORT: {
        pl: "/pl/studia/specjalnosci/twp-transport-autonomiczny",
        en: "/en/studies/specialties/twp-autonomous-transport"
    },
    SPECIALIZATIONS_TWP_INTRALOGISTICS: {
        pl: "/pl/studia/specjalnosci/twp-intralogistyka",
        en: "/en/studies/specialties/twp-intralogistics"
    },
};

export default RoutesPath;
