import React, {FC} from "react";
import MainCarousel from "../../components/MainCarousel/MainCarousel";
import Shortcuts from "../../components/Shortcuts/Shortcuts";
import NewsCarousel from "../../components/NewsCarousel/NewsCarousel";
import Achievements from "../../components/Achievments/Achievments";
import Cathedrals from "../../components/Cathedrals/Cathedrals";
import HorizontalListRow from "../../components/HorizontalList/HorizontalList";

interface PropsInterface {
}

const LayoutPage: FC<PropsInterface> = () => {
    return (
        <>
            <MainCarousel/>
            <Shortcuts/>
            <NewsCarousel/>
            <Achievements/>
            <Cathedrals/>
            <HorizontalListRow/>
        </>
    );
};

LayoutPage.propTypes = {};

export default LayoutPage;
