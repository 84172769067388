import React, { FC, useEffect } from "react";
import styled from "styled-components";
import BreakpointEnum from "../../shared/enums/BreakpointEnum/BreakpointEnum";
import Container from "../Container";
import PropTypes from "prop-types";
import { RouteComponentProps, withRouter } from "react-router-dom";

const StyledDetailContent = styled.div`
    width: 90%;
    margin: auto;
    display: flex;
    font-size: calc(18px + var(--font-modificator, 0px));
    padding: 45px 0;
    width: ${(props: {isFullWidth?: boolean}) => (props.isFullWidth ? "100%" : "90%")};
    @media ${BreakpointEnum.S} {
        width: 100%;
    }
`;

export const StyledArticleContent = styled.div`
    color: var(--font-dark, #000);
    font-size: calc(18px + var(--font-modificator, 0px));
    font-family: "Barlow", sans-serif;

    @media ${BreakpointEnum.MD} {
        font-size: calc(20px + var(--font-modificator, 0px));
    }

    @media ${BreakpointEnum.XXL} {
        font-size: calc(23px + var(--font-modificator, 0px));
    }

    p, em {
        font-size: calc(1.2vw + var(--font-modificator, 0px));
        line-height: 160%;
        margin: 0 0 18px;
    }

    a {
        font-size: calc(1.2vw + var(--font-modificator, 0px));
        color: var(--anchor-color,#00305c);

        &:hover {
            text-decoration: underline var(--font-dark, #00305c);
        }
    }
    
    h1, h2, h3, h4, h5, h6 {
        font-weight: 500;
        line-height: 1.2;
    }
    ul {
        list-style: none;

        li {
            font-size: calc(23px + var(--font-modificator, 0px));
            margin: 0 8%;
            line-height: 1.5;

            &:before {
                content: "\\2022";
                color: #00315A;
                font-weight: bold;
                display: inline-block;
                width: 1em;
                margin-left: -1em;
                font-size: 30px;
                position: relative;
                top: 3px;
                left: 10px;
            }
        }
    }

    address, dl, ol, ul {
        margin-bottom: 1rem;
    }

    ol {
        li::marker {
            font-size: calc(23px + var(--font-modificator, 0px));
        }
    }

    strong, b {
        font-family: "Barlow-Medium", sans-serif;
        font-weight:  600;
    }

    pre {
        white-space: pre-wrap;
    }

    table {
        margin: auto auto 1rem auto;
        overflow-x: auto;
        display: block;
        width: fit-content;
        max-width: 80%;
        margin: auto auto 1rem auto;

        td, th {
            border-top: 1px solid #dee2e6;
            vertical-align: top;
            padding: .75rem;
            border-collapse: collapse;
            max-width: 100%;
            color: var(--font-dark, #000);
        }
    }

    blockquote {
        border-left: 5px solid var(--main-blue, #00305c);
        padding: 20px;
        font-family: "Barlow-Light", sans-serif;
        font-style: italic;
        font-size: calc(18px + var(--font-modificator, 0px));
        color: var(--font-dark, #000);
    }

    h1 {
        font-size: calc(2.6vw + var(--font-modificator, 0px));
        margin: 2% 0 17px 0;
    }

    h2 {
        font-size: calc(2.2vw + var(--font-modificator, 0px));
        margin: 3% 0 17px 0;
    }

    h3 {
        font-size: calc(1.7vw + var(--font-modificator, 0px));
        margin: 3% 0 17px 0;
    }

    h4 {
        font-size: calc(1.5vw + var(--font-modificator, 0px));
        margin: 3% 0 17px 0;
    }

    h5 {
        font-size: calc(1.3vw + var(--font-modificator, 0px));
        margin: 3% 0 17px 0;
    }

    h6 {
        font-size: calc(1.1vw + var(--font-modificator, 0px));
        margin: 3% 0 17px 0;
    }

    .defaultMargin {
        margin: 0 10%;
    }

    .leftF {
        float: left;
        padding-right: 45px;
        padding-bottom: 15px;
    }

    .rightF {
        float: right;
        padding-left: 45px;
        padding-bottom: 15px;
    }

    .p-justified {
        text-align: justify;
    }

    .leftF,
    .rightF,
    .bigPicture {
        img {
            max-width: 100%;
        }
    }

    .odd,
    .even {
        margin-top: 40px;
    }

    .narrowParagraph {
        margin: 0 7% 18px 7%;
        font-size: 27px;
        font-weight: bold;
        line-height: 140%;
    }

    .emphasize {
        margin: 0 7% 18px 7%;
        font-size: 27px;
        font-weight: bold;
        font-style: italic;
    }

    .marginBottom5 {
        margin-bottom: 5%;
    }

    #conferenceProgramCouncil {
        .programCouncil {
            display: flex;
            flex-wrap: wrap;
            p {
                flex-basis: 50%;
                padding-right: 5%;
                @media only screen and (max-width: 768px) {
                    flex-basis: 100%;
                }
            }
        }
    }

    @media only screen and (max-width: 991px) {
            h1 {
                font-size: 30px;
            }

            h2 {
                font-size: 24px;
            }
            
            h3 {
                font-size: 21px;
            }
            
            h4 {
                font-size: 17px;
            }
            
            h5 {
                font-size: 15px;
            }
            
            h6 {
                font-size: 13px;
            }

            a, p, em {
                font-size: 16px;
                text-align: unset;
            }

            ul {
                list-style: none;

                li {
                    font-size: calc(16px + var(--font-modificator, 0px));
                    margin: 0 8%;

                    &:before {
                        content: "\\2022";
                        color: #00315A;
                        font-weight: bold;
                        display: inline-block;
                        width: 1em;
                        margin-left: -1em;
                        font-size: 30px;
                        position: relative;
                        top: 3px;
                        left: 10px;
                    }
                }
            }

            ol {
                li::marker {
                    font-size: calc(16px + var(--font-modificator, 0px));
                }
            }

            .narrowParagraph {
                font-size: 18px;
            }

            .table {
                width: 100%;
                margin: auto auto 1rem auto;
            }
    }
`;

export const DetailContent: FC<{content: string; isFullWidth?: boolean} & RouteComponentProps> = ({
                                                                                                      content,
                                                                                                      isFullWidth,
                                                                                                      history
                                                                                                  }) => {
    useEffect(() => {
        const contentRenderer = document.getElementById("page-detail-content");
        if (contentRenderer) {
            const links: HTMLCollectionOf<HTMLAnchorElement> = contentRenderer.getElementsByTagName("a");
            for (let i = 0; i < links.length; i++) {
                const linkUrl = links[i].href;
                const matchLink = linkUrl ? linkUrl.toString().match(/^(http(s)?:\/\/([^/]+))?\/(.*)$/) : null;
                if (matchLink && (!matchLink[3] || matchLink[3] === window.location.host) && matchLink[4]) {
                    links[i].onclick = function () {
                        history.push("/" + matchLink[4]);
                        return false;
                    };
                }
            }
        }
    }, []);

    return (
        <StyledDetailContent isFullWidth={isFullWidth}>
            <Container>
                <StyledArticleContent id="page-detail-content" dangerouslySetInnerHTML={{ __html: content }} />
            </Container>
        </StyledDetailContent>
    );
};

DetailContent.propTypes = {
    content: PropTypes.string.isRequired,
    isFullWidth: PropTypes.bool,
    history: PropTypes.any,
};

DetailContent.defaultProps = {
    isFullWidth: false,
};

export default withRouter(DetailContent);
