// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { FC, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import AnimateHeight from "react-animate-height";
import { injectIntl, WrappedComponentProps } from "react-intl";
import BreakpointEnum from "../../shared/enums/BreakpointEnum/BreakpointEnum";
import DetailContentChildren from "../DetailContent/DetailContentChildren";
import { FilesForStaffInterface } from "../../services/Api/DataInterface/FilesForStaffInterface";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const HeaderText = styled.h1`
    width: 90%;
    margin: auto;
    padding-top: 31px;
    font-size: calc(32px + var(--font-modificator, 0px));

    @media ${BreakpointEnum.S} {
        font-size: calc(39px + var(--font-modificator, 0px));
        width: 100%;
    }
`;

const TitleFile = styled.h3`
    cursor: pointer;
`;

const TabsText = styled.div`
    font-size: calc(22px + var(--font-modificator, 0px));

    @media ${BreakpointEnum.S} {
        font-size: calc(25px + var(--font-modificator, 0px));
    }
`;

interface PropsInterface extends WrappedComponentProps {
    data: FilesForStaffInterface[] | null;
}

const FileItems: FC<PropsInterface> = ({ data, intl }) => {
    const [itemIdClicked, setItemIdClicked] = useState(0);

    if (!data) {
        return null;
    }

    const tabsElement: { [key: string]: FilesForStaffInterface[] } = {};
    for (const item of data) {
        if (!tabsElement[item.category]) {
            tabsElement[item.category] = [];
        }

        tabsElement[item.category].push(item);
    }
    const tabsKey = Object.keys(tabsElement).sort();

    return (
        <>
            <HeaderText>Pliki dla pracowników</HeaderText>
            <br />
            <br />
            <br />
            <Tabs>
                <TabList>
                    {tabsKey.map((key) => (
                        <Tab key={key}>
                            <TabsText>{intl.formatMessage({ id: `filesForStaff.category.${key.toLowerCase()}` })}</TabsText>
                        </Tab>
                    ))}
                </TabList>
                {tabsKey.map((key) => (
                    <TabPanel key={key}>
                        <DetailContentChildren>
                            {tabsElement[key].map((item, index) => (
                                <>
                                    <TitleFile
                                        onClick={() => {
                                            setItemIdClicked(item.id);
                                        }}
                                    >
                                        {item.title}
                                    </TitleFile>
                                    <AnimateHeight height={item.id === itemIdClicked ? "auto" : 0} duration={400}>
                                        <ul key={index}>
                                            {item.files.map((file, index) => (
                                                <li key={`${item.id}_${index}`}>
                                                    <a href={file.url} target="_blank" rel="noreferrer">
                                                        {file.description ? file.description : file.name}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </AnimateHeight>
                                </>
                            ))}
                        </DetailContentChildren>
                    </TabPanel>
                ))}
            </Tabs>
        </>
    );
};

FileItems.propTypes = {
    data: PropTypes.any,
    intl: PropTypes.any.isRequired,
};

export default injectIntl(FileItems);
