import React, { FC } from "react";
import styled from "styled-components";
import Container from "../Container/Container";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import menuStructure from "../Menu/menuStructure";
import { injectIntl, WrappedComponentProps } from "react-intl";
import BreakpointEnum from "../../shared/enums/BreakpointEnum/BreakpointEnum";
import { GetListMenuResponseInterface } from "../../services/Api/DataInterface/GetListMenuResponseInterface";

const HeaderText = styled.h1`
    width: 90%;
    margin: auto;
    padding-top: 31px;
    font-size: calc(32px + var(--font-modificator, 0px));
    
    @media ${BreakpointEnum.S} {
        font-size: calc(39px + var(--font-modificator, 0px));
        width: 100%;
    }
`;

const getDataForHeader = (menu: GetListMenuResponseInterface[], activeElements: GetListMenuResponseInterface[], pathname: string) => {
    for (const item of menu) {
        if (item.routes && ((item.routes.pl && item.routes.pl === pathname) || (item.routes.en && item.routes.en === pathname))) {
            activeElements.push(item);
            break;
        }

        if (!item.children) {
            continue;
        }

        getDataForHeader(item.children, activeElements, pathname);
        if (activeElements.length > 0) {
            activeElements.push(item);
            break;
        }
    }
};

export const DetailHeaderText: FC<WrappedComponentProps> = ({ intl }) => {
    const location = useLocation();
    const menuElements: GetListMenuResponseInterface[] = [];

    getDataForHeader(menuStructure, menuElements, location.pathname);

    if (menuElements.length === 0) {
        return null;
    }

    return (
        <Container>
            <HeaderText> {intl.formatMessage({ id: menuElements[0].text })}</HeaderText>
        </Container>
    );
};

DetailHeaderText.propTypes = {
    intl: PropTypes.any.isRequired,
};

export default injectIntl(DetailHeaderText);
