import { MediaInterface } from "./MediaInterface";

export enum categoryNews {
    NEWS = "Aktualnosci-ogloszenia",
    NEWS_NON_STATIONARY = "Aktualnosci-niestacjonarne",
    NEWS_DOCTORAL = "Aktualnosci-doktoranckie",
}

export interface SliderNewsItemInterface {
    id: number;
    aktualnosci_obrazki: MediaInterface[];
    date_article: string;
    category: categoryNews;
    url: string;
    pliki: MediaInterface[];
    title: string;
    author: string;
    description: string;
    short_description: string;
}

export interface SliderNewsResponseInterface {
    items: SliderNewsItemInterface[];
    numOfItems: number;
    offset: number;
}
