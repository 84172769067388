import React, { FC, useEffect, useState } from "react";
import PropTypes from "prop-types";
import DetailHeader from "../../components/DetailHeader/DetailHeader";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import container from "../../container";
import LocaleEnum from "../../services/Locale/enums/Locale/LocaleEnum";
import { DynamicObjectSlugEnum, PageResult } from "../../services/Api/DynamicModuleService";
import { NewsInterface } from "../../services/Api/DataInterface/NewsInterface";
import NewsListItem from "../../components/NewsList/components/NewsListItem/NewsListItem";
import BreakpointEnum from "../../shared/enums/BreakpointEnum/BreakpointEnum";
import Container from "../../components/Container";
import styled from "styled-components";
import {connect} from "react-redux";
import {ApplicationState} from "../../store";

interface PropsInterface {
    dataSource: DynamicObjectSlugEnum;
    slug: string;
    locale: LocaleEnum;
}

const StyledNewsDetail = styled.div`
    width: 90%;
    margin: auto;
    display: flex;
    font-size: calc(18px + var(--font-modificator, 0px));
    flex-wrap: wrap;

    @media ${BreakpointEnum.S} {
        width: 100%;
    }
`;

const StyledNewsDetailTitle = styled.div`
    font-size: calc(38px + var(--font-modificator, 0px));
    color: var(--font-dark, #00305c);
    border-bottom: 1px solid var(--contrast-yellow, #a71931);
    padding: 20px 0;

    @media ${BreakpointEnum.S} {
        font-size: calc(48px + var(--font-modificator, 0px));
    }
`;

const LayoutNewsDetail: FC<PropsInterface> = ({ dataSource, slug, locale }) => {
    const [newsDetailItemData, setNewsDetailItemData] = useState<null | NewsInterface>(null);

    useEffect(() => {
        (async () => {
            const pageData: PageResult<NewsInterface> = await container.dynamicModuleApiService.getPageList(locale, dataSource, 1, 10);
            /* eslint-disable  @typescript-eslint/no-explicit-any */
            const item = pageData.items.find((item) => (item as any).id_page === slug);
            if (!item) {
                throw new Error(`404 - not found expected news in database ${dataSource}-${slug}`);
            }
            setNewsDetailItemData(item);
        })();
    }, [locale, dataSource, slug]);

    return (
        <>
            <DetailHeader />
            <Breadcrumbs />
            {newsDetailItemData && (
                <Container>
                    <StyledNewsDetailTitle>{newsDetailItemData.title}</StyledNewsDetailTitle>
                    <StyledNewsDetail>
                        <NewsListItem htmlId={`news-detail-${newsDetailItemData.id}`} item={newsDetailItemData} isSingleItemList={true} />
                    </StyledNewsDetail>
                </Container>
            )}
        </>
    );
};

LayoutNewsDetail.propTypes = {
    dataSource: PropTypes.any.isRequired,
    slug: PropTypes.string.isRequired,
    locale: PropTypes.oneOf([LocaleEnum.PL, LocaleEnum.EN]).isRequired,
};


const mapStateToProps = (state: ApplicationState) => ({
    locale: state.locale.locale,
});

export default connect(mapStateToProps)(LayoutNewsDetail);
