import React, { Dispatch, FC, SetStateAction, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import {
    ActiveFullscreenMenuItem,
    FullscreenMenuItem,
    FullscreenMenuList,
    FullscreenMenuWrapper,
    MenuContent,
    StyledHamburgerBox,
    SubmenuContent,
    CloseIconWrapper,
    ScrollArrow,
} from "./components/StyledFullscreenComponents";
import Hamburger from "../Hamburger/Hamburger";
import LangIco from "../LangIco/LangIco";
import SubmenuItem from "./components/SubmenuItem/SubmenuItem";
import { injectIntl, WrappedComponentProps } from "react-intl";
import LocaleEnum from "../../../../services/Locale/enums/Locale/LocaleEnum";
import { ApplicationState } from "../../../../store";
import { connect } from "react-redux";
import { Divide as CloseIcon } from "hamburger-react";
import { ThemeEnum } from "../../../../shared/enums/ThemeEnum/ThemeEnum";
import styled from "styled-components";
import container from "../../../../container";
import { GetListMenuResponseInterface } from "../../../../services/Api/DataInterface/GetListMenuResponseInterface";


interface FullscreenMenuProps extends WrappedComponentProps {
    mainMenuItems?: GetListMenuResponseInterface[] | null;
    isOpen: boolean;
    activeElementIndex: number | boolean;
    closeFullScreenMenu?: () => void;
    locale: LocaleEnum;
    openingSource?: boolean;
    contrast: ThemeEnum;
}

const onItemClick = (
    item: GetListMenuResponseInterface,
    setActiveParentIndex: Dispatch<SetStateAction<number | boolean>>,
    index: number,
    setIsMainMenuClosed: any,
    locale: LocaleEnum
) => {
    if (item && item.routes && item.routes[locale]) {
        return (window.location.href = item.routes[locale]);
    }

    setActiveParentIndex(index);
    setIsMainMenuClosed(true);
};

const isHomepageOrNews = (pathname: string) => {
    return pathname === "/" || pathname.includes("/aktualnosci-ogloszenia");
};

const FullscreenMenu: FC<FullscreenMenuProps> = ({ locale, mainMenuItems, isOpen, activeElementIndex, closeFullScreenMenu, intl, openingSource, contrast }) => {
    const initParent = typeof activeElementIndex === "number" ? activeElementIndex : false;
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    const [activeParentIndex, setActiveParentIndex] = useState<any>(initParent);
    const [isMainMenuClosed, setIsMainMenuClosed] = useState<boolean>(!(openingSource && isHomepageOrNews(window.location.pathname)));
    const hamburgerExitColor = contrast !== ThemeEnum.Contrast2 ? '#FFF' : '#000';  
    const menuRef = useRef<HTMLDivElement>(null);
    const activeItem = (mainMenuItems?.filter((item) => item.id == activeParentIndex))?.[0]
    const [showTopArrow, setShowTopArrow] = useState<boolean>(false);
    const [showBottomArrow, setShowBottomArrow] = useState<boolean>(false);
    const submenuContentRef = useRef<HTMLDivElement>(null);
    const resizeObserverRef = useRef<ResizeObserver | null>(null);
    
    useEffect(() => {
        setActiveParentIndex(activeParentIndex || initParent);

    }, [initParent, isOpen]);

    useEffect(() => {
        setActiveParentIndex(activeElementIndex);
    }, [activeElementIndex]);

    useEffect(() => {
        if (isHomepageOrNews(window.location.pathname)) {
            setIsMainMenuClosed(!(openingSource && isHomepageOrNews(window.location.pathname)));
        }
    }, [window.location.pathname, openingSource]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                closeFullScreenMenu?.();
            }
        };

        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen, closeFullScreenMenu]);

    useEffect(() => {
        const handleScroll = () => {
            if (submenuContentRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = submenuContentRef.current;
                setShowTopArrow(scrollTop > 0);
                setShowBottomArrow(scrollTop + clientHeight < scrollHeight);
            }
        };

        const handleResize = () => {
            setTimeout(() => {
                if (submenuContentRef.current) {
                    const { scrollTop, scrollHeight, clientHeight } = submenuContentRef.current;
                    setShowTopArrow(scrollTop > 0);
                    setShowBottomArrow(scrollTop + clientHeight < scrollHeight);
                }
            }, 300);
        };

        if (submenuContentRef.current) {
            submenuContentRef.current.addEventListener("scroll", handleScroll);
            handleScroll();
        }

        if (isOpen && submenuContentRef.current) {
            handleResize();
            if (resizeObserverRef.current) {
                resizeObserverRef.current.disconnect();
            }
            resizeObserverRef.current = new ResizeObserver(handleResize);
            resizeObserverRef.current.observe(submenuContentRef.current);
        }

        return () => {
            if (submenuContentRef.current) {
                submenuContentRef.current.removeEventListener("scroll", handleScroll);
            }
            if (resizeObserverRef.current) {
                resizeObserverRef.current.disconnect();
            }
        };
    }, [submenuContentRef.current, isOpen]);

    const scrollIntervalRef = useRef<NodeJS.Timeout | null>(null);

    const startScrolling = (direction: 'up' | 'down') => {
        if (scrollIntervalRef.current) return;

        scrollIntervalRef.current = setInterval(() => {
            if (submenuContentRef.current) {
                const scrollAmount = 50;
                if (direction === 'up') {
                    submenuContentRef.current.scrollBy({ top: -scrollAmount, behavior: 'smooth' });
                } else {
                    submenuContentRef.current.scrollBy({ top: scrollAmount, behavior: 'smooth' });
                }
            }
        }, 50);
    };

    const stopScrolling = () => {
        if (scrollIntervalRef.current) {
            clearInterval(scrollIntervalRef.current);
            scrollIntervalRef.current = null;
        }
    };

    return (
        <FullscreenMenuWrapper isOpen={isOpen} ref={menuRef}>
            <MenuContent>
                {isOpen && (
                    <>
                    <LangIco isTopBar={false} />
                    <FullscreenMenuList hasActiveParent={activeParentIndex !== false} isClosed={isMainMenuClosed}>
                        {activeItem && activeItem.text && (
                            <ActiveFullscreenMenuItem onClick={() => setIsMainMenuClosed(!isMainMenuClosed)}>
                                <StyledHamburgerBox>
                                    <Hamburger isActive={!isMainMenuClosed} />
                                </StyledHamburgerBox>
                                { activeItem?.text || "" }
                            </ActiveFullscreenMenuItem>
                        )}
                        {mainMenuItems?.map((item, index) => {
                            if (locale === LocaleEnum.EN && !item.isMultiLang) {
                                return false;
                            }
                            return (
                                item.id !== activeParentIndex && (
                                    <FullscreenMenuItem
                                        key={`fsmik_${item.id}`}
                                        onClick={() => onItemClick(item, setActiveParentIndex, item.id, setIsMainMenuClosed, locale)}
                                    >
                                        { item?.text || "" }
                                    </FullscreenMenuItem>
                                )
                            );
                        })}
                    </FullscreenMenuList>
                    </>
                )}

                <SubmenuContent ref={submenuContentRef}>
                    {activeItem?.children?.map((item: GetListMenuResponseInterface) => {
                        if (locale === LocaleEnum.EN && !item.isMultiLang) {
                            return false;
                        }
               
                        return <SubmenuItem {...item} key={`smfsik_${item.id}`} closeFullScreenMenu={closeFullScreenMenu} />;
                    })}
                    <CloseIconWrapper>
                        <CloseIcon
                            toggled={isOpen}
                            toggle={closeFullScreenMenu}
                            color={hamburgerExitColor}
                            direction={"right"}
                        />
                    </CloseIconWrapper>
                    <ScrollArrow
                        isVisible={showTopArrow}
                        className="top"
                        onMouseEnter={() => startScrolling('up')}
                        onMouseLeave={stopScrolling}
                    >
                        ▲
                    </ScrollArrow>
                    <ScrollArrow
                        isVisible={showBottomArrow}
                        className="bottom"
                        onMouseEnter={() => startScrolling('down')}
                        onMouseLeave={stopScrolling}
                    >
                        ▼
                    </ScrollArrow>
                </SubmenuContent>
            </MenuContent>
        </FullscreenMenuWrapper>
    );
};

FullscreenMenu.propTypes = {
    locale: PropTypes.oneOf([LocaleEnum.PL, LocaleEnum.EN]).isRequired,
    mainMenuItems: PropTypes.array,
    isOpen: PropTypes.bool.isRequired,
    activeElementIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
    intl: PropTypes.any.isRequired,
    closeFullScreenMenu: PropTypes.func,
    openingSource: PropTypes.bool,
    contrast: PropTypes.any
};

const mapStateToProps = (state: ApplicationState) => ({
    locale: state.locale.locale,
    contrast: state.contrast.contrast
});

export default connect(mapStateToProps)(injectIntl(FullscreenMenu));