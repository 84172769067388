import React, { FC } from "react";
import DetailHeader from "../DetailHeader/DetailHeader";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import Container from "../Container";
import styled from "styled-components";
import BreakpointEnum from "../../shared/enums/BreakpointEnum/BreakpointEnum";
import DetailContentChildren from "../DetailContent/DetailContentChildren";

const HeaderText = styled.h1`
    width: 90%;
    margin: auto;
    padding-top: 31px;
    font-size: calc(32px + var(--font-modificator, 0px));

    @media ${BreakpointEnum.S} {
        font-size: calc(39px + var(--font-modificator, 0px));
        width: 100%;
    }
`;

const ContainerMiddle = styled.div`
    width: 80%;
    margin: 0 auto;
    text-align: center;
    line-height: 34px;
`;

const Forbidden: FC = () => {
    return (
        <>
            <DetailHeader />
            <Breadcrumbs />
            <Container>
                <HeaderText>Dostęp do dokumentów</HeaderText>
            </Container>
            <DetailContentChildren>
                <ContainerMiddle>
                    Niestety, nie masz dostępu do plików.
                    <br />
                </ContainerMiddle>
            </DetailContentChildren>
        </>
    );
};

export default Forbidden;
