import React, { FC } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";
import { ApplicationState } from "../../store";
import Shortcut from "./components/Shortcut/Shortcut";
import Container from "../Container";
import BreakpointEnum from "../../shared/enums/BreakpointEnum/BreakpointEnum";
import { ThemeEnum } from "../../shared/enums/ThemeEnum/ThemeEnum";

interface IShortCutsProps {
    contrast: ThemeEnum
}

export interface ShortcutInterface {
    id: string | number;
    icoUrl: string;
    prelabel?: string | null;
    label: string;
    sublabel?: string | null;
    index: number;
}

const ShortcutContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
    padding-top: 65px;

    @media ${BreakpointEnum.S} {
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    @media ${BreakpointEnum.MD} {
        column-gap: 20px;
    }

    @media ${BreakpointEnum.XXL} {
        column-gap: 30px;
    }
`;

const Shortcuts: FC<IShortCutsProps> = ({contrast}) => {
    const shortcuts: ShortcutInterface[] = [
        {
            id: 1,
            prelabel: "shortcut.candidates.prelabel",
            label: "shortcut.candidates.label",
            sublabel: "shortcut.candidates.sublabel",
            index: 2,
            icoUrl: `/images/contrast/${contrast}/ico1.png`,
        },
        {
            id: 2,
            prelabel: "shortcut.students.prelabel",
            label: "shortcut.students.label",
            sublabel: "shortcut.students.sublabel",
            index: 5,
            icoUrl: `/images/contrast/${contrast}/ico2.png`,
        },
        {
            id: 3,
            label: "shortcut.faculty.label",
            sublabel: "shortcut.faculty.sublabel",
            index: 4,
            icoUrl: `/images/contrast/${contrast}/ico3.png`
        },
        {id: 4, label: "shortcut.science.label", index: 6, icoUrl: `/images/contrast/${contrast}/ico4.png`},
    ];
    return (
        <Container>
            <ShortcutContainer>
                {shortcuts.map((shortcut: ShortcutInterface) => (
                    <Shortcut {...shortcut} key={`k${shortcut.id}${shortcut.label}`} />
                ))}
            </ShortcutContainer>
        </Container>
    );
};

Shortcuts.propTypes = {
    contrast: PropTypes.any.isRequired,
};

const mapStateToProps = (state: ApplicationState) => ({
    contrast: state.contrast.contrast,
});

export default connect(mapStateToProps)(Shortcuts);
