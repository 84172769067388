import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { ApplicationState } from "../../store";
import { connect } from "react-redux";
import { BreakpointValuesEnum } from "../../shared/enums/BreakpointEnum/BreakpointEnum";
import { useWindowSize } from "../../shared/hooks/useWindowSize";
import MenuMobile from "./MenuMobile/MenuMobile";
import MenuDesktop from "./MenuDesktop/MenuDesktop";
import LocaleEnum from "../../services/Locale/enums/Locale/LocaleEnum";
import PropTypes from "prop-types";
import container from "../../container";
import { changeLocaleAction } from "../../services/Locale/store/Actions";
import { GetListMenuResponseInterface } from "../../services/Api/DataInterface/GetListMenuResponseInterface";

const MenuComponent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export interface MenuPropsInterface {
    locale: LocaleEnum;
}

const Menu: FC<MenuPropsInterface> = ({ locale }) => {
    const size = useWindowSize();
    const [isMobile, setIsMobile] = useState(window?.innerWidth < BreakpointValuesEnum.MD);
    const [menuShortcut, setMenuShortcut] = useState<GetListMenuResponseInterface[] | null>(null);


    useEffect(() => {
        const { width } = size;

        if (!width) {
            return;
        }

        if (width < BreakpointValuesEnum.MD) {
            setIsMobile(true);
            return;
        }

        setIsMobile(false);
    }, [size.width]);

    useEffect(() => {
        (async () => {
            const data = await container.menuService.getListMenu(locale)
            setMenuShortcut(data);
        })();
    }, [locale]);

    if(!menuShortcut) {
        return null;
    }

    //@TODO check dynamic load with https://reactjs.org/docs/code-splitting.html#reactlazy
    return (
        <MenuComponent>
            {isMobile ? (
                <MenuMobile mainMenuItems={menuShortcut} />
            ) : (
                <MenuDesktop mainMenuItems={menuShortcut} />
            )}
        </MenuComponent>
    );
};

Menu.propTypes = {
    locale: PropTypes.oneOf([LocaleEnum.PL, LocaleEnum.EN]).isRequired,
};

const mapStateToProps = (state: ApplicationState) => ({
    locale: state.locale.locale,
});

export default connect(mapStateToProps, { changeLocaleAction })(Menu);
