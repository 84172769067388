import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import AbstractRestApiConstructorInterface from "./AbstractRestApiOptionsInterface";

abstract class AbstractRestApi<T> {
    protected httpClient: AxiosInstance;

    protected constructor(options: AbstractRestApiConstructorInterface) {
        const { host } = options;
        const { defaults = {} } = options;

        this.httpClient = axios.create({
            baseURL: host,
            withCredentials: true,
        });
        this.httpClient.defaults = defaults;
    }

    addInterceptors = (
        responseErrorTransformer?: (error: AxiosError) => void,
        requestTransformer?: (req: AxiosRequestConfig) => Promise<AxiosRequestConfig> | AxiosRequestConfig
    ) => {
        if (responseErrorTransformer) {
            this.httpClient.interceptors.response.use(
                (response) => response,
                (error) => responseErrorTransformer(error)
            );
        }

        if (requestTransformer) {
            this.httpClient.interceptors.request.use(requestTransformer);
        }
    };

    protected async get(path: string, options?: AxiosRequestConfig & T): Promise<AxiosResponse> {
        return await this.httpClient.get(path, options);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected async post(path: string, body: any, options?: AxiosRequestConfig & T): Promise<AxiosResponse> {
        return await this.httpClient.post(path, body, options);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected async put(path: string, body: any, options?: AxiosRequestConfig & T): Promise<AxiosResponse> {
        return await this.httpClient.put(path, body, options);
    }

    protected async delete(path: string, options?: AxiosRequestConfig & T): Promise<AxiosResponse> {
        return await this.httpClient.delete(path, options);
    }
}

export default AbstractRestApi;
