import React, { FC } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ShortcutPropsInterface from "../../Interfaces/ShortcutPropsInterface";
import BreakpointEnum from "../../../../shared/enums/BreakpointEnum/BreakpointEnum";
import { ApplicationState } from "../../../../store";
import { connect } from "react-redux";
import { changeMenuActions } from "../../../../services/Locale/store/Menu/MenuActions";
import { injectIntl } from "react-intl";

const ShortcutLink = styled.a`
    display: inline-flex;
    width: 100%;
    cursor: Pointer;
`;

const ShortcutBox = styled.div`
    background: var(--main-red, red);
    width: 90%;
    display: inline-flex;
    transition: 0.3s background-color;

    @media (min-width: 440px) {
        width: 45%;
    }

    @media ${BreakpointEnum.S} {
        width: 25%;
    }

    &:hover {
        background: var(--main-blue, #000);
    }
`;

const ShortcutContent = styled.div`
    padding: 15px;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    display: flex;
    align-items: center;

    @media ${BreakpointEnum.MD} {
        align-items: flex-end;
        padding: 20px 15px;
    }

    @media ${BreakpointEnum.XL} {
        padding: 35px 20px;
    }

    @media ${BreakpointEnum.XXL} {
        padding: 50px 35px;
    }
`;

const Ico = styled.img`
    height: 40px;
    display: inline-flex;

    @media ${BreakpointEnum.MD} {
        height: 100%;
    }
`;

const ShortcutLabelsContent = styled.div`
    color: var(--font-light, #fff);
    text-transform: uppercase;
    line-height: 1.16;
    display: inline-flex;
    flex-direction: column;
    font-size: calc(16px + var(--font-modificator, 0px));
    margin-left: 10px;

    @media ${BreakpointEnum.MD} {
        font-size: calc(18px + var(--font-modificator, 0px));
        margin-left: 20px;
    }

    @media ${BreakpointEnum.XL} {
        font-size: calc(20px + var(--font-modificator, 0px));
    }
`;

const Prelabel = styled.div`
    font-size: calc(0.7em + var(--font-modificator, 0px));
    width: 100%;
`;

const Label = styled.div`
    width: 100%;
`;

const Sublabel = styled.div`
    font-size: calc(0.7em + var(--font-modificator, 0px));
    width: 100%;
`;

const Shortcut: FC<ShortcutPropsInterface> = (props) => {
    const { prelabel, label, sublabel, icoUrl, changeMenuActions, index } = props;

    return (
        <ShortcutBox>
            <ShortcutLink
                onClick={() => {
                    changeMenuActions(index, true);
                }}
            >
                <ShortcutContent>
                    <Ico src={icoUrl} alt={`${label} ico`} />
                    <ShortcutLabelsContent>
                        <Prelabel>{prelabel ? props.intl.formatMessage({ id: prelabel }) : "\u00A0"}</Prelabel>
                        <Label>{label ? props.intl.formatMessage({ id: label }) : "\u00A0"}</Label>
                        <Sublabel>{sublabel ? props.intl.formatMessage({ id: sublabel }) : "\u00A0"}</Sublabel>
                    </ShortcutLabelsContent>
                </ShortcutContent>
            </ShortcutLink>
        </ShortcutBox>
    );
};

Shortcut.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    icoUrl: PropTypes.string.isRequired,
    prelabel: PropTypes.string,
    label: PropTypes.string.isRequired,
    sublabel: PropTypes.string,
    index: PropTypes.number.isRequired,
    changeMenuActions: PropTypes.func.isRequired,
    intl: PropTypes.any.isRequired,
};

Shortcut.defaultProps = {
    sublabel: "",
    prelabel: "",
};

const mapStateToProps = (state: ApplicationState) => ({
    activeElement: state.menu.activeElement,
    isOpenMenu: state.menu.isOpenMenu,
    contrast: state.contrast.contrast
});

export default connect(mapStateToProps, { changeMenuActions })(injectIntl(Shortcut));
