import { GetListMenuResponseInterface } from "../../services/Api/DataInterface/GetListMenuResponseInterface";

const GeneratePathApi = (child: GetListMenuResponseInterface, page?: boolean) => {
    const url: string[] = [];

    if (!child.routes) {
        return url;
    }

    if(child.routes.pl) {
        url.push(child.routes.pl);

        if(page) {
            url.push(child.routes.pl + '/_get/page/:page(\\d+)');
        }
    }

    if (child.routes.en) {
        url.push(child.routes.en as string);
    }

    return url;
};

export default GeneratePathApi;