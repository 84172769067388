import React, { FC } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import BreakpointEnum from "../../shared/enums/BreakpointEnum/BreakpointEnum";

interface CarouselArrowProps {
    direction?: string;
    onClick: () => void;
    overwritePosition?: number;
    hidden?: boolean;
}

export interface ArrowProps {
    isLeft: boolean;
    position?: number;
    hidden?: boolean;
}

export const Arrow = styled.button`
    position: absolute;
    height: 87px;
    width: 24px;
    top: 0;
    bottom: 0;
    border: none;
    outline: none;
    margin: auto;
    z-index: 1;
    cursor: pointer;
    transition: .5s opacity;
    background-color: var(--image-background-color, none)!important;
    background-blend-mode: var(--image-background-blend-mode, none);
    background: ${(props: ArrowProps) => `url("/images/arrow-${props.isLeft ? "left" : "right"}.svg") no-repeat center`};
    ${(props: ArrowProps) => (props.isLeft ? `left: 0;` : `right: 0`)};
    ${(props: ArrowProps) => (props.hidden ? `opacity: 0;` : `opacity: 1`)};

    @media ${BreakpointEnum.MD} {
        ${(props: ArrowProps) => (props.isLeft ? `left: ${props.position}px;` : `right: ${props.position}px;`)};
        width: 36px;
    }

    @media ${BreakpointEnum.XXL} {
        width: 53px;
    }
`;

const CarouselArrow: FC<CarouselArrowProps> = (props) => {
    const { direction, onClick, overwritePosition, hidden } = props;
    return <Arrow onClick={onClick} isLeft={direction?.toLowerCase() === "left"} position={overwritePosition || 0} hidden={hidden}/>;
};

CarouselArrow.propTypes = {
    direction: PropTypes.string,
    onClick: PropTypes.any,
    overwritePosition: PropTypes.number,
    hidden: PropTypes.bool,
};

CarouselArrow.defaultProps = {
    direction: "right",
    overwritePosition: 0,
    hidden: false,
};

export default CarouselArrow;
