import AbstractRestApi from "../AbstractRest/AbstractRestApi";
import AbstractApiConstructorInterface from "../AbstractRest/AbstractRestApiOptionsInterface";
import LocaleEnum from "../Locale/enums/Locale/LocaleEnum";
import ApiRequestOptionsInterface from "./ApiRequestOptionsInterface";
import {GetListMenuResponseInterface} from "./DataInterface/GetListMenuResponseInterface";

export default class MenuService extends AbstractRestApi<ApiRequestOptionsInterface> {
    constructor(options: AbstractApiConstructorInterface) {
        super(options);
    }

    public async getListMenu(lang: LocaleEnum): Promise<GetListMenuResponseInterface[]> {
        const response = await this.get("/menu/list", { params : { lang }});

        return response.data;
    }
}