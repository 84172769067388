import {
    LocaleActionTypesEnum,
    LocaleActionTypes,
    LocaleStateInterface,
} from "./Types";
import LocaleEnum from "../enums/Locale/LocaleEnum";

const initialState: LocaleStateInterface = {
    locale: LocaleEnum.PL,
};

const localeReducer = function (state = initialState, action: LocaleActionTypes): LocaleStateInterface {
    switch (action.type) {
        case LocaleActionTypesEnum.CHANGE_LOCALE:
            return {
                ...state,
                locale: action.locale,
            };
        default:
            return state;
    }
};

export default localeReducer;
