import React, { FC, useEffect } from "react";
import PropTypes from "prop-types";
import ReactPaginate from "react-paginate";
import styled from "styled-components";
import BreakpointEnum from "../../shared/enums/BreakpointEnum/BreakpointEnum";
import Container from "../Container";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { NewsListDataProps } from "./interfaces/NewsListInterface";
import NewsListItem from "./components/NewsListItem/NewsListItem";

const StyledNewsList = styled.div`
    width: 90%;
    margin: auto;
    display: flex;
    font-size: calc(18px + var(--font-modificator, 0px));
    flex-wrap: wrap;

    @media ${BreakpointEnum.S} {
        width: 100%;
    }
`;

const StyledNewsListTitle = styled.div`
    font-size: calc(38px + var(--font-modificator, 0px));
    color: var(--font-dark, #00305c);
    border-bottom: 1px solid var(--contrast-yellow, #a71931);
    padding: 20px 0;
    text-indent: 20px;

    @media ${BreakpointEnum.S} {
        font-size: calc(48px + var(--font-modificator, 0px));
    }
`;

const StyledPaginationContainer = styled.div`
    ul.pagination-row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-content: center;
        margin: 20px 0;

        @media ${BreakpointEnum.MD} {
            margin: 45px 0;
        }

        li {
            display: inline-flex;

            a {
                padding: 10px 12px;
                background: var(--main-blue, #00305c);
                transition: 0.3s background;
                cursor: pointer;
                color: var(--font-light, #fff);

                @media ${BreakpointEnum.XS} {
                    padding: 15px 20px;
                }

                &:hover {
                    background: var(--main-red, #a71931);
                }
            }

            &.selected a {
                background: var(--main-red, #a71931);
            }
        }
    }
`;

const NewsList: FC<NewsListDataProps & RouteComponentProps> = ({ itemsPerPage, openItemId, history, newsPageListData }) => {
    const scrollToElement = (element: HTMLElement | null) => {
        element && element.scrollIntoView({ behavior: "smooth" });
    };

    const scrollToNews = (id: number) => {
        const htmlId = `list-news-item-${id}`;
        if (document && htmlId) {
            const el = document.getElementById(htmlId);
            scrollToElement(el);
        }
    };

    const scrollToTop = () => {
        scrollToElement(document.getElementById("root"));
    };

    useEffect(() => {
        if (openItemId) {
            scrollToNews(openItemId);
        }
    }, [openItemId]);

    return (
        <StyledNewsList>
            <Container>
                <StyledNewsListTitle />
            </Container>

            {newsPageListData && (
                <>
                    {newsPageListData.items.map((item) => (
                        <NewsListItem htmlId={`list-news-item-${item.id}`} item={item} isOpen={item.id === openItemId} key={item.id} />
                    ))}

                    {newsPageListData.numOfItems / itemsPerPage > 1 && (
                        <Container>
                            <StyledPaginationContainer>
                                <ReactPaginate
                                    pageCount={newsPageListData.numOfItems / itemsPerPage}
                                    pageRangeDisplayed={2}
                                    marginPagesDisplayed={1}
                                    containerClassName="pagination-row"
                                    previousLabel={"❮"}
                                    nextLabel={"❯"}
                                    onPageChange={({ selected }) => {
                                        const url = history.location.pathname.match(/\/_get\/page\/[0-9]+/)
                                            ? history.location.pathname.replace(
                                                  /\/_get\/page\/[0-9]+/,
                                                  selected === 0 ? "" : `/_get/page/${selected + 1}`
                                              ) + history.location.search
                                            : history.location.pathname + (selected === 0 ? "" : `/_get/page/${selected + 1}`);
                                        scrollToTop();
                                        history.push(url);
                                    }}
                                />
                            </StyledPaginationContainer>
                        </Container>
                    )}
                </>
            )}
        </StyledNewsList>
    );
};

NewsList.propTypes = {
    openItemId: PropTypes.number,
    newsPageListData: PropTypes.any,
    history: PropTypes.any,
    itemsPerPage: PropTypes.number.isRequired,
};

NewsList.defaultProps = {};

export default withRouter(NewsList);
