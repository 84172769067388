// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { FC } from "react";
import AnimateHeight from "react-animate-height";
import PropTypes from "prop-types";
import styled from "styled-components";
import BreakpointEnum from "../../../../shared/enums/BreakpointEnum/BreakpointEnum";
import { NewsListItemInterface } from "../../interfaces/NewsListItemInterface";
import { StyledArticleContent } from "../../../DetailContent/DetailContent";
import Gallery from "../../../Gallery/GalleryAdaptive";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { MediaInterface } from "../../../../services/Api/DataInterface/MediaInterface";
import Container from "../../../Container/Container";

const StyledNewsListItem = styled.article`
    width: 100%;
    margin: auto;
    display: flex;
    font-size: calc(18px + var(--font-modificator, 0px));
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 20px 0;
    color: var(--font-dark, #000);

    @media ${BreakpointEnum.XS} {
        padding: 20px 0;
    }

    @media ${BreakpointEnum.MD} {
        padding: 45px 0px;
        width: 100%;
    }
`;

const BottomLine = styled.div`
    border-bottom: 1px solid var(--contrast-yellow, #ddd);
    width: 100%;
`;

const StyledDate = styled.div`
    font-size: calc(18px + var(--font-modificator, 0px));
    color: var(--contrast-yellow, #a71931);

    @media ${BreakpointEnum.MD} {
        font-size: calc(28px + var(--font-modificator, 0px));
    }
`;

const StyledTitle = styled.div`
    font-size: calc(25px + var(--font-modificator, 0px));

    @media ${BreakpointEnum.MD} {
        margin: 3% 0 17px;
        font-size: calc(2.2vw + var(--font-modificator, 0px));
    }
`;

const StyledToggleContent = styled.div`
    width: 100%;
`;

const StyledNewsItemContent = styled(StyledArticleContent)`
    @media ${BreakpointEnum.XS} {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    @media ${BreakpointEnum.MD} {
        padding-bottom: 45px;
`;

const StyledShortContent = styled.div``;
const StyledFullContent = styled.div``;

const StyledToggleButton = styled.button`
    outline: none;
    box-shadow: none;
    border: 0;
    background: var(--main-blue, #00305c);
    color: var(--font-light, #fff);
    cursor: pointer;
    padding: 14px;
    text-align: center;
    font-size: calc(20px + var(--font-modificator, 0px));
    width: 150px;
    align-self: end;
    display: inline-flex;
    justify-content: center;
    transition: 0.2s;
    margin-top: 30px;

    @media ${BreakpointEnum.S} {
        &:hover {
            background: var(--font-light-hover, #2263a1);
        }
    }
    
    &:hover {
        background: var(--main-blue, #00305c);
    }
`;
const StyledFileList = styled.ul`
    margin-bottom: 40px;
    padding-left: 30px;
    color: var(--font-dark, #000);
    list-style: none;
    font-size: calc(18px + var(--font-modificator, 0px));

    li {
        position: relative;
        margin-bottom: 15px;

        a:hover {
            text-decoration: underline;
        }

        &::before {
            content: "";
            background: var(--main-blue, #00305c);
            display: inline-block;
            margin-left: -29px;
            position: absolute;
            height: 10px;
            width: 10px;
            border-radius: 10px;
            top: calc(calc(1em / 1.3) / 2);
        }
    }
`;

const StyledGalleryWrapper = styled.div`
    padding-bottom: 20px;

    @media ${BreakpointEnum.MD} {
        padding-bottom: 45px;
    }
`;

const StyledSingleGalleryWrapper = styled.div`
    padding-bottom: 20px;
    float: none;
    width: 100%;

    @media ${BreakpointEnum.MD} {
        padding-bottom: 45px;
        float: right;
        width: 40%;
        margin-left: 15px;
    }
`;

const NewsListItem: FC<NewsListItemInterface & RouteComponentProps> = ({ isSingleItemList, htmlId, item, isOpen, location, history }) => {
    const { id, title, aktualnosci_obrazki, date_article, description, short_description, pliki } = item;
    const hasGallery: boolean = !!aktualnosci_obrazki && !!aktualnosci_obrazki.length;
    const hasOneImage = hasGallery && aktualnosci_obrazki.length === 1;
    const hasFiles: boolean = !!pliki && Array.isArray(pliki) && !!pliki.length;

    return (
        <>
            <StyledNewsListItem id={htmlId}>
                <Container>
                    {!isSingleItemList && date_article && <StyledDate>{date_article ? date_article.split(" ")[0] : ""}</StyledDate>}
                    {!isSingleItemList && <StyledTitle>{title || ""}</StyledTitle>}
                </Container>
                <StyledToggleContent>
                    <AnimateHeight height={!isOpen ? "auto" : 0} duration={300}>
                        <Container>
                            {short_description && (
                                <StyledShortContent>
                                    <StyledNewsItemContent dangerouslySetInnerHTML={{ __html: short_description }} />
                                </StyledShortContent>
                            )}
                        </Container>
                    </AnimateHeight>

                    <AnimateHeight height={isSingleItemList || isOpen ? "auto" : 0} duration={500}>
                        {
                            <StyledFullContent>
                                <Container>
                                    {hasOneImage && (
                                        <StyledSingleGalleryWrapper>
                                            <Gallery images={aktualnosci_obrazki} />
                                        </StyledSingleGalleryWrapper>
                                    )}
                                    {description && <StyledNewsItemContent dangerouslySetInnerHTML={{ __html: description }} />}
                                    {hasFiles && (
                                        <StyledFileList>
                                            {pliki.map((file: MediaInterface, index: number) => (
                                                <li key={`file-detail-${id}-${index}`}>
                                                    <a target="_blank" rel="noreferrer" href={file.url} title={file.name}>
                                                        {file.name}
                                                    </a>
                                                </li>
                                            ))}
                                        </StyledFileList>
                                    )}
                                </Container>
                                {hasGallery && !hasOneImage && (
                                    <StyledGalleryWrapper>
                                        <Gallery images={aktualnosci_obrazki} />
                                    </StyledGalleryWrapper>
                                )}
                            </StyledFullContent>
                        }
                    </AnimateHeight>
                </StyledToggleContent>
                <Container>
                    {!isSingleItemList && (
                        <StyledToggleButton
                            onClick={() => {
                                const newsDetailUrl = `${location.pathname}${location.search}${isOpen ? "" : `#${id}`}`;
                                history.push(newsDetailUrl);
                            }}
                        >
                            {isOpen ? "Zwiń" : "Rozwiń"}
                        </StyledToggleButton>
                    )}
                </Container>
            </StyledNewsListItem>
            <Container>
                <BottomLine />
            </Container>
        </>
    );
};

NewsListItem.propTypes = {
    item: PropTypes.any,
    isOpen: PropTypes.bool,
    location: PropTypes.any,
    history: PropTypes.any,
    htmlId: PropTypes.string.isRequired,
    isSingleItemList: PropTypes.bool,
};

export default withRouter(NewsListItem);
