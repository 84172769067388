import React, { FC } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

interface FontControllerProps {
    label: string;
    supLabel: string;
    fontSizeModificator: string;
}

const FontController = styled.div`
    display: flex;
    flex-wrap: nowrap;
    cursor: pointer;
    margin-right: 20px;
    font-family: "Barlow-Bold", sans-serif;
    font-size: calc(14px + var(--font-modificator, 0px));
    align-items: center;
`;

const changeFontHandler = (fontSizeModificator: string) => {
    const htmlElement = document.getElementsByTagName("html")[0].classList;
    htmlElement.forEach((item) => {
        if (item.startsWith("font-size--")) {
            htmlElement.remove(item);
        }
    });

    if (!htmlElement) return;

    htmlElement.add(`font-size--${fontSizeModificator}`);
};

const FontSizeController: FC<FontControllerProps> = ({ label, supLabel, fontSizeModificator }) => {
    return (
        <FontController
            onClick={() => {
                changeFontHandler(fontSizeModificator);
            }}
        >
            {label}
            <sup>{supLabel}</sup>
        </FontController>
    );
};

FontSizeController.propTypes = {
    label: PropTypes.string.isRequired,
    supLabel: PropTypes.string.isRequired,
    fontSizeModificator: PropTypes.string.isRequired,
};

export default FontSizeController;
