export enum ThemeEnum {
    Default = "default" /* not used, as default variables are assigned to :root */,
    Contrast = "contrast1",
    Contrast2 = "contrast2",
    Contrast3 = "contrast3",
    Contrast4 = "contrast4",
}

export enum FontThemeEnum {
    Medium = "big" /* not used, as default variables are assigned to :root */,
    Big = "medium",
}
