import config from "./config";
import AbstractRestApiConstructorInterface from "./services/AbstractRest/AbstractRestApiOptionsInterface";
import DynamicModuleService from "./services/Api/DynamicModuleService";
import MenuService from "./services/Api/MenuService";
import StaffPanelService from "./services/Api/StaffPanelService";
import history from "./store/history";

const options: AbstractRestApiConstructorInterface = { host: config.apiUrl };
const dynamicModuleApiService = new DynamicModuleService(options);
dynamicModuleApiService.addInterceptor();
const staffPanelService = new StaffPanelService(options);
staffPanelService.addInterceptor();
const menuService = new MenuService(options);

const container = {
    dynamicModuleApiService,
    staffPanelService,
    history,
    menuService
};

export default container;
