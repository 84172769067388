import React, { FC, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import container from "../../container";
import LocaleEnum from "../../services/Locale/enums/Locale/LocaleEnum";
import { ApplicationState } from "../../store";
import { EmployeeListResponseInterface } from "../../services/Api/DataInterface/EmployeeListResponseInterface";
import { injectIntl, WrappedComponentProps } from "react-intl";

interface PropsInterface extends WrappedComponentProps {
    locale: LocaleEnum;
}

const EmployeeList: FC<PropsInterface> = ({ locale, intl }) => {
    const [data, setData] = useState<EmployeeListResponseInterface[] | null>(null);

    useEffect(() => {
        (async () => {
            const data = await container.dynamicModuleApiService.getEmployeeList(locale);
            setData(data.items);
        })();
    }, [locale]);

    if (!data) {
        return <> </>;
    }

    return (
        <table>
            <thead>
                <th>{intl.formatMessage({ id: "employeeList.name" })}</th>
                <th>{intl.formatMessage({ id: "employeeList.position" })}</th>
                <th>{intl.formatMessage({ id: "employeeList.company" })}</th>
            </thead>
            <tbody>
                {data.map((item: EmployeeListResponseInterface, index) => {
                    return (
                        <tr key={index}>
                            <td>{item.name}</td>
                            <td>{item.position}</td>
                            <td>{item.company}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

EmployeeList.propTypes = {
    locale: PropTypes.oneOf([LocaleEnum.PL, LocaleEnum.EN]).isRequired,
    intl: PropTypes.any.isRequired,
};

const mapStateToProps = (state: ApplicationState) => ({
    locale: state.locale.locale,
});

export default connect(mapStateToProps)(injectIntl(EmployeeList));
