import React, { FC } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

interface TopBarHamburgerProps {
    isArrow?: boolean;
    isActive?: boolean;
    onClick?: () => void;
}

const StyledTopBarHamburger = styled.div`
    width: auto;
    margin: 0 40px 0 0;
    transition: all 0.3s ease;
    z-index: 99;
    cursor: pointer;

    .bar {
        display: block;
        height: 2px;
        width: 30px;
        background: var(--font-light, #FFF);
        margin: 5px auto;
        transition: all 0.3s ease;
    }

    &.active .arrow-top {
        transform: rotateZ(-45deg) translateY(2px);
        width: 25px;
        margin: 0 auto 5px;
    }

    &.active .arrow-middle {
        transform: translateX(15px);
    }

    &.active .arrow-bottom {
        transform: rotateZ(45deg) translateY(-2px);
        width: 25px;
        margin: 5px auto 0;
    }
`;

const TopBarHamburger: FC<TopBarHamburgerProps> = ({ isActive, onClick }) => {
    return (
        <StyledTopBarHamburger className={isActive ? "active" : ""} onClick={onClick}>
            <div className="bar arrow-top" />
            <div className="bar arrow-middle" />
            <div className="bar arrow-bottom" />
        </StyledTopBarHamburger>
    );
};

TopBarHamburger.propTypes = {
    isActive: PropTypes.bool,
    isArrow: PropTypes.bool,
    onClick: PropTypes.func,
};

export default TopBarHamburger;
