import { useEffect, useState } from "react";

interface windowSizesInterface {
    width: number | undefined;
}

function useWindowSize() {
    const [windowSize, setWindowSize] = useState<windowSizesInterface>({
        width: window && window.innerWidth,
    });

    useEffect(() => {
        let oldWindowSize = window.innerWidth;

        function handleResize() {
            const currentWidth = window.innerWidth;
            const diff = Math.abs(oldWindowSize - currentWidth);

            if (diff > 2) {
                oldWindowSize = currentWidth;
                setWindowSize({
                    width: currentWidth,
                });
            }
        }

        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return windowSize;
}

export { useWindowSize };
